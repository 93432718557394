import _ from 'lodash';

function findObjectWithKeyValue(obj, key, value, parent = null) {
  if (_.has(obj, key) && obj[key] === value) {
    return parent;
  }

  for (const prop in obj) {
    if (_.isObject(obj[prop])) {
      const foundParent = findObjectWithKeyValue(obj[prop], key, value, obj);
      if (foundParent) {
        return foundParent;
      }
    }
  }

  return null;
}

export default findObjectWithKeyValue;