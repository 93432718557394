<script lang="ts">
  import _ from 'lodash';
  import { onMount } from 'svelte';
  import Icon from '@iconify/svelte';
  import {InstructionDialog, Logo, NewComponentOption} from '../atoms';
  import UserButton from '../atoms/UserButton.svelte';
  import { interfaceOverlay, mode } from '../../stores/uiStore';
  import { authUser } from '../../stores/userStore';
  import { UserComponentsMenu } from '../organisms';
  import getUserComponentsList from '../../firebase/getUserComponentsList';
  import deleteUserComponent from '../../firebase/deleteUserComponent';
  import MODE_TEXT from '../../utils/_mode-text';
  import IconButton from '../atoms/IconButton.svelte';
  import { UserView } from '.';
  import createProject from '../../helpers/createProject';

  let newComponentSelectedOption = null;
  let showComponents: string = 'recent';
  let componentName: string = '';
  let userComponentsList = [];
  let starting: boolean = false;

  let projectNameInputElement: HTMLInputElement;

  onMount(() => {
    projectNameInputElement.addEventListener('keydown', async (event) => {
      if (event.key === 'Enter' || event.keyCode === 13) {
        event.preventDefault();
        starting = true;
        await createProject(componentName, newComponentSelectedOption);
        starting = false;
      }
    });
  });

  const optionHandler = (option) => {
    newComponentSelectedOption = option === newComponentSelectedOption ? null : option;
    projectNameInputElement.focus();
  }

  const deleteComponent = async (id) => {
    try {
      const result = await deleteUserComponent($authUser.uid, id);
      if (result.success) {
        // Update the items using the state setter function (setState, setItems, etc.)
        userComponentsList = userComponentsList.filter(item => item.componentId !== id);
      } else {
        console.error('Failed to delete component:', result.error);
      }
    } catch(error) {
      console.error(error);
    }
  }

  async function fetchComponents() {
    try {
      userComponentsList = await getUserComponentsList($authUser.uid);
    } catch (error) {
      console.error('Error fetching components:', error);
    }
  }

  $: if ($authUser) {
    fetchComponents();
  }

  const showUserModal = () => {
    interfaceOverlay.set({
      component: UserView,
      props: {}
    });
  }
</script>

<div class="dashboard">
  <header>
    <div>
      <Logo place="dashboard"/>
      <div class="right-side">
        <a href="https://docs.google.com/forms/d/e/1FAIpQLScF7M4kuEMKWZBq3n9sjAMwm0pghk0gJHIgXNIGVGIgCzYZug/viewform?usp=sf_link" target="_blank" rel="noreferrer noopener"><Icon icon="eva:message-circle-outline" class="icon" width="10"/>Send Feedback</a>
        <IconButton on:click={showUserModal}>
          <Icon icon="eva:person-outline" width="20"></Icon>
        </IconButton>
      </div>
    </div>
  </header>

  <section class="primary-actions">
    <div>
      <h2 class="section-heading">{MODE_TEXT[$mode]['newHeading']}</h2>
      <div class="new-component-options" data-selected-option={newComponentSelectedOption}>
        <div class="option-1">
          <NewComponentOption
            label="From Library"
            description={MODE_TEXT[$mode]['newOption3Description']}
            selected={newComponentSelectedOption === 'from-library'}
            on:click={() => optionHandler('from-library')}
          >
            <Icon icon="eva:shopping-bag-outline" width="28" color="#00bbff"/>
          </NewComponentOption>
        </div>
        <div class="option-2">
          <NewComponentOption
            label="From Generator AI"
            description={MODE_TEXT[$mode]['newOption2Description']}
            selected={newComponentSelectedOption === 'from-generator'}
            on:click={() => optionHandler('from-generator')}
          >
            <Icon icon="eva:flash-outline" width="28" color="#d8a001"/>
          </NewComponentOption>
        </div>
        <div class="option-3">
          <NewComponentOption
            label="From Scratch"
            description={MODE_TEXT[$mode]['newOption1Description']}
            selected={newComponentSelectedOption === 'from-scratch'}
            on:click={() => optionHandler('from-scratch')}
          >
            <Icon icon="heroicons:wrench-screwdriver" width="28" color="#00cea8"/>
          </NewComponentOption>
        </div>
        <div class="start"
          class:option-1={newComponentSelectedOption === 'from-library'}
          class:option-2={newComponentSelectedOption === 'from-generator'}
          class:option-3={newComponentSelectedOption === 'from-scratch'}
        >
          <div>
            <input type="text" class="dashboard-inputfield" placeholder={MODE_TEXT[$mode]['newOptionInputPlaceholder']} bind:value={componentName} bind:this={projectNameInputElement} maxlength="40"/>
          </div>
          <div>
            <UserButton
              icon={!starting ? 'eva:arrow-right-fill' : null}
              on:click={async () => {
                starting = true;
                await createProject(componentName, newComponentSelectedOption);
                starting = false;
              }}
              style={'height: inherit; border-top-left-radius: 0; border-bottom-left-radius: 0'}
            >
              {#if !starting }Start{:else} <Icon icon="svg-spinners:blocks-shuffle-3"/> {/if}
            </UserButton>
          </div>
        </div>
      </div>
    </div>
    <!--<div class="tutorials">
      <h2 class="section-heading">Video Tutorials</h2>
      <Videos videos={videoTutorials}/>
    </div>-->
  </section>

  <section class="components">
    <!--<div>
      <h2 class="section-heading">New Template</h2>
      <FeaturedTemplates on:click={featuredTemplateHandler} featured={['portfolio-1']}/>
    </div>-->
    <div>
      <h2 class="section-heading">{MODE_TEXT[$mode]['dashboardMenuHeading']}</h2>
      <!--<div class="filter">
        <InputToggle initialValue={showComponents} options={yourComponentOptions} on:change={(event) => yourComponentsHandler(event.detail)}/>
      </div>-->
      {#if $authUser}
        {#if showComponents === 'recent'}
          {#if userComponentsList.length > 0 }
            <UserComponentsMenu items={userComponentsList} deleteHandler={deleteComponent}/>
          {:else}
            <div class="no-components">
              <InstructionDialog icon={{icon: "eva:plus-fill", size: 35}} dialog="You haven't built any projects"/>
            </div>
          {/if}
        {:else if showComponents === MODE_TEXT[$mode]['dashboardMenuOption2']}
          <div class="kits-menu">
            <InstructionDialog icon={{icon: "eva:shopping-bag-outline", size: 35}} dialog={MODE_TEXT[$mode]['dashboardMenuNoItems']}/>
          </div>
        {/if}
      {:else}
        <div class="not-signed-in-container" on:click={showUserModal} role="button">
          <InstructionDialog icon={{icon: "eva:person-add-outline", size: 35}} dialog="You are not signed in. Click here to create an account and save your work."/>
        </div>
      {/if}
    </div>
  </section>
</div>

<style lang="scss">
  .dashboard {
    font-size: 16px;
  }

  header {
    padding: 15px 30px;
    padding-top: 13px;
    position: sticky;
    top: 0;
    z-index: 10;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: #292929;

    h1 {
      line-height: 1;
      font-size: 1.05em;
      font-weight: 500;
      margin: 0 auto;
      max-width: 1300px;
    }

    > div {
      max-width: 1300px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .right-side {
      display: flex;
      gap: 30px;
      font-size: 0.85em;
    }

    a {
      align-self: center;
      color: $lightest;
      text-decoration: none;
      display: flex;
      gap: 5px;
      align-items: center;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .start {
    display: flex;
    grid-column-start: 3;
    align-items: center;
    --ui-theme: #313131;
    gap: 4px;
    height: 45px;
    box-shadow: $shadow-4;

    > div {
      height: inherit;

      &:first-child {
        flex-grow: 1;
      }
    }
  }

  .dashboard-inputfield {
    @include clear-default-styling;
    box-sizing: border-box;
    padding: 10px;
    width: 100%;
    height: inherit;
    outline-offset: -2px;
    font-family: $font-family;
    border-radius: $border-radius-1 0 0 $border-radius-1;

    &:focus {
      outline: solid 2px var(--ui-theme);
    }
  }

  .option-1 {
    --ui-theme: #{$ui-theme-color-general};
  }

  .option-2 {
    --ui-theme: #{$ui-theme-color-generator};
  }

  .option-3 {
    --ui-theme: #{$ui-theme-color-components};
  }

  .primary-actions,
  .components {
    padding: 70px 30px;
    max-width: 1050px;
    margin: 0 auto;
  }

  .components {
    display: grid;
    grid-template-columns: auto 300px;
    gap: 30px;
    min-height: 300px;
    padding-bottom: 30px;
  }

  .components {
    grid-template-columns: none
  }

  .section-heading {
    font-weight: 500;
    font-size: 1.15em;
    margin: 0;
    margin-bottom: 20px;

    &.align-center {
      text-align: center;
    }
  }

  .new-component-options {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 15px;
    row-gap: 15px;

    &:not([data-selected-option]) {
      .start {
        opacity: 0;
        pointer-events: none;
      }
    }

    &[data-selected-option="from-library"] {
      .start {grid-column-start: 1}
    }

    &[data-selected-option="from-generator"] {
      .start {grid-column-start: 2}
    }

    &[data-selected-option="from-scratch"] {
      .start {grid-column-start: 3}
    }
  }

  .components {
    padding-top: 0;
  }

  .filter {
    max-width: 300px;
    margin: 0 auto;
    margin-bottom: 30px;
  }

  .tutorials {
    --ui-theme: #FF0000;
  }


  .not-signed-in-container {
    cursor: pointer;
    max-width: 500px;
    margin: 0 auto;

    &:hover {
      --ui-theme: #{$ui-theme-color-output};
    }
  }

  .kits-menu {
    max-width: 500px;
    margin: 0 auto;
    --ui-theme: #{$ui-theme-color-general};
  }

  .no-components {
    max-width: 500px;
    margin: 0 auto;
  }

  @media screen and (max-width: 1200px) {
    .primary-actions {
      grid-template-columns: auto 230px;
    }
  }
</style>