export type Base64EncodedFile = { contents: string; name: string; type: string };

export function base64EncodeFile(file: File): Promise<Base64EncodedFile> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            if(typeof reader.result !== 'string') return reject('Failed to read file')
            const contents = reader.result;
            const base64Contents = contents.split(',')[1]; // remove the data URL prefix
            resolve({
                contents: base64Contents,
                name: file.name,
                type: file.type,
            });
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
    });
}