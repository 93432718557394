<script lang="ts">
  import { onMount } from "svelte";
  import { selectedElement, selectedElementPrioritized, selectedElementScaffold } from "../../stores/scaffoldStore";
  import { ViewTitle, InstructionDialog } from "../atoms";
  import { Dimensions, Text, Borders, Spacing, Shadows, TextInput, Background, Layout, Icon, Image, Embed, List, Position, Class } from "../templates/index.js";
  import { FONTAWESOME_CLASSNAMES, SELF_CLOSING_TAGS } from "../../scaffold/ScaffoldRegulator";

  let viewTitle = 'Element Properties';

  let showTextTool, showListTool, showImageTool, showPositionTool, showTextInputTool, showEmbedTool, showLayoutTool, showIconTool, showBackgroundTool, showShadowsTool, showDimensionsTool, showBordersTool, showSpacingTool;

  onMount(() => {
    return () => {
      if( $selectedElement.id && !$selectedElementPrioritized ) {
        selectedElement.set({id: null, element: null}); // clear previously selected element
      }
    }
  });

  $: {
    if( $selectedElementScaffold.selectedScaffold !== null ) {
      viewTitle =`${$selectedElement.element ? $selectedElement.element.replace(/-/g, ' ') : 'Element'} Properties`;
      const _S_ = $selectedElementScaffold.selectedScaffold;
      const isIcon = _S_.attributes.class && FONTAWESOME_CLASSNAMES.find(fa => _S_.attributes.class.includes(fa));
      const hasNestedText = _S_.hasOwnProperty('nested') && _S_.nested.length && _S_.nested[0].hasOwnProperty('text');
      showTextTool = hasNestedText;
      showListTool = _S_.tag === 'ul' || _S_.tag === 'ol' || _S_.tag === 'li';
      showImageTool = _S_.tag === 'img';
      showPositionTool = _S_.tag !== 'body';
      showTextInputTool = (_S_.tag === 'input') || (_S_.tag === 'textarea'); // TODO: Check for other input types
      showEmbedTool = _S_.tag === 'iframe';
      showLayoutTool = _S_.tag !== 'textarea' && _S_.tag !== 'iframe' && _S_.tag !== 'select' && !hasNestedText && !isIcon && !SELF_CLOSING_TAGS.includes(_S_.tag);
      showIconTool = isIcon;
      // TODO: some of the tools below should probably not be made available under certain scinarios. For example; backgroundTool on input with type radio/checkbox
      showBackgroundTool = true;
      showShadowsTool = true;
      showDimensionsTool = true;
      showBordersTool = true;
      showSpacingTool = true;
    }
  }
</script>

<ViewTitle title={viewTitle}/>
{#if $selectedElementScaffold.selectedScaffold !== null }
  <!--<GeneratorPrompt placeholder="What changes would you like to make to the selected element? Elements nested within won't be affected."/>-->
  <Class/>
  {#if showTextInputTool}
    <TextInput/>
  {/if}
  {#if showListTool}
    <List/>
  {/if}
  {#if showEmbedTool}
    <Embed/>
  {/if}
  {#if showImageTool}
    <Image/>
  {/if}
  {#if showIconTool}
    <Icon/>
  {/if}
  {#if showTextTool}
    <Text/>
  {/if}
  {#if showLayoutTool}
    <Layout/>
  {/if}
  {#if showPositionTool}
    <Position/>
  {/if}
  {#if showDimensionsTool}
    <Dimensions/>
  {/if}
  {#if showBordersTool}
    <Borders/>
  {/if}
  {#if showSpacingTool}
    <Spacing/>
  {/if}
  {#if showBackgroundTool}
    <Background/>
  {/if}
  {#if showShadowsTool}
    <Shadows/>
  {/if}
{:else}
  <section>
    <InstructionDialog icon={{icon: "icon-park-outline:click", size: 35}} dialog="Click any element in the Canvas to customize its properties."/>
  </section>
{/if}

<style lang="scss">
  section {
    margin: 0 $view-content-edge-spacing $view-sections-separation $view-content-edge-spacing;
  }
</style>