import { authUser } from '../stores/userStore';
import { auth } from './firebase';

const checkEmailVerification = async (): Promise<string> => {
  try {
    const user = auth.currentUser;
    if (user) {
      await user.reload();
      if (user.emailVerified) {
        authUser.set(user);
        return 'User email is verified.';
      } else {
        return 'User email is not verified.';
      }
    } else {
      return 'No user is currently signed in.';
    }
  } catch (error) {
    console.error(error);
    return `Error: ${error.message}`;
  }
};

export default checkEmailVerification;