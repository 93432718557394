<script lang="ts">
  import { onMount } from "svelte";
  import { createStack } from '../../utils/_svelte-undo';
  import { canvasDOMMutationCounter, activeNavItem, newComponent, viewWidth, canvasDOM, canvasWindow } from "../../stores/uiStore";
  import { CanvasOverlay } from "../organisms";
  import nav, { NAV_ITEM } from "../../utils/_nav-items";
  import { CanvasBar, DOMNavigator, CanvasResizer } from "../molecules";
  import { scaffold } from "../../stores/scaffoldStore";
  import { get } from "svelte/store";
  import ScaffoldCoordinator from "../../scaffold/ScaffoldCoordinator";

  let canvasRef: HTMLIFrameElement | null = null;
  let canvasWidth = 1200;
  let canvasMinWidth = 320;
  let iframeHeight: number;
  let resizing = false;

  let iFramePath = `https://${window.location.hostname}/template/index.html`;

  if (import.meta.env.DEV) {
    // If running locally we can reference the file directly
    iFramePath = "../../public/template/index.html"
  }

  const deviceWidths = {
    desktop: 1200,
    tablet: 720,
    smartphone: 420
  }

  $: {
    if( canvasRef && $canvasDOM && $canvasDOMMutationCounter && resizing === false ) {
      iframeHeight = canvasRef.contentWindow.document.documentElement.scrollHeight;
    }
  }

  const undoRedo = (event) => {
    if (event.ctrlKey || event.metaKey) {
      if (event.key === 'z' || event.key === 'Z') {
        if (event.shiftKey) {
          event.preventDefault();
          ScaffoldCoordinator.undoRedo('redo');
        } else {
          event.preventDefault();
          ScaffoldCoordinator.undoRedo('undo');
        }
      } else if (event.key === 'y' || event.key === 'Y') {
        event.preventDefault();
        ScaffoldCoordinator.undoRedo('redo');
      }
    }
  }

  onMount(() => {
    switch($newComponent.method) {
      case 'from-generator':
        document.querySelector('.topbar .generator input[type="text"]').focus();
        break;
      case 'from-library':
        activeNavItem.set(NAV_ITEM.components);
        viewWidth.set(nav[NAV_ITEM.components].viewWidth);
        break;
      case 'from-scratch':
        activeNavItem.set(NAV_ITEM.elements);
        viewWidth.set(nav[NAV_ITEM.elements].viewWidth);
        break;
      case 'from-data-source':
        activeNavItem.set(NAV_ITEM.data);
        viewWidth.set(nav[NAV_ITEM.data].viewWidth);
        break;
    }

    // undo/redo scaffold stack
    window.scaffoldStack = createStack(get(scaffold));

    // undo/redo keyboard shortcust
    document.addEventListener('keydown', undoRedo);

    return () => {
      window.scaffoldStack = null;
      document.removeEventListener('keydown', undoRedo);
    }
  });

  // when the canvas first mounts, pass the initial scaffold, and when the scaffold updates
  $: {
    if( $scaffold !== null && $canvasWindow ) {
      $canvasWindow.incomingScaffoldUpdate($scaffold);
    }
  };

  const deviceClickHandler = (event) => {
    canvasWidth = deviceWidths[event.detail];
  }

  const resizeStartHandler = () => {
    resizing = true;
  }

  const resizeEndHandler = () => {
    resizing = false;
  }
  
  $: {
    if( !resizing ) {
      document.body.classList.remove('resizing-canvas');
    } else {
      document.body.classList.add('resizing-canvas');
    }
  }
</script>

<div class="bar-floater">
  <CanvasBar on:deviceclick={deviceClickHandler}/>
</div>
<div class="canvas-container" style:width={`${canvasWidth}px`}>
  <div class="label">
    <span>{$newComponent.name}</span>
    <span>{canvasWidth}px</span>
  </div>
  <div class="iframe-wannabe" style:height={`${iframeHeight}px`}>
    {#if $canvasDOM && $activeNavItem !== NAV_ITEM.preview && !resizing && $activeNavItem !== NAV_ITEM.components && $activeNavItem !== NAV_ITEM.export}
      <CanvasOverlay/>
    {/if}
    <iframe src="{iFramePath}" title="canvas" scrolling="no" bind:this={canvasRef} data-hj-allow-iframe></iframe>
  </div>
  {#if $activeNavItem !== NAV_ITEM.preview}
    <div class="sticky-canvas-footer">
      <DOMNavigator/>
    </div>
  {/if}
</div>
<CanvasResizer
  width={canvasWidth}
  minWidth={canvasMinWidth}
  onResize={(newWidth) => canvasWidth = newWidth}
  on:resizeStart={resizeStartHandler}
  on:resizeEnd={resizeEndHandler}
/>

<style lang="scss">
  .canvas-container, :global(.canvas-resizer) {
    max-width: calc(100% - 80px);
  }

  .canvas-container, :global(.canvas-resizer) {
    height: auto;
    margin: 120px auto;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1;
  }

  :global(body:not(.resizing-canvas)) {
    .canvas-container, :global(.canvas-resizer) {
      transition: width .30s;
    }
  }

  :global(body.resizing-canvas) {
    iframe {
      pointer-events: none;
    }
  }

  .label {
    font-size: 12px;
    margin-bottom: 5px;
    margin-top: -17px;
    width: 100%;
    display: flex;
    line-height: 1;
    color: #828282;
    position: absolute;
    justify-content: space-between;
  }

  .iframe-wannabe {
    width: 100%;
    position: relative;
  }

  iframe {
    width: inherit;
    height: inherit;
    border: none;
    overflow: hidden;
    outline: solid 1px #4f4f4f;
    box-sizing: border-box;
  }

  .sticky-canvas-footer {
    position: sticky;
    bottom: 0;
    z-index: 10;
  }

  .bar-floater {
    position: sticky;
    top: 0px;
    margin: 0 auto;
    z-index: 10;
    padding-bottom: 5px;
    background-color: #292929;
  }
</style>
