<script lang="ts">
  import Icon from '@iconify/svelte';
  
  export let isActive: boolean = false;
  export let icon: string;
  export let hflip: boolean = false;
  export let style: string = null;
  export let disabled: boolean = false;
  export let label: string;
</script>

<div class:active={isActive}>
  <button on:click class="nav-btn" class:active={isActive} class:disabled={disabled} type="button" style={style}>
    <Icon icon={icon} width="24px" hFlip={hflip}/>
  </button>
  <label class={label}>{label}</label>
</div>

<style lang="scss">
  div {
    position: relative;
    display: flex;

    &:not(.active):hover {
      label {
        opacity: 1;
      }
    }
  }

  button {
    @include clear-default-styling;
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    width: $navbars-buttons-size;
    height: $navbars-buttons-size;
    border-radius: 7px;
    color: var(--ui-theme);
    background-color: $background-index-1;
    border: solid 4px $background-index-3;

    &:hover {
      border-color: var(--ui-theme);
    }

    &.active, &:active {
      background-color: var(--ui-theme);
      border-color: var(--ui-theme);
      color: var(--ui-theme-icon-offset);
      cursor: default;
    }

    &.disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  label {
    background-color: $lightest;
    position: absolute;
    z-index: 100;
    padding: 5px;
    border-radius: 5px;
    white-space: nowrap;
    font-size: 13px;
    height: fit-content;
    align-self: center;
    opacity: 0;
    font-weight: 500;
    pointer-events: none;
    transition-property: opacity;
    transition-duration: 0.10s;
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);

    &.collection {
      left: calc(100% + 10px);
    }

    &:before {
      content: "";
      width: 10px;
      height: 10px;
      top: 0;
      left: 0;
      background-color: white;
      position: absolute;
      z-index: -1;
    }
  }

  :global(.leftbar .buttons-group label) {
    left: calc(100% + 10px);

    &:before {
      left: -3px;
      top: 0px;
      bottom: 0px;
      margin: auto;
      transform: rotate(45deg);
    }
  }

  :global(.topbar .buttons-group label) {
    top: calc(100% + 10px);
    margin: auto;

    &:before {
      top: -3px;
      margin: auto;
      transform: rotate(45deg);
      left: 0;
      right: 0;
    }
  }

  :global(.topbar .buttons-group > div) {
    justify-content: center;
  }

  :global(.topbar .buttons-group[data-group="general"] > div:first-child label) {
    border-top-left-radius: 0;

    &:before {
      content: none;
    }
  }    
</style>