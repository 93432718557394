<script lang="ts">
  import { CollectionView, ComponentsView, ElementsView, ExportView, PublishView, PreferencesView, PropertiesView, DOMNavigatorView, NotReadyView } from '../components/views';
  import DataView from '../components/views/DataView.svelte';
  import { activeNavItem, viewWidth } from '../stores/uiStore';
  import nav from '../utils/_nav-items';
  
  const components = {
    CollectionView,
    ComponentsView,
    ElementsView,
    ExportView,
    PublishView,
    PreferencesView,
    PropertiesView,
    DOMNavigatorView,
    NotReadyView,
    DataView
  }
</script>

<aside class="theme-dark" data-active={$activeNavItem} style:width="{$viewWidth}px">
    <div class="view-scroller">
      {#if nav.hasOwnProperty($activeNavItem)}
        <svelte:component this={components[nav[$activeNavItem].view]}/>
      {/if}
    </div>
</aside>

<style lang="scss">
  aside {
    position: absolute;
    z-index: 5;
    box-sizing: border-box;
    font-size: 13px;
    font-weight: 500;
    -webkit-user-select: none;
    user-select: none;
    transition-property: width, opacity;
    width: $views-width-normal;
    padding-right: $navbars-buttons-gap;
    transition: $frame-transitions-speed;
    height: calc((100vh - $navbars-thickness) - $navbars-buttons-gap);
    top: $navbars-thickness;
    left: $navbars-thickness;
    bottom: $navbars-buttons-gap;
    color: $lightest;
    
    @include active-navitem-ui-themes;

    // TODO: expiremental theme application method.
    &.theme-dark {
      @include theme-dark;
    }
  }

  .view-scroller {
    width: 100%;
    height: 100%;
    overflow: hidden scroll;
    scroll-behavior: smooth;
    box-sizing: border-box;
    border-radius: $border-radius-1;
    background: $background-index-1;
    border: solid 5px $background-index-3;
    background-color: $background-index-1;

    border-right: none;
    @include custom-scrollbar;
  }
</style>