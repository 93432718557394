<script lang="ts">
  export let label: string;
  export let sticky: boolean = false;
</script>

<h3 class:sticky={sticky}>
  <span>{label}</span>
  <div class="slot">
    <slot/>
  </div>
</h3>

<style lang="scss">
  h3 {
    font-weight: 400;
    font-size: 1.1em;
    margin: 0 0 15px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    position: relative;
    z-index: 10;
    box-sizing: border-box;
    white-space: nowrap;
    padding: 0px $view-content-edge-spacing;
    background-color: $background-index-3;

    &.sticky {
      position: sticky;
      top: -3px;
    }
  }

  .slot {
    display: flex;
    gap: 10px;
  }
</style>