<script lang="ts">
  import Icon from "@iconify/svelte";
  import { IconButton } from "../atoms";
  export let id: string | number;
  export let name: string;
  export let thumbnail: string;
  export let deleteHandler: Function;
  export let menuClickHandler: Function;

  let menu;
</script>

<article>
  <img src={thumbnail} alt={name} on:click/>
  <div class="meta">
    <h4>{name}</h4>
    <div class="menu" bind:this={menu}>
      <IconButton on:click={() => menuClickHandler(id, menu)}>
        <Icon icon="eva:trash-2-outline" color="#fa5b5a"/>
      </IconButton>
    </div>
</article>

<style lang="scss">
  article {
    cursor: pointer;

    .meta {
      display: flex;
      
    }

    img {
      max-width: 100%;
      width: 100%;
      border-radius: $border-radius-2;
      box-sizing: border-box;
      outline: solid 2px $lightest;
      outline-offset: -2px;
      box-shadow: $shadow-4;
    }

    h4 {
      margin-top: 10px;
      margin-bottom: 0;
      font-weight: 500;
      font-size: 0.9em;
      flex: 1;
    }

    &:hover {
      .menu {
        visibility: visible;
      }
    }
  }

  .menu {
    visibility: hidden;
  }
</style>