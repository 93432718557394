import {
  addDoc,
  collection,
} from '@firebase/firestore';
import {
  getDownloadURL,
  ref,
  uploadString,
} from '@firebase/storage';

import {
  firestore,
  storage
} from './firebase'

async function uploadUserComponent(userId, componentId, componentName, scaffold, dataSource = null) {
  const path = `user-components/${userId}/${componentId}.json`;
  
  // Create a reference with the user's unique ID
  const userComponentRef = ref(storage, path);
  
  // Upload the JSON string
  await uploadString(userComponentRef, scaffold, 'raw');
  
  // Get the download URL if needed
  const downloadURL = await getDownloadURL(userComponentRef);

  // Upload the metadata to Firestore
  const docId = await uploadMetadata(userId, componentId, componentName, downloadURL, dataSource);
  
  return { downloadURL, docId };
}

async function uploadMetadata(userId, componentId, componentName, downloadURL, dataSource = null) {
  const componentsCollection = collection(firestore, 'user-components');

  // Create the metadata object
  const metadata = {
    userId,
    componentId,
    downloadURL,
    componentName,
    title: '',
    description: '',
    timestamp: new Date().toISOString(),
    dataSource
  };

  // Upload the metadata to Firestore
  const docRef = await addDoc(componentsCollection, metadata);
  return docRef.id; // Return the Firestore document ID
}


export default uploadUserComponent;
