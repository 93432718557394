<script lang="ts">
  import { NavIconButton } from '../components/atoms';
  import { GeneratorBar } from '../components/organisms';
  import ScaffoldCoordinator from '../scaffold/ScaffoldCoordinator';
  import { activeNavItem, newComponent, viewWidth } from '../stores/uiStore';
  import nav, { NAV_ITEM } from '../utils/_nav-items';

  const navClickHandler = (activate) => {
    //const toActivate = activate === NAV_ITEM.preview ? null : activate;
    if( $activeNavItem === NAV_ITEM.preview && activate === NAV_ITEM.preview ) {
      activate = null;
    }
    if( $activeNavItem === NAV_ITEM.trash && activate === NAV_ITEM.trash ) {
      activate = null; 
    }
    const vWidth = nav[activate] && nav[activate].hasOwnProperty('viewWidth') ? nav[activate].viewWidth : null;
    // update states
    activeNavItem.set(activate);
    viewWidth.set(vWidth);
  }

  const undoRedoClickHandler = (direction: 'undo' | 'redo') => {
    // TODO: if a currently selected element is removed when undoing/redoing, it causes an error
    ScaffoldCoordinator.undoRedo(direction);
  }
</script>

<div class="topbar">
  <div class="buttons-group" data-group="general">
    <NavIconButton label="collection" icon="eva:layers-outline" isActive={$activeNavItem === NAV_ITEM.collection} on:click={() => navClickHandler(NAV_ITEM.collection)}/>
    <NavIconButton label="preferences" icon="eva:settings-outline" isActive={$activeNavItem === NAV_ITEM.preferences} on:click={() => navClickHandler(NAV_ITEM.preferences)}/>
    <NavIconButton label="data source" icon="bx:spreadsheet" isActive={$activeNavItem === NAV_ITEM.data} on:click={() => navClickHandler(NAV_ITEM.data)}/>
  </div>
  <div class="buttons-group margin" data-group="generator">
    <GeneratorBar --ui-theme="#d8a001"/>
  </div>
  <div class="buttons-group" data-group="output">
    <NavIconButton label="preview" icon="eva:eye-outline" isActive={$activeNavItem === NAV_ITEM.preview} on:click={() => navClickHandler(NAV_ITEM.preview)} style={$newComponent ? "z-index: 15" : "z-index: 0"}/>
    <NavIconButton label="code" icon="eva:code-download-outline" isActive={$activeNavItem === NAV_ITEM.export} on:click={() => navClickHandler(NAV_ITEM.export)}/>
    <NavIconButton label="publish" icon="eva:globe-fill" isActive={$activeNavItem === NAV_ITEM.publish} on:click={() => navClickHandler(NAV_ITEM.publish)}/>
  </div>
</div>
<div class="leftbar">
  <div class="buttons-group" data-group="general">
    <NavIconButton label="elements" icon="eva:plus-outline" isActive={$activeNavItem === NAV_ITEM.elements} on:click={() => navClickHandler(NAV_ITEM.elements)}/>
    <NavIconButton label="templates" icon="eva:shopping-bag-outline" isActive={$activeNavItem === NAV_ITEM.components} on:click={() => navClickHandler(NAV_ITEM.components)}/>
  </div>
  <div class="buttons-group margin" data-group="components">
    <NavIconButton label="element properties" icon="eva:options-2-outline" isActive={$activeNavItem === NAV_ITEM.properties} on:click={() => navClickHandler(NAV_ITEM.properties)}/>
    <NavIconButton label="data" icon="material-symbols:flowsheet-outline" isActive={$activeNavItem === NAV_ITEM.interaction} on:click={() => navClickHandler(NAV_ITEM.interaction)}/>
    <NavIconButton label="responsive" icon="mingcute:device-line" isActive={$activeNavItem === NAV_ITEM.responsive} on:click={() => navClickHandler(NAV_ITEM.responsive)}/>
  </div>
  <div class="buttons-group" data-group="backtrack">
    <NavIconButton label="remove" icon="eva:trash-2-outline" isActive={$activeNavItem === NAV_ITEM.trash} on:click={() => navClickHandler(NAV_ITEM.trash)}/>
    <NavIconButton label="undo" icon="eva:undo-outline" isActive={$activeNavItem === NAV_ITEM.undo} on:click={() => undoRedoClickHandler('undo')}/>
    <NavIconButton label="redo" icon="eva:undo-outline" isActive={$activeNavItem === NAV_ITEM.redo} hflip={true} on:click={() => undoRedoClickHandler('redo')}/>
  </div>
</div>

<style lang="scss">
  .topbar, .leftbar {
    position: relative;
    display: flex;
    box-sizing: border-box;
    //z-index: 7;
    justify-content: space-between;
    align-items: center;
  }

  .topbar {
    top: 0px;
    width: 100%;
    height: $navbars-thickness;
    padding: 0 $navbars-buttons-gap;

    .margin {
      margin: 0 $navbars-buttons-gap;
    }
  }

  .leftbar {
    left: 0px;
    flex-direction: column;
    padding-bottom: $navbars-buttons-gap;
    width: $navbars-thickness;
    height: calc(100vh - $navbars-thickness);

    .buttons-group {
      flex-direction: column;
    }

    .margin {
      margin: $navbars-buttons-gap 0;
    }
  }

  .buttons-group {
    display: inline-flex;
    gap: $navbars-buttons-gap;

    &[data-group="general"] {
      --ui-theme: #{$ui-theme-color-general};
      --ui-theme-icon-offset: #{$lightest};
    }

    &[data-group="output"] {
      --ui-theme: #{$ui-theme-color-output};
      --ui-theme-icon-offset: #{$lightest};
    }

    &[data-group="components"] {
      --ui-theme: #{$ui-theme-color-components};
      --ui-theme-icon-offset: #{$lightest};
    }

    &[data-group="backtrack"] {
      --ui-theme: #{$ui-theme-color-backtrack};
      --ui-theme-icon-offset: #{$lightest};
    }
  }
</style>