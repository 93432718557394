import { get } from "svelte/store";
import updateUserComponent from "../firebase/updateUserComponent";
import { authUser } from "../stores/userStore";
import { scaffold, scaffoldDataSource } from "../stores/scaffoldStore";
import { interfaceOverlay, newComponent } from "../stores/uiStore";
import { UserView } from "../components/views";
import updateFieldInFirestore from "../firebase/updateUserComponentData";

const saveProgress = async () => {
  if( get(authUser) ) {
    try {
      await updateUserComponent(get(authUser).uid, get(newComponent).id, JSON.stringify(get(scaffold)));
      if( get(scaffoldDataSource) ) {
        await updateFieldInFirestore({
          userId: get(authUser).uid,
          componentId: get(newComponent).id,
          fieldName: 'dataSource',
          newValue: get(scaffoldDataSource)
        });
      }
    } catch(error) {
      alert(error);
      console.error(error);
    };
  } else {
    interfaceOverlay.set({
      component: UserView,
      props: {}
    });
  }
}

export default saveProgress;