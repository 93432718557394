<script lang="ts">
  import Icon from "@iconify/svelte";
  import signOutUser from "../../firebase/signOutUser";
  import { authUser } from "../../stores/userStore";
  import { UserButtonSmall, UserInputField, UserVerifyEmail, IconButton } from "../atoms";
  import { interfaceOverlay } from "../../stores/uiStore";

  $: verifiedEmail = $authUser && $authUser.emailVerified;

  let emailAddress = $authUser.email;
  let name = $authUser.name;
  let password = '••••••••';

  let nameDisabled: boolean = true;
  let emailDisabled: boolean = true;
  //let passwordDisabled: boolean = true;

  //$: nameIcon = nameDisabled ? 'eva:edit-2-outline' : 'eva:checkmark-outline';
  //$: emailIcon = emailDisabled ? 'eva:edit-2-outline' : 'eva:checkmark-outline';

  const handleSignOut = () => {
    signOutUser();
  }

  /*const handleToggle = (toggle: 'name' | 'email' | 'password') => {
    alert('This feature is not ready yet...')
    return false;
    if( toggle === 'name' ) {
      if( !nameDisabled ) {
        alert('save name')
      }
      nameDisabled = !nameDisabled;
    } else if( toggle === 'email' ) {
      if( !nameDisabled ) {
        alert('save email')
      }
      emailDisabled = !emailDisabled;
    } else if( toggle === 'password' ) {
      alert('send password reset link');
    }
  }*/
</script>

{#if $authUser}
<div class="content">
  <div class="close-container">
    <IconButton on:click={() =>  interfaceOverlay.set(null)}>
      <Icon icon="uil:times"/>
    </IconButton>
  </div>
  <aside>
    {#if authUser && verifiedEmail === true}
      <h3 class="welcome">Welcome, <br/><strong>{$authUser.email}</strong></h3>
    {:else}
      <UserVerifyEmail email={$authUser.email}/>
    {/if}
    <form on:submit={(event) => event.preventDefault()}>
      <div class="field">
        <UserInputField label="Name" type="text" icon="eva:person-outline" bind:value={name} disabled={nameDisabled}/>
        <!--<UserIconButton icon={nameIcon} on:click={() => handleToggle('name')}/>-->
      </div>
      <div class="field">
        <UserInputField label="Email Address" type="email" icon="eva:email-outline" bind:value={emailAddress} disabled={emailDisabled}/>
        <!--<UserIconButton icon={emailIcon} on:click={() => handleToggle('email')}/>-->
      </div>
      <div class="field">
        <UserInputField label="Password" type="password" icon="eva:lock-outline" bind:value={password} disabled/>
        <!--<UserIconButton icon="eva:edit-2-outline" on:click={() => handleToggle('password')}/>-->
      </div>
    </form>
    <footer>
      <UserButtonSmall icon="eva:log-out-outline" on:click={handleSignOut}>sign out</UserButtonSmall>
    </footer>
  </aside>
</div>
{/if}

<style lang="scss">
  .content {
    height: inherit;
    overflow-y: scroll;
    --ui-theme: #{$ui-theme-color-output};
  }

  .welcome {
    margin: 0;
    font-weight: 500;
    padding: 30px;
  }

  form {
    border-top: solid 1px #e5e5e5;
    padding: 30px;
    padding-bottom: 0;
  }

  .field {
    //display: grid;
    //grid-template-columns: auto min-content;
    //align-items: center;
    //gap: 10px;
    margin-bottom: 20px;
  }

  footer {
    padding: 30px;
  }

  .close-container {
    @include close-container;
  }
</style>