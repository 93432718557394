import _ from 'lodash';
import getLibraryComponentScaffold from "../firebase/getLibraryComponentScaffold";
import initialScaffoldJSON from '../scaffold/ScaffoldInitial.json';
import createProject from "./createProject";
import {CF_generateResume} from '../firebase/firebase';
import { base64EncodeFile } from "./base64encodeFile";
import { authUser } from "../stores/userStore";
import { collection, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';
import {
  firestore,
} from '../firebase/firebase'

import {get} from 'svelte/store';
import type { User } from "firebase/auth";


const createProjectWithDataSource = async (selectedTemplateId: string, userResume: File) => {
  // TODO: Call cloud function with here, and pass it the resume JSON
  // TODO: Call createProject with resumeJSON object, and presets


  // encode file, and send to cloud function
  const encodedFile = await base64EncodeFile(userResume);
  const response = await CF_generateResume({resume: encodedFile});

  const userResumeCollection = collection(firestore, 'user-json-resume');
  const usersResumeDoc = doc(userResumeCollection, (get(authUser) as User).uid as any);

  const resumeSnap = await getDoc(usersResumeDoc);
  if (resumeSnap.exists()) {
    // Update the existing document
    await updateDoc(usersResumeDoc, {
      "resume": response
    });
  } else {
    // Document doesn't exist yet, create it
    await setDoc(usersResumeDoc, {
      "resume": response
    });
  }

  const dataSource = 'choices' in response.data ? {resume: JSON.parse(response.data.choices[0].message.content)} ?? {} : {}

  const libScaffold = await getLibraryComponentScaffold(selectedTemplateId, selectedTemplateId);
  const initialScaffold = _.cloneDeep(initialScaffoldJSON);
  initialScaffold.nested.push(libScaffold);
  // TODO: dataSourceId should reference the id of the uploaded resumeJSON data source to firestore
  createProject('Resume Portfolio', 'from-data-source', {scaffold: initialScaffold, dataSourceId: null, name: 'Resume Portfolio', method: 'from-data-source'}, dataSource);
}

export default createProjectWithDataSource;