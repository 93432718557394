<script lang="ts">
  import { scaffold } from "../../stores/scaffoldStore";
  import { ViewSection, ViewTitle } from "../atoms";
  import { DOMNavigatorFull } from "../templates";
</script>

<ViewTitle title="Element Layers"/>
<ViewSection>
  <DOMNavigatorFull scaffold={$scaffold}/>
</ViewSection>

<style lang="scss">

</style>