<script lang="ts">
  import Icon from "@iconify/svelte";
  import { createEventDispatcher } from "svelte";
  
  export let value: {}[];
  export let options: {label: string | {icon: string, width: string, hFlip: boolean, rotate: number}, value: string}[];

  const dispatch = createEventDispatcher();

  const toggleHandler = (optionValue) => {
    dispatch('change', optionValue);
  }
</script>

<div class="input-toggle-multiple">
  <div class="options">
    {#each options as option}
      <button class:selected={value[option.label.value]} on:click={() => toggleHandler(option.label.value)}>
        {#if typeof option.label === 'string'}
          {option.label}
        {:else}
          <Icon icon={option.label.icon} width={option.label.width} hFlip={option.label.hFlip} rotate={option.label.rotate}/>
        {/if}
      </button>
    {/each}
  </div>
</div>

<style lang="scss">
  .options {
    font-size: 0.85em;
    min-height: 38px;
    box-sizing: border-box;
    display: flex;
    overflow: hidden;
    border-radius: $border-radius-2;
    border: solid 1px $background-color-index-4-offset;
  }

  button {
    @include clear-default-button-styling;
    border-left: solid 2px;
    border-top: solid 1px;
    border-bottom: solid 1px;
    border-color: inherit;
    box-sizing: border-box;
    font-size: 0;
    flex-basis: 100%;

    &.selected {
      background-color: $background-color-index-4;
      border-color: $background-color-index-4-offset;
      color: var(--ui-theme);
    }

    &:first-child {
      border-left-width: 1px;
      border-top-left-radius: calc($border-radius-2 - 1px);
      border-bottom-left-radius: calc($border-radius-2 - 1px);
    }

    &:last-child {
      border-right: solid 1px $background-color-index-4-offset;
      border-top-right-radius: calc($border-radius-2 - 1px);
      border-bottom-right-radius: calc($border-radius-2 - 1px);
    }

    &:hover {
      color: var(--ui-theme);
    }
  }
</style>