<script lang="ts">
  import { createEventDispatcher } from "svelte";

  export let width: number;
  export let minWidth: number;
  export let onResize: (newWidth: number) => void;

  let resizable, leftHandle, rightHandle;
  let startX, startWidth, originalX;
  let isLeftHandle;

  const dispatcher = createEventDispatcher();

  const initDrag = (event) => {
    event.preventDefault(); // Prevent default actions like text selection
    dispatcher('resizeStart'); // Resize-start event dispatch

    startX = event.clientX;
    startWidth = resizable.offsetWidth;
    originalX = resizable.getBoundingClientRect().left;
    isLeftHandle = event.target === leftHandle;

    document.documentElement.addEventListener('mousemove', doDrag, false);
    document.documentElement.addEventListener('mouseup', stopDrag, false);
  }

  const doDrag = (event) => {
    if (isLeftHandle) {
        // Calculate new width for left handle drag
        const newWidth = startWidth - 2 * (event.clientX - startX);
        if (newWidth > minWidth) {
            width = newWidth;
            onResize(newWidth);
        }
    } else {
        // Calculate new width for right handle drag
        const newWidth = startWidth + 2 * (event.clientX - startX);
        if (newWidth > minWidth) {
            width = newWidth;
            onResize(newWidth);
        }
    }
  }

  const stopDrag = () => {
    dispatcher('resizeEnd'); // Resize-end event dispatch
    document.documentElement.removeEventListener('mousemove', doDrag, false);
    document.documentElement.removeEventListener('mouseup', stopDrag, false);
  }

</script>

<div class="canvas-resizer" bind:this={resizable} style:width={`${width}px`}>
  <div class="left" on:mousedown={initDrag} bind:this={leftHandle}></div>
  <div class="right" on:mousedown={initDrag} bind:this={rightHandle}></div>
</div>

<style lang="scss">
  .canvas-resizer {
    position: sticky;
    height: 100%;
    top: 0;
    z-index: 0;

    .left, .right {
      height: 60px;
      width: 20px;
      top: 50%;
      transform: translateY(-50%);
      position: absolute;
      cursor: ew-resize;
      border-radius: 5px;

      &:before, &:after {
        content: "";
        background-color: rgba(255, 255, 255, 0.20);
        width: 5px;
        height: inherit;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        border-radius: inherit;
      }

      &:after {
        height: 50%;
      }

      &:hover {
        &:before, &:after {
          background-color: rgba(255, 255, 255, 0.50);
        }
      }
    }

    .left {
      margin-left: -30px;

      &:before {
        right: 0;
      }

      &:after {
        right: 10px;
        margin-left: -10px;
      }
    }

    .right {
      left: 100%;
      margin-left: 10px;

      &:after {
        right: 5px;
      }
    }
  }
</style>