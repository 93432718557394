<script lang="ts">
  import getUserComponentScaffold from "../../firebase/getUserComponentScaffold";
  import { interfaceOverlay, newComponent } from "../../stores/uiStore";
  import { OptionsMenu } from "../atoms";
  import { ComponentMenuItem } from "../molecules";
  import { setScaffold } from "../../scaffold/ScaffoldCoordinator";
    import { scaffoldDataSource } from "../../stores/scaffoldStore";

  export let items;
  export let deleteHandler;

  const componentSelectionHandler = async (data) => {
    let selectionScaffold;
    try {
      selectionScaffold = await getUserComponentScaffold(data.downloadURL);
    } catch(error) {
      console.error(error);
      alert(error);
    }
    setScaffold(JSON.parse(selectionScaffold));
    newComponent.set({
      id: data.componentId,
      docId: data.docId,
      name: data.componentName,
      method: 'from-existing',
      title: data.title,
      description: data.description,
      dataSource: data.dataSource
    });
    if( data.dataSource ) {
      scaffoldDataSource.set(data.dataSource);
    }
  }

  const options = [
    {label: 'Delete forever', value: 'delete'}
  ]

  const menuClickHandler = (id, menu) => {
    const rect = menu.getBoundingClientRect();
    interfaceOverlay.set({
      component: OptionsMenu,
      props: {
        options: options,
        onClick: (value) => {
          if( value === 'delete' ) {
            deleteHandler(id);
          }
        }
      },
      containerStyle: `top:${rect.bottom}px; left:${rect.left}px; width: 100px; --ui-theme: #fa5b5a`
    })
  }
</script>

<div>
  {#each items as item}
    <ComponentMenuItem
      name={item.componentName}
      id={item.componentId}
      deleteHandler={deleteHandler}
      menuClickHandler={menuClickHandler}
      thumbnail="/images/business/preview-placeholder.svg"
      on:click={() => componentSelectionHandler(item)}
    />
  {/each}
</div>

<style lang="scss">
  div {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 15px;
    row-gap: 30px;
  }
</style>