import { v4 as uuidv4 } from 'uuid';
import { ELEMENT } from '../scaffold/ScaffoldRegulator';

type Scaffold = {
  tag: string;
  attributes?: { [key: string]: string };
  nested?: Scaffold[];
  text?: string;
};

const ELEMENTS_ATTR = {
  'div': ELEMENT.container,
  'ul': ELEMENT.list,
  'ol': ELEMENT.list,
  'li': ELEMENT.listItem,
  'button': ELEMENT.button,
  'span': ELEMENT.span,
  'p': ELEMENT.paragraph,
  'h1': ELEMENT.heading,
  'h2': ELEMENT.heading,
  'h3': ELEMENT.heading,
  'h4': ELEMENT.heading,
  'h5': ELEMENT.heading,
  'h6': ELEMENT.heading,
  'hr': ELEMENT.divider,
  'img': ELEMENT.image,
  'input': ELEMENT.textfield,
  'textarea': ELEMENT.textarea,
  'a': 'link'
}

const determineElement = (tag, classList) => {
  if( tag === 'i' && classList.contains('fa-solid') || classList.contains('fa-brands') || classList.contains('fa-regular') || classList.contains('fa-sharp') ) return 'icon'
  return ELEMENTS_ATTR[tag] ?? tag;
}

function htmlToScaffold(html: string): Scaffold[] {
  const parser = new DOMParser();
  const doc = parser.parseFromString(`<body>${html.trim()}</body>`, 'text/html');

  function convertNodeToScaffold(node: ChildNode): Scaffold | null {
    if (node.nodeType === Node.TEXT_NODE && node.textContent?.trim()) {
      return { text: node.textContent.trim() };
    }

    if (node.nodeType === Node.ELEMENT_NODE) {
      const element = node as Element;
      const tagName = element.tagName.toLowerCase();
      const scaffold: Scaffold = {
        tag: tagName,
        attributes: {
          'data-id': uuidv4(),
          'data-element': determineElement(tagName, element.classList)
        },
        nested: []
      };

      // remove contenteditable if already present. will be properly applied below
      if( element.hasAttribute('contenteditable') ) {
        element.removeAttribute('contenteditable')
      }
      
      // if text node, add contenteditable attribute
      if( element && element.childNodes.length === 1 && element.childNodes[0].nodeType === Node.TEXT_NODE ) {
        scaffold.attributes['contenteditable'] = 'plaintext-only';
      } else if( element && element.childNodes.length > 1 ) {
        // wraps loose text with a span to allow targeted text editing, and preventing removal of other non-text nodes within the contenteditable element
        element.childNodes.forEach(n => {
          if ( n.nodeType === 3 && n.textContent.trim() !== '' ) { // Node is a text node
            const span = document.createElement('span');
            span.setAttribute('contenteditable', 'plaintext-only');
            span.textContent = n.textContent;
            element.replaceChild(span, n);
          }
        });
      }

      for (const attr of element.attributes) {
        scaffold.attributes![attr.name] = attr.value;
      }

      element.childNodes.forEach(child => {
        const childScaffold = convertNodeToScaffold(child);
        if (childScaffold) {
          scaffold.nested!.push(childScaffold);
        }
      });

      return scaffold;
    }

    return null;
  }

  return Array.from(doc.body.childNodes)
    .map(convertNodeToScaffold)
    .filter((scaffold): scaffold is Scaffold => scaffold !== null);
}

export default htmlToScaffold;