<script lang="ts">
  import Prism from 'prismjs';
  import beautify from 'js-beautify';
  import IconButton from '../atoms/IconButton.svelte';
  import Icon from '@iconify/svelte';
  import cleanExportHTML from '../../helpers/cleanExportHTML';
  import { onMount } from 'svelte';
  import 'prismjs/themes/prism-tomorrow.css';

  export let css: string = '';
  export let html: string = '';

  let cleanCode, formattedCode, highlightedCode;

  onMount(() => {
    cleanCode = cleanExportHTML(html);
    formattedCode = beautify.html(cleanCode);
    highlightedCode = Prism.highlight(formattedCode, Prism.languages.html, 'html');
  });

  const copyCode = () => {
    navigator.clipboard.writeText(formattedCode);
  }
</script>

<div class="copy-button">
  <IconButton title="copy code" on:click={copyCode}>
    <Icon icon="eva:clipboard-outline" width="16px"/>
  </IconButton>
</div>
<pre>
  <code>
    {@html highlightedCode}
  </code>
</pre>

<style lang="scss">
  pre {
    user-select: text;
    overflow-x: scroll;
    margin: 0;
    height: 100%;
    padding-left: 15px;
    background-color: $background-color-index-4;
    border-radius: $border-radius-2;
    border: solid 1px $background-color-index-4-offset;
    @include custom-scrollbar;
  }

  .copy-button {
    position: sticky;
    top: 20px;
    height: 0;
    text-align: right;
    padding-right: 20px;
    display: block;
    transform: translateY(20px);
  }
</style>