<script lang="ts">
  import Icon from "@iconify/svelte";
  import IconButton from "../atoms/IconButton.svelte";
  import Videos from "./Videos.svelte";
  import { showTutorials } from "../../stores/uiStore";
  import { fly } from "svelte/transition";
  import { quintOut } from "svelte/easing";
  import videoTutorials from "../../utils/_video-tutorials";
</script>

<article transition:fly={{ duration: 300, x: 0, y: 5, easing: quintOut }}>
  <header>
    <h5>Getting Started</h5>
    <IconButton on:click={() => showTutorials.set(false)}>
      <Icon icon="uil:times"/>
    </IconButton>
  </header>
  <div class="content">
    <Videos videos={videoTutorials}/>
  </div>
</article>

<style lang="scss">
  article {
    background-color: $lightest;
    width: 300px;
    border-radius: $border-radius-1;
    color: #666;
    box-shadow: $shadow-2;
    border: solid 4px $lightest;
  }

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 3px;

    h5 {
      margin: 0;
      line-height: 1;
      padding: 10px 5px;
    }
  }

  .content {
    background-color: #292929;
    height: 300px;
    width: inherit;
    border-radius: inherit;
    overflow-y: scroll;
    overflow-x: hidden;
    @include custom-scrollbar(transparent, #666);
  }
</style>