<script lang="ts">
  import { selectedElementPrioritized } from "../../stores/scaffoldStore";
  import { showMarker } from "../../stores/uiStore";

  export let top: number, left: number, width: number, height: number;
  export let id: string;
  export let element: string;
  export let isSelected: boolean;
</script>

<div
  class:selected={isSelected}
  class:prioritize={isSelected && $selectedElementPrioritized}
  class:show-marker={$showMarker === id}
  role="button"
  data-element={!$selectedElementPrioritized ? element : `★ ${element}`}
  style:top="{top}px"
  style:left="{left}px"
  style:width="{width}px"
  style:height="{height}px"
  on:click
/>

<style lang="scss">
  div {
    position: absolute;
    @include marker-outline($ui-theme-color-backtrack);

    &:hover {
      outline-style: solid;

      &:before {
        content: attr(data-element);
      }
    }

    &.selected {
      @include marker-pattern($ui-theme-color-backtrack);

      &:before {
        content: attr(data-element);
      }
    }

    // prioritize
    &.prioritize {
      @include marker-outline($ui-theme-color-backtrack);
      z-index: 100000;
      &:before {
        content: attr(data-element);
      }
    }

    &.show-marker {
      @include marker-outline($ui-theme-color-backtrack);
      outline-style: solid;

      &:before {
        content: attr(data-element);
      }
    }
  }
</style>