<script lang="ts">
  import Icon from "@iconify/svelte";
  import { interfaceOverlay } from "../../stores/uiStore";

  export let icon: string;

  const iconClickHandler = () => {
    $interfaceOverlay = null;
  }
</script>

<div role="button" on:click={iconClickHandler}>
  <Icon icon={icon} width={40}/>
</div>

<style lang="scss">
  div {
    text-align: center;
    padding: 20px;
    cursor: pointer;
    flex: 1 0 5%;

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
      border-radius: $border-radius-2;
    }
  }
</style>