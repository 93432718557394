<script lang="ts">
  import Icon from "@iconify/svelte";

  export let icon: string;
</script>

<button on:click>
  <span><Icon icon={icon}/></span>&nbsp;<slot/>
</button>

<style lang="scss">
  button {
    @include clear-default-button-styling;
    padding: 5px 10px;
    line-height: 1;
    font-weight: 500;
    margin-top: 15px;
    border: solid 1px #e5e5e5;
    border-radius: $border-radius-2;
    font-size: 0.95em;

    &:hover {
      color: var(--ui-theme);
    }

    &:active {
      background-color: var(--ui-theme);
      color: $lightest;
    }

    span {
      vertical-align: top;
    }
  }
</style>