<script lang="ts">
  import ScaffoldCoordinator from '../../scaffold/ScaffoldCoordinator';
  import { Label, ViewSection } from '../atoms';
  import { InputRange, SpacingSelector } from '../molecules';

  const arbitraryRegex = (property: string): RegExp => new RegExp(`^${property}-\\[[^\\]]+\\]`);

  let marginInputDisabled = true,
      paddingInputDisabled = true

  $: selected = {
    marginTop: false,
    marginRight: false,
    marginBottom: false,
    marginLeft: false,
    center: false,
    paddingTop: false,
    paddingRight: false,
    paddingBottom: false,
    paddingLeft: false,
  }

  $: {
    marginInputDisabled = !(selected.marginTop || selected.marginRight || selected.marginBottom || selected.marginLeft)
    paddingInputDisabled = !(selected.paddingTop || selected.paddingRight || selected.paddingBottom || selected.paddingLeft)
  }

  const selectHandler = (event) => {
    if( event.detail === 'center' ) {
      selected = {
        marginTop: !selected.center ? true : false,
        marginRight: !selected.center ? true : false,
        marginBottom: !selected.center ? true : false,
        marginLeft: !selected.center ? true : false,
        center: !selected.center,
        paddingTop: selected.center,
        paddingRight: selected.center,
        paddingBottom: selected.center,
        paddingLeft: selected.center,
      }
      return;
    }
    selected[event.detail] = !selected[event.detail];
  }

  const conflictingMarginRegex = arbitraryRegex('m');
  const conflictingMarginTopRegex = arbitraryRegex('mt');
  const conflictingMarginRightRegex = arbitraryRegex('mr');
  const conflictingMarginBottomRegex = arbitraryRegex('mb');
  const conflictingMarginLeftRegex = arbitraryRegex('ml');
  const conflictingPaddingRegex = arbitraryRegex('p');
  const conflictingPaddingTopRegex = arbitraryRegex('pt');
  const conflictingPaddingRightRegex = arbitraryRegex('pr');
  const conflictingPaddingBottomRegex = arbitraryRegex('pb');
  const conflictingPaddingLeftRegex = arbitraryRegex('pl');

  const marginHandler = (event) => {
    if( selected.marginTop && selected.marginRight && selected.marginBottom && selected.marginLeft ) {
      if( event.detail !== '' ) {
        ScaffoldCoordinator.addTailwindClass(`m-[${event.detail}px]`);
      } else {
        ScaffoldCoordinator.removeClass(conflictingMarginRegex);
      }
    } else {
      if( selected.marginTop ) {
        if( event.detail !== '' ) {
          ScaffoldCoordinator.addTailwindClass(`mt-[${event.detail}px]`);
        } else {
          ScaffoldCoordinator.removeClass(conflictingMarginTopRegex);
        }
      }
      if( selected.marginRight ) {
        if( event.detail !== '' ) {
          ScaffoldCoordinator.addTailwindClass(`mr-[${event.detail}px]`);
        } else {
          ScaffoldCoordinator.removeClass(conflictingMarginRightRegex);
        }
      }
      if( selected.marginBottom ) {
        if( event.detail !== '' ) {
          ScaffoldCoordinator.addTailwindClass(`mb-[${event.detail}px]`);
        } else {
          ScaffoldCoordinator.removeClass(conflictingMarginBottomRegex);
        }
      }
      if( selected.marginLeft ) {
        if( event.detail !== '' ) {
          ScaffoldCoordinator.addTailwindClass(`ml-[${event.detail}px]`);
        } else {
          ScaffoldCoordinator.removeClass(conflictingMarginLeftRegex);
        }
      }
    }
  }

  const paddingHandler = (event) => {
    if( selected.paddingTop && selected.paddingRight && selected.paddingBottom && selected.paddingLeft ) {
      if( event.detail !== '' ) {
        ScaffoldCoordinator.addTailwindClass(`p-[${event.detail}px]`);
      } else {
        ScaffoldCoordinator.removeClass(conflictingPaddingRegex);
      }
    } else {
      if( selected.paddingTop ) {
        if( event.detail !== '' ) {
          ScaffoldCoordinator.addTailwindClass(`pt-[${event.detail}px]`);
        } else {
          ScaffoldCoordinator.removeClass(conflictingPaddingTopRegex);
        }
      }
      if( selected.paddingRight ) {
        if( event.detail !== '' ) {
          ScaffoldCoordinator.addTailwindClass(`pr-[${event.detail}px]`);
        } else {
          ScaffoldCoordinator.removeClass(conflictingPaddingRightRegex);
        }
      }
      if( selected.paddingBottom ) {
        if( event.detail !== '' ) {
          ScaffoldCoordinator.addTailwindClass(`pb-[${event.detail}px]`);
        } else {
          ScaffoldCoordinator.removeClass(conflictingPaddingBottomRegex);
        }
      }
      if( selected.paddingLeft ) {
        if( event.detail !== '' ) {
          ScaffoldCoordinator.addTailwindClass(`pl-[${event.detail}px]`);
        } else {
          ScaffoldCoordinator.removeClass(conflictingPaddingLeftRegex);
        }
      }
    }
  }
</script>

<ViewSection>
  <div class="layout">
    <div>
      <span class="label">Select Spacing</span>
      <SpacingSelector selected={selected} on:select={selectHandler}/>
    </div>
    <div class="controls">
      <div>
        <Label>Margin</Label>
        <InputRange disabled={marginInputDisabled} on:input={marginHandler}/>
      </div>
      <div class="slider">
        <Label>Padding</Label>
        <InputRange disabled={paddingInputDisabled} on:input={paddingHandler}/>
      </div>
    </div>
  </div>
</ViewSection>

<style lang="scss">
  .layout {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 15px;
  }

  .controls {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-column-gap: 6px;
    row-gap: 10px;
  }

  .label {
    @include input-label;
  }
</style>