<script lang="ts">
  import Icon from "@iconify/svelte";

  export let icon: string;
  export let style: string = '';
</script>

<button type="button" on:click style={style}>
  <span>
    <slot/>
  </span>
  {#if icon}
    <span>
      <Icon icon={icon} width="22"/>
    </span>
  {/if}
</button>

<style lang="scss">
  button {
    @include clear-default-button-styling;
    background-color: var(--ui-theme);
    padding: 10px;
    border-radius: $border-radius-2;
    font-weight: 700;
    color: $lightest;
    display: flex;
    align-items: center;
    box-shadow: inset 0px 0px 0px 2px rgba(255, 255, 255, 0.3);

    span {
      display: contents;
    }
  }
</style>