import {
  arrayUnion,
  collection,
  doc,
  getDoc,
  setDoc,
  updateDoc,
} from 'firebase/firestore';

import {
  firestore
} from './firebase'

const addToUserCollection = async (userId, collectionId) => {
  const userCollection = collection(firestore, 'user-collection');
  const userDoc = doc(userCollection, userId); 
  
  // First, check if the document exists
  const docSnap = await getDoc(userDoc);
  
  if (docSnap.exists()) {
    // Update the existing document
    await updateDoc(userDoc, {
      "collectionIds": arrayUnion(collectionId)
    });
  } else {
    // Document doesn't exist yet, create it
    await setDoc(userDoc, {
      collectionIds: [collectionId]
    });
  }
  
  return userId;
}

export default addToUserCollection;