<script lang="ts">
  import Icon from "@iconify/svelte";
  import { createEventDispatcher } from "svelte";

  const dispatch = createEventDispatcher();

  export let steps;
  export let activeIndex: number;

  const clickHandler = (stepIndex: number) => {
    if( !steps[stepIndex].complete ) return;
    dispatch('click', stepIndex);
  }
</script>

<div class="stepper">
  {#each steps as step, index}
    <div class:current-step={activeIndex === index} on:click={() => clickHandler(index)}>
      <span>
        {#if step.complete}
          <Icon icon="eva:checkmark-circle-2-fill" width="40" color="#00bbff"/>
        {:else}
          {#if activeIndex === index}
            <Icon icon="eva:radio-button-on-fill" width="40"/>
          {:else}
            <Icon icon="eva:radio-button-off-fill" width="40" color="#666"/>
          {/if}
        {/if}
      </span>
      <!--<h5>{step.label}</h5>-->
    </div>
    {#if index !== steps.length - 1}
      <i class="timeline" class:gone-through={index < activeIndex}></i>
    {/if}
  {/each}
</div>

<style lang="scss">
  .stepper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3px;
    font-size: 0;

    > div {
      display: flex;
      flex-direction: column;
      text-align: center;
      gap: 5px;
      justify-content: center;
      position: relative;

      span {
        display: block;
      }

      h5 {
        margin: 0;
        //position: absolute;
        white-space: nowrap;
      }

      &.current-step {
        color: $ui-theme-color-general;
      }
    }
  }

  .timeline {
    width: 70px;
    height: 4px;
    background-color: #666;
    position: relative;

    &:before {
      content: "";
      width: 0;
      height: inherit;
      background-color: $ui-theme-color-general;
      position: absolute;
      transition: width .30s;
    }

    &.gone-through {
      &:before {
        width: 100%;
      }
    }
  }
</style>