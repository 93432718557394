import TAILWIND_SETS from "./_tailwind-sets";

// text tool
export const textAlignOptions = [
  {label: {icon: 'majesticons:text-align-left', width: '20px', hFlip: false}, value: 'text-left'},
  {label: {icon: 'majesticons:text-align-center', width: '20px', hFlip: false}, value: 'text-center'},
  {label: {icon: 'majesticons:text-align-left', width: '20px', hFlip: true}, value: 'text-right'},
  {label: {icon: 'majesticons:text-align-justify', width: '20px', hFlip: true}, value: 'text-justify'},
]

export const styleOptions = [
  {label: {value: 'uppercase', icon: 'ph:text-t-duotone', width: '19px', hFlip: false}},
  {label: {value: 'italic', icon: 'ph:text-italic-bold', width: '18px', hFlip: false}},
  {label: {value: 'underline', icon: 'mi:underline', width: '18px', hFlip: false}},
  {label: {value: 'line-through', icon: 'fluent:text-strikethrough-16-filled', width: '18px', hFlip: false}},
]

export const tagOptions = [
  {label: 'Paragraph', value: 'p'},
  {label: 'Heading 1', value: 'h1'},
  {label: 'Heading 2', value: 'h2'},
  {label: 'Heading 3', value: 'h3'},
  {label: 'Heading 4', value: 'h4'},
  {label: 'Heading 5', value: 'h5'},
  {label: 'Heading 6', value: 'h6'},
]

export const weightOptions = TAILWIND_SETS['fontWeight'].map(w => ({label: w.split('-')[1], value: w}));

export const webSafeFontsOptions = [
  {label: 'Sans', value: 'font-sans'},
  {label: 'Serif', value: 'font-serif'},
  {label: 'Mono', value: 'font-mono'},
]

export const sizeUnitOptions = ['px', 'em', 'rem'];

// input types
export const InputTypeOptions = [
  {label: 'Text', value: 'text'},
  {label: 'Email Address', value: 'email'},
  {label: 'Password', value: 'password'},
  {label: 'Phone Number', value: 'tel'},
  {label: 'Date', value: 'date'},
  {label: 'Number', value: 'number'},
  {label: 'URL', value: 'url'},
]

// position tool
export const positionOptions = [
  {label: 'Relative', value: 'relative'},
  {label: 'Absolute', value: 'absolute'},
  {label: 'Fixed', value: 'fixed'},
  {label: 'Sticky', value: 'sticky'},
]

// list
export const listOptions = [
  {label: {icon: 'tabler:x', width: '20px', hFlip: false}, value: 'list-none'},
  {label: {icon: 'fa-solid:list-ul', width: '20px', hFlip: false}, value: 'list-disc'},
  {label: {icon: 'fa-solid:list-ol', width: '20px', hFlip: false}, value: 'list-decimal'},
];

export const listPositionOptions = [
  {label: 'Inside', value: 'list-inside'},
  {label: 'Outside', value: 'list-outside'}
]

// dimensions tool
export const dimensionsUnits = ['px', '%', 'vh', 'vw'];
export const dimensionsUnitOptions = [
  {label: 'PX', value: 'px'},
  {label: '%', value: '%'},
  {label: 'VH', value: 'vh'},
  {label: 'VW', value: 'vw'},
]

// border
export const borderOptions = [
  {label: 'None', value: 'border-none'},
  {label: 'Solid', value: 'border-solid'},
  {label: 'Dashed', value: 'border-dashed'},
  {label: 'Dotted', value: 'border-dotted'},
  {label: 'Double', value: 'border-double'},
]

export const borderRadiusUnitOptions = [
  {label: 'px', value: 'px'},
  {label: '%', value: '%'},
]

// background
export const backgroundToggleOptions = [
  {label: 'Color', value: 'color'},
  {label: 'Image / Gradient', value: 'image'},
]

export const clipOptions = [
  {label: 'None', value: 'none'},
  {label: 'Border box', value: 'border-box'},
  {label: 'Padding box', value: 'padding-box'},
  {label: 'Content box', value: 'content-box'},
  {label: 'Text', value: 'text'},
]

export const attachmentOptions = [
  {label: 'Scroll', value: 'scroll'},
  {label: 'Fixed', value: 'fixed'},
]

export const sizeOptions = [
  {label: 'Auto', value: 'bg-auto'},
  {label: 'Cover', value: 'bg-cover'},
  {label: 'Contain', value: 'bg-contain'},
]

export const repeatOptions = [
  {label: {icon: 'tabler:x', width: '20px', hFlip: false}, value: 'left'},
  {label: {icon: 'oi:grid-three-up', width: '12px', hFlip: false, rotate: 0}, value: 'center'},
  {label: {icon: 'bx:dots-vertical', width: '20px', hFlip: false, rotate: 1}, value: 'right'},
  {label: {icon: 'bx:dots-vertical', width: '20px', hFlip: false, rotate: 0}, value: 'right'},
]