<script lang="ts">
  import Icon from "@iconify/svelte";
  import { interfaceOverlay } from "../../stores/uiStore";
  import { IconsLib } from "../templates";

  export let color = '#000';

  const showIconsLib = () => {
    interfaceOverlay.set({
      component: IconsLib,
      props: {},
      containerStyle: `
        width: 700px;
        height: 500px;
        max-width: 100%;
        max-height: 100%;
        margin: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      `
    });
  }
</script>

<div on:click={showIconsLib}>
  <Icon icon="eva:cube-fill" width={100} color={color}/>
</div>

<style lang="scss">
  div {
    cursor: pointer;
    width: 100%;
    height: 101px;
    opacity: 0.8;
    justify-content: center;
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    border-radius: $border-radius-2;
    @include chessboard-background-pattern;
  }
</style>