<script lang="ts">
  export let label: string;
  export let description: string;
  export let selected: boolean;
</script>

<div class="option" class:selected={selected} on:click>
  <div>
    <slot/>
  </div>
  <div>
    <h4>{label}</h4>
    <p>{description}</p>
  </div>
</div>

<style lang="scss">
  .option {
    display: grid;
    grid-template-columns: auto 1fr;
    border: solid 2px #313131;
    padding: 20px 15px;
    box-sizing: border-box;
    gap: 15px;
    border-radius: $border-radius-2;
    position: relative;
    background-color: #252525;
    box-shadow: $shadow-4;
    cursor: pointer;
    height: 100%;

    &:hover {
      border-color: #3e3e3e;

      &:after {
        background-color: #3e3e3e;
      }
    }

    &:after {
      content: "";
      width: 10px;
      height: 10px;
      border-radius: 10px;
      background-color: #313131;
      position: absolute;
      bottom: 15px;
      right: 15px;
    }

    &.selected {
      border-color: var(--ui-theme);

      &:after {
        background-color: var(--ui-theme);
      }
    }
  }
  

  h4 {
    margin-top: 4px;
    margin-bottom: 10px;
    font-size: 1.2em;
    font-weight: 600;
    line-height: 1;
  }

  p {
    font-size: 0.90em;
    line-height: 1.4;
    margin: 0;
    padding-right: 10px;
  }
</style>