<script lang="ts">
  import Icon from "@iconify/svelte";

  export let id: string;
  export let name: string;
  export let tags: string[];
  export let premium: boolean;
  export let thumbnail: string;
  export let previewURL: string;
</script>

<article>
  <div
    class="thumbnail"
    style:background-image="url(images/library/{id}/{thumbnail})"
    role="listitem"
    on:click
  >
    {#if premium}
      <div class="thumb-top">
        <Icon icon="iconoir:bright-crown" width={25} color="#fcc21a"/>
      </div>
    {/if}
    <!--<div class="thumb-bottom">
      {#each tags as tag}
        <LibItemTag>{tag}</LibItemTag>
      {/each}
    </div>-->
  </div>
  <div class="meta">
    <h4>{name}</h4>
    <a href={previewURL} target="_blank">
      <Icon icon="eva:eye-outline" width={25}/>
    </a>
  </div>
</article>

<style lang="scss">
  .thumbnail {
    height: 250px;
    cursor: pointer;
    position: relative;
    background-size: cover;
    background-position: center;
    overflow: hidden;
    box-shadow: $shadow-3;
    //border: solid 1px $background-color-index-4;
    border-radius: $border-radius-2;

    &:hover {
      border-color: var(--ui-theme);
    }

    .thumb-top {
      text-align: right;
    }

    .thumb-top,
    .thumb-bottom {
      padding: 15px;
    }

    .thumb-bottom {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgb(16,16,16);
      background: linear-gradient(180deg, rgba(16,16,16,0) 0%, rgba(16,16,16,0.6853335084033614) 100%);
    }
  }

  .meta {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    align-items: center;

    h4 {
      margin: 0;
      font-weight: 500;
    }

    a {
      color: inherit;

      &:hover {
        color: var(--ui-theme);
      }
    }
  }
</style>