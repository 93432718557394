<script lang="ts">
  import { createEventDispatcher } from "svelte";

  export let selected;

  const dispatch = createEventDispatcher();
  
  const clickHandler = (selection) => {
    dispatch('select', selection);
  }
</script>

<div class="border-selector">
  <div class="top-left" class:selected={selected.cornerTopLeft} on:click={() => clickHandler('cornerTopLeft')}></div>
  <div class="top" class:selected={selected.borderTop} on:click={() => clickHandler('borderTop')}></div>
  <div class="top-right" class:selected={selected.cornerTopRight} on:click={() => clickHandler('cornerTopRight')}></div>
  <div class="left" class:selected={selected.borderLeft} on:click={() => clickHandler('borderLeft')}></div>
  <div class="center-toggle" class:selected={selected.center} on:click={() => clickHandler('center')}></div>
  <div class="right" class:selected={selected.borderRight} on:click={() => clickHandler('borderRight')}></div>
  <div class="bottom-left" class:selected={selected.cornerBottomLeft} on:click={() => clickHandler('cornerBottomLeft')}></div>
  <div class="bottom" class:selected={selected.borderBottom} on:click={() => clickHandler('borderBottom')}></div>
  <div class="bottom-right" class:selected={selected.cornerBottomRight} on:click={() => clickHandler('cornerBottomRight')}></div>
</div>

<style lang="scss">
  .border-selector {
    width: 101px;
    height: 101px;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 2px;
    border-radius: $border-radius-2;
    border-width: 3px;
    border-color: rgb(218, 218, 218);

    > div {
      position: relative;
      background-color: $background-color-index-4;
      border: solid 1px $background-color-index-4-offset;

      &.selected:not(.center-toggle) {
        &:before {
          border-color: $ui-theme-color-components;
        }
      }

      &:hover {
        background-color: $background-color-index-4-offset;
      }

      &:before {
        content: "";
        position: absolute;
        margin: auto;
        box-sizing: border-box;
        width: 15px;
        height: 15px;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        box-sizing: border-box;
        border-width: 3px;
        background-color: $background-index-1;
        border-color: rgb(218, 218, 218);
      }

      &.center-toggle {
        background-color: $background-index-1;

        &:before {
          background-color: transparent;
          border-radius: 100%;
          border-style: solid;
          border-color: $lightest;
        }

        &.selected {
          &:before {
            border-radius: 0;
          }
        }
      }

      &.top-left {
        border-top-left-radius: $border-radius-2;
        &:before {
          border-left-style: solid;
          border-top-style: solid;
        }
      }

      &.top:before {
        border-top-style: solid;
      }

      &.top-right {
        border-top-right-radius: $border-radius-2;
        &:before {
          border-right-style: solid;
          border-top-style: solid;
        }
      }

      &.left:before {
        border-left-style: solid;
      }

      &.right:before {
        border-right-style: solid;
      }

      &.bottom-left {
        border-bottom-left-radius: $border-radius-2;
        &:before {
          border-left-style: solid;
          border-bottom-style: solid;
        }
      }

      &.bottom:before {
        border-bottom-style: solid;
      }

      &.bottom-right {
        border-bottom-right-radius: $border-radius-2;
        &:before {
          border-right-style: solid;
          border-bottom-style: solid;
        }
      }
    }
  }
</style>