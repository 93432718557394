export default (
  canvasRightPos: number,
  canvasTopPos: number,
  viewWidth: number
) => {
  const windowWidth = window.innerWidth;
  const toolBarsThickness = 80; // TODO: get this and other values from the scss variable
  const canvasArea = windowWidth - (windowWidth - canvasRightPos + toolBarsThickness);
  const scale = 100 - (viewWidth * 100) / canvasArea;
  const scaleNumber = scale.toString().replace('.', '');
  const height = `${(window.innerHeight - canvasTopPos) * (1 / parseFloat('0.' + scaleNumber))}px`;
  return `transform: scale(0.${scaleNumber}); height: ${height};`;
};
