<script lang="ts">
  import { onMount } from "svelte";
  import ScaffoldCoordinator from "../../scaffold/ScaffoldCoordinator";
  import { selectedElementScaffold } from "../../stores/scaffoldStore";
  import { interfaceOverlay } from "../../stores/uiStore";
  import { ViewSection, Label, OptionsMenu } from "../atoms";
  import { InputField, ItemsListArea } from "../molecules";
  import tailwindCssClassSearch from '@dtinth/tailwind-css-class-search';

  // TODO: Improve class filter performance(debouncing), source tailwind file locally

  let classList = [];
  let searchQuery = '';
  let formElement;

  let searchResults = [];
  let formattedSearchResults = [];

  onMount(async () => {
    if( !window.hasOwnProperty('tailwindSearchIndex') ) {
      // get tailwind file for indexing
      const response = await fetch('https://unpkg.com/tailwindcss@2.2.19/dist/tailwind.css');
      const tailwindCSS = await response.text();
      // Generate a search index
      window.tailwindSearchIndex = await tailwindCssClassSearch(tailwindCSS);
    }
    if( formElement ) {
      formElement.querySelector('input').addEventListener('keydown', (event) => {
        if (event.key === 'Enter' || event.keyCode === 13) {
          event.preventDefault();
          addClass(searchQuery);
        }
      });
    }
  });

  $: {
    if( $selectedElementScaffold.selectedScaffold ) {
      const classes = ScaffoldCoordinator.getAllTailwindClasses();
      classList = !classes ? [] : classes.split(' ');
    }
  }

  $: {
    if( searchQuery !== '' && formElement && formattedSearchResults.length ) {
      const rect = formElement.getBoundingClientRect();
      interfaceOverlay.set({
        component: OptionsMenu,
        props: {
          options: formattedSearchResults,
          value: null,
          onClick: (value) => {
            addClass(value);
          }
        },
        containerStyle: `top:${rect.bottom}px; left:${rect.left}px; width:${rect.width}px; --ui-theme: #00cea8; height: 200px;`
      })
    } else {
      interfaceOverlay.set(null);
    }
  }

  const searchTailwindClass = async () => {
    if (window.hasOwnProperty('tailwindSearchIndex') && searchQuery) {
      searchResults = await window.tailwindSearchIndex.search(searchQuery);
      formattedSearchResults = searchResults.map(result => ({label: result.className, value: result.className}));
    }
  }

  const removeClass = (event) => {
    ScaffoldCoordinator.removeClass(classList[event.detail]);
  }

  const addClass = (value) => {
    ScaffoldCoordinator.addTailwindClass(value);
    searchQuery = '';
  }
</script>

<ViewSection>
  <div class="layout">
    <form on:submit|preventDefault bind:this={formElement}>
      <Label>Add Tailwind Class</Label>
      <InputField type="text" placeholder="Search by Tailwind class name, or CSS" leftIcon="bxl:tailwind-css" bind:value={searchQuery} on:input={searchTailwindClass}/>
    </form>
    {#if classList.length}
      <div>
        <ItemsListArea list={classList} on:click={removeClass}/>
      </div>
    {/if}
  </div>
</ViewSection>

<style lang="scss">
  .layout {
    display: grid;
    gap: 10px;
  }
</style>