<script lang="ts">
    import { CF_publishPage } from "../../firebase/firebase";
    import cleanExportHTML from "../../helpers/cleanExportHTML";
    import { canvasDOM, newComponent } from "../../stores/uiStore";
    import exportHTMLTemplate from "../../utils/_export-html-template";
    import { Label, ViewSection } from "../atoms";
    import UserButton from "../atoms/UserButton.svelte";
    import { InputField } from "../molecules";
    import InputToggle from "../molecules/InputToggle.svelte";

    let domain = '';
    let publish = 'sub';
    let endingPrefix = '.formulator.app';

    const publishOptions = [
      {label: 'Sub Domain', value: 'sub'},
      {label: 'Custom Domain (PRO)', value: 'custom'},
    ]

    const publishToggleHandler = (event) => {
      publish = event.detail;
    }

    const publishPageHandler = async () => {
      const regex = new RegExp(/^(?!-)[A-Za-z0-9-]+([\-\.]{1}[a-z0-9]+)*\.[A-Za-z]{2,6}$/);
      let fullDomain = `${domain}${endingPrefix}`;
      if( !fullDomain || !regex.test(fullDomain) ) {
        alert('Provide a valid domain name...');
        return;
      }
      const html = cleanExportHTML(exportHTMLTemplate($newComponent.title, $newComponent.description, $canvasDOM.body.outerHTML));
      const result = await CF_publishPage({domain, html});
      console.log(result);
    }
</script>

<ViewSection>
  <InputToggle initialValue={publish} options={publishOptions} on:change={publishToggleHandler}/>
  {#if publish === 'sub'}
    <div class="layout">
      <div>
        <Label>Sub Domain</Label>
        <InputField placeholder="example" leftIcon="eva:globe-fill" bind:value={domain}/>
        <span class="domain-preview">{!domain ? 'example' : domain}<span>.formulator.app</span></span>
        <div class="publish-btn">
          <UserButton icon="eva:checkmark-outline" on:click={publishPageHandler}>Publish Your Page</UserButton>
        </div>
      </div>
    </div>
  {/if}
</ViewSection>

<style lang="scss">
  .layout {
    display: grid;
    row-gap: 10px;
    margin-top: 10px;
  }

  .domain-preview {
    margin-top: 10px;
    margin-bottom: 10px;
    display: block;

    span {
      opacity: 0.8;
    }
  }

  .publish-btn {
    display: flex;
    justify-content: end;
  }
</style>