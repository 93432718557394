<script lang="ts">
  import getClassFromSet from "../../helpers/getClassFromSet";
  import extractNumberFromString from "../../helpers/getNumberFromString";
  import getUnitFromArbitraryClass from "../../helpers/getUnitFromArbitraryClass";
  import ScaffoldCoordinator from "../../scaffold/ScaffoldCoordinator";
  import { selectedElementScaffold } from "../../stores/scaffoldStore";
  import TAILWIND_SETS from "../../utils/_tailwind-sets";
  import { borderOptions, borderRadiusUnitOptions } from "../../utils/_tool-options";
  import { Label, ViewSection } from "../atoms";
  import { BordersSelector, InputColor, InputField, InputRange, InputSelect } from "../molecules";

  // TODO: Values can be updated, but sometimes true value is not reflected in the tool initially and class names get mixed up. In other words, this is a pain in the ass

  let values;
  let borderInputsDisabled = true;
  let radiusInputsDisabled = true;

  $: selected = {
    borderTop: false,
    borderRight: false,
    borderBottom: false,
    borderLeft: false,
    center: false,
    cornerTopLeft: false,
    cornerTopRight: false,
    cornerBottomRight: false,
    cornerBottomLeft: false
  }

  const arbitraryRegex = (property: string): RegExp => new RegExp(`^${property}-\\[[^\\]]+\\]`);

  $: {
    const classList = $selectedElementScaffold.selectedScaffold?.attributes?.class ?? '';
    values = {
      borderStyle: getClassFromSet(classList, TAILWIND_SETS['borderStyle']),
      borderRadius: extractNumberFromString(getClassFromSet(classList, arbitraryRegex('rounded')) ?? ''),
      borderWidth: '',
      borderRadiusUnit: getUnitFromArbitraryClass(getClassFromSet(classList, arbitraryRegex('rounded')) ?? '') ?? 'px'
    }
  }

  $: {
    borderInputsDisabled = !(selected.borderTop || selected.borderRight || selected.borderBottom || selected.borderLeft);
    radiusInputsDisabled = !(selected.cornerTopLeft || selected.cornerTopRight || selected.cornerBottomRight || selected.cornerBottomLeft);
  }

  const selectHandler = (event) => {
    if( event.detail === 'center' ) {
      selected = {
        borderTop: !selected.center,
        borderRight: !selected.center,
        borderBottom: !selected.center,
        borderLeft: !selected.center,
        center: !selected.center,
        cornerTopLeft: selected.center,
        cornerTopRight: selected.center,
        cornerBottomRight: selected.center,
        cornerBottomLeft: selected.center
      }
      return;
    }
    selected[event.detail] = !selected[event.detail];
  }

  const borderRadiusHandler = (event) => {
    if( selected.cornerTopLeft && selected.cornerTopRight && selected.cornerBottomRight && selected.cornerBottomLeft ) {
      ScaffoldCoordinator.addTailwindClass(`rounded-[${event.detail}${values.borderRadiusUnit}]`);
    } else {
      if( selected.cornerTopLeft ) ScaffoldCoordinator.addTailwindClass(`rounded-tl-[${event.detail}${values.borderRadiusUnit}]`);
      if( selected.cornerTopRight ) ScaffoldCoordinator.addTailwindClass(`rounded-tr-[${event.detail}${values.borderRadiusUnit}]`);
      if( selected.cornerBottomRight ) ScaffoldCoordinator.addTailwindClass(`rounded-br-[${event.detail}${values.borderRadiusUnit}]`);
      if( selected.cornerBottomLeft ) ScaffoldCoordinator.addTailwindClass(`rounded-bl-[${event.detail}${values.borderRadiusUnit}]`);
    }
  }

  const borderWidthHandler = (event) => {
    let width = !event.detail ? 0 : event.detail;
    if( selected.borderTop && selected.borderRight && selected.borderBottom && selected.borderLeft ) {
      ScaffoldCoordinator.addTailwindClass(`border-[${width}px]`);
    } else {
      if( selected.borderTop ) ScaffoldCoordinator.addTailwindClass(`border-t-[${width}px]`);
      if( selected.borderRight ) ScaffoldCoordinator.addTailwindClass(`border-r-[${width}px]`);
      if( selected.borderBottom ) ScaffoldCoordinator.addTailwindClass(`border-b-[${width}px]`);
      if( selected.borderLeft ) ScaffoldCoordinator.addTailwindClass(`border-l-[${width}px]`);
    }
  }

  const borderStyleHandler = (event) => {
    ScaffoldCoordinator.addTailwindClass(event.detail);
  }

  const borderColorHandler = (event) => {
    if( selected.borderTop && selected.borderRight && selected.borderBottom && selected.borderLeft ) {
      ScaffoldCoordinator.addTailwindClass(`border-[${event.detail}]`);
    } else {
      if( selected.borderTop ) ScaffoldCoordinator.addTailwindClass(`border-t-[${event.detail}]`);
      if( selected.borderRight ) ScaffoldCoordinator.addTailwindClass(`border-r-[${event.detail}]`);
      if( selected.borderBottom ) ScaffoldCoordinator.addTailwindClass(`border-b-[${event.detail}]`);
      if( selected.borderLeft ) ScaffoldCoordinator.addTailwindClass(`border-l-[${event.detail}]`);
    }
  }

  const unitChangeHandler = (event) => {
    values.borderRadiusUnit = event.detail
  }
</script>

<ViewSection>
  <div class="layout">
    <div>
      <span class="label">Select Borders</span>
      <BordersSelector selected={selected} on:select={selectHandler}/>
    </div>
    <div class="controls">
      <div class="style">
        <Label>Style</Label>
        <InputSelect value={values.borderStyle} on:change={borderStyleHandler} options={borderOptions} disabled={borderInputsDisabled}/>
      </div>
      <div>
        <Label>Width</Label>
        <!-- TODO: Omited value from width because class name gets mixed up -->
        <InputField type="number" on:input={borderWidthHandler} disabled={borderInputsDisabled}/>
      </div>
      <div>
        <Label>Color</Label>
        <InputColor value={''} on:input={borderColorHandler} disabled={borderInputsDisabled}/>
      </div>
      <div class="slider">
        <Label>Border Radius</Label>
        <InputRange
          unitOptions={borderRadiusUnitOptions}
          unit={values.borderRadiusUnit}
          minRange={0}
          maxRange={100}
          initialValue={values.borderRadius}
          on:input={borderRadiusHandler}
          on:unitClick={unitChangeHandler}
          disabled={radiusInputsDisabled}
        />
      </div>
    </div>
  </div>
</ViewSection>

<style lang="scss">
  .layout {
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 15px;
  }

  .controls {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 6px;
    row-gap: 10px;
  }

  .slider, .style {
    grid-column-start: 1;
    grid-column-end: 4;
    align-self: flex-end;
  }

  .label {
    @include input-label;
  }
</style>