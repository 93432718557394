const TAILWIND_SETS = {
  textAlign: [
    'text-left',
    'text-center',
    'text-right',
    'text-justify',
    'text-start',
    'text-end'
  ],
  textStyle: [
    'underline',
    'uppercase',
    'italic',
    'line-through'
  ],
  fontWeight: [
    'font-thin',
    'font-extralight',
    'font-light',
    'font-normal',
    'font-medium',
    'font-semibold',
    'font-bold',
    'font-extrabold',
    'font-black',
  ],
  fontSize: [
    'text-xs',
    'text-sm',
    'text-base',
    'text-lg',
    'text-xl',
    'text-2xl',
    'text-3xl',
    'text-4xl',
    'text-5xl',
    'text-6xl',
    'text-7xl',
    'text-8xl',
    'text-9xl'
  ],
  lineHeight: [
    'leading-3',
    'leading-4',
    'leading-5',
    'leading-6',
    'leading-7',
    'leading-8',
    'leading-9',
    'leading-10',
    'leading-none',
    'leading-tight',
    'leading-snug',
    'leading-normal',
    'leading-relaxed',
    'leading-loose'
  ],
  fontFamily: [
    'font-sans',
    'font-serif',
    'font-mono'
  ],
  position: [
    'static',
    'fixed',
    'absolute',
    'relative',
    'sticky'
  ],
  list: [
    'list-none',
    'list-disc',
    'list-decimal'
  ],
  listPosition: [
    'list-inside',
    'list-outside'
  ],
  borderStyle: [
    'border-solid',
    'border-dashed',
    'border-dotted',
    'border-double',
    'border-hidden',
    'border-none'
  ],
  backgroundSize: [
    'bg-auto',
    'bg-cover',
    'bg-contain'
  ]
}

export default TAILWIND_SETS;