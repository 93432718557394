import {
  getDownloadURL,
  ref,
  uploadString,
} from '@firebase/storage';

import {
  storage
} from './firebase'

async function updateUserComponent(userId, componentId, scaffold) {
  const path = `user-components/${userId}/${componentId}.json`;
  
  // Create a reference with the user's unique ID
  const userComponentRef = ref(storage, path);
  
  // Upload the JSON string
  await uploadString(userComponentRef, scaffold, 'raw');
  
  // Get the download URL if needed
  const downloadURL = await getDownloadURL(userComponentRef);
  
  return downloadURL;
}

export default updateUserComponent;
