<script lang="ts">
  import { quintInOut } from "svelte/easing";
  import { fly } from "svelte/transition";
  import { BlurBackgroundFilter, LibItemTag } from "../atoms";
  import { activeNavItem, interfaceOverlay, viewWidth } from "../../stores/uiStore";
  import nav, { NAV_ITEM } from "../../utils/_nav-items";
  import addToUserCollection from "../../firebase/addToUserCollection";
  import Icon from "@iconify/svelte";
  import { authUser } from "../../stores/userStore";
  import { UserView } from "../views";

  export let props;

  let id: string = props.id;
  let name: string = props.name;
  let tags: string[] = props.tags;
  let description: string = props.description;
  let banner: string = props.banner;
  let showcase: string = props.showcase;

  let addingToCollection = false;

  const addToCollectionHandler = async () => {
    if( !$authUser ) {
      interfaceOverlay.set({
        component: UserView,
        props: {}
      });
      viewWidth.set(null);
      return false;
    }
    if( addingToCollection ) return;
    addingToCollection = true;
    await addToUserCollection($authUser.uid, id);
    interfaceOverlay.set(null);
    viewWidth.set(nav[NAV_ITEM.elements].viewWidth);
    activeNavItem.set(NAV_ITEM.collection);
  }
</script>

<BlurBackgroundFilter>
  <article class="theme-light" transition:fly={{duration: 500, x: 0, y: -50, easing: quintInOut}}>
    <div class="preview">
      <img src="/images/library/{id}/{banner}" alt={name}/>
      {#each showcase as image}
        <img src="/images/library/{id}/{image}" alt="{name} showcase image"/>
      {/each}
    </div>
    <div class="details">
      <div class="meta">
        <h3>{name}</h3>
        <div>
          {#each tags as tag}
            <LibItemTag>{tag}</LibItemTag>
          {/each}
        </div>
        <p>{description}</p>
      </div>
      <div class="use">
        <button type="button" on:click={addToCollectionHandler}>
          {#if addingToCollection}
            <Icon icon="svg-spinners:blocks-shuffle-3"/>
          {:else}
            Add to Collection
          {/if}
        </button>
      </div>
    </div>
  </article>
</BlurBackgroundFilter>

<style lang="scss">
  article {
    @include overlaying-content-container;
    max-width: 1100px;
    max-height: 600px;
    --ui-theme: #{$ui-theme-color-general};
    display: flex;

    &.theme-light {
      @include theme-light;
    }
  }

  .preview {
    background-color: #f7f7f9;
    flex-grow: 1;
    height: 100%;
    width: min-content;
    overflow: hidden scroll;
    @include custom-scrollbar;

    img {
      max-width: 100%;
    }
  }

  .details {
    background-color: $lightest;
    height: 100%;
    flex-basis: 350px;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
  }

  .meta {
    flex-grow: 1;
    padding: 20px 20px 0 20px;

    h3 {
      margin-top: 0;
    }

    p {
      margin-top: 30px;
      line-height: 1.5;
    }
  }

  .use {
    align-self: flex-end;
    padding: 20px;
    box-sizing: border-box;
    width: 100%;

    button {
      @include userButton;
    }
  }
</style>