<script lang="ts">
  import { activeNavItem } from "../../stores/uiStore";
  import { NAV_ITEM } from "../../utils/_nav-items";
</script>

{#if $activeNavItem !== NAV_ITEM.preview}
<section>
  <slot/>
</section>
{/if}

<style lang="scss">
  section {
    position: fixed;
    bottom: 20px;
    right: 40px;
    width: fit-content;
    height: fit-content;
    z-index: 99;
  }
</style>