<script lang="ts">
  import getClassFromSet from "../../helpers/getClassFromSet";
  import extractNumberFromString from "../../helpers/getNumberFromString";
  import ScaffoldCoordinator from "../../scaffold/ScaffoldCoordinator";
  import { selectedElementScaffold } from "../../stores/scaffoldStore";
  import TAILWIND_SETS from "../../utils/_tailwind-sets";
  import { positionOptions } from "../../utils/_tool-options";
  import { Label, ViewSection } from "../atoms";
  import { InputField, InputToggle } from "../molecules";

  const arbitraryPosRegex = (property: string): RegExp => new RegExp(`${property}-\\[[^\\]]+\\]`);

  let values;
  let disabledPositionInputs: boolean;

  $: {
    const classList = $selectedElementScaffold.selectedScaffold?.attributes?.class ?? '';
    values = {
      position: getClassFromSet(classList, TAILWIND_SETS['position']),
      top: extractNumberFromString(getClassFromSet(classList, arbitraryPosRegex('top')) ?? ''),
      right: extractNumberFromString(getClassFromSet(classList, arbitraryPosRegex('right')) ?? ''),
      bottom: extractNumberFromString(getClassFromSet(classList, arbitraryPosRegex('bottom')) ?? ''),
      left: extractNumberFromString(getClassFromSet(classList, arbitraryPosRegex('left')) ?? ''),
    }
    disabledPositionInputs = !values.position ? true : false;
  }

  const positionAmountHandler = (value, property) => {
    const conflictingRegex = arbitraryPosRegex(property);
    if( value ) {
      ScaffoldCoordinator.addTailwindClass(`${property}-[${value}px]`);
    } else {
      ScaffoldCoordinator.removeClass(conflictingRegex)
    }
  }

  const positionHandler = (event) => {
    if( event.detail !== values.position ) {
      ScaffoldCoordinator.addTailwindClass(event.detail);
    } else {
      ScaffoldCoordinator.removeClass(event.detail);
    }
  }
</script>

<ViewSection>
  <div class="position-type">
    <Label>Type</Label>
    <InputToggle
      options={positionOptions}
      initialValue={values.position}
      on:change={positionHandler}
    />
  </div>
  <div class="layout">
    <div>
      <Label>Top</Label>
      <InputField
        value={values.top}
        type="number"
        stepper={true}
        on:input={(event) => positionAmountHandler(event.detail, 'top')}
        disabled={disabledPositionInputs}
      />
    </div>
    <div>
      <Label>Bottom</Label>
      <InputField
        value={values.bottom}
        type="number"
        stepper={true} 
        on:input={(event) => positionAmountHandler(event.detail, 'bottom')}
        disabled={disabledPositionInputs}
      />
    </div>
    <div>
      <Label>Left</Label>
      <InputField 
        value={values.left}
        type="number"
        stepper={true} 
        on:input={(event) => positionAmountHandler(event.detail, 'left')}
        disabled={disabledPositionInputs}
      />
    </div>
    <div>
      <Label>Right</Label>
      <InputField
        value={values.right}
        type="number"
        stepper={true} 
        on:input={(event) => positionAmountHandler(event.detail, 'right')}
        disabled={disabledPositionInputs}
      />
    </div>
  </div>
</ViewSection>

<style lang="scss">
  .layout {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
  }

  .position-type {
    margin-bottom: 10px;
  }
</style>