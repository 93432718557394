<script lang="ts">
  import Icon from '@iconify/svelte';
  import { IconButton, FloatingNavMenu } from './components/atoms';
  import Navbars from './frame/Navbars.svelte';
  import Views from './frame/Views.svelte';
  import Main from './frame/Main.svelte';
  import { draggingInProgress, interfaceOverlay, showTutorials } from './stores/uiStore';
  import { onMount } from 'svelte';
  import { scaffold } from './stores/scaffoldStore';
  import { InterfaceOverlay, CornerOverlay } from './components/organisms';
  import { auth } from './firebase/firebase';
  import { onAuthStateChanged } from 'firebase/auth';
  import { authUser } from './stores/userStore';
  import canvasMessageHandler from './helpers/canvasMessageHandler';
  import { About, ResumeOnboarding } from './components/templates';
  import Tutorials from './components/organisms/Tutorials.svelte';

  onMount(() => {
    // user authentication listener
    onAuthStateChanged(auth, (user) => {
      if (user) {
        authUser.set(user);
      } else {
        authUser.set(null);
        // show the appropriate onboarding modal based on url parameter
        const location = window.location.search;
        const urlParams = new URLSearchParams(location);
        switch( urlParams.get('mode') ) {
          case 'resume':
            showResumeOnboardingModal();
            break;
          default:
            //showAboutModal();
        }
      }
    });

    // canvas messages receiver
    window.addEventListener('message', canvasMessageHandler, false);

    // scaffold keyboard shortcut
    document.addEventListener('keydown', function(event) {
      // Check if Ctrl (or Command on macOS) + S was pressed
      if ((event.ctrlKey || event.metaKey) && event.key === 's') {
        event.preventDefault(); // Prevent the default browser save action
        var win = window.open("", "Title", "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=780,height=200,top="+(screen.height-400)+",left="+(screen.width-840));
        win.document.body.innerHTML = `<pre><code>${JSON.stringify($scaffold, undefined, 2)}</code></pre>`
      }
    });
  });

  const showAboutModal = () => {
    interfaceOverlay.set({
      component: About,
      props: {}
    });
  }

  const showResumeOnboardingModal = () => {
    interfaceOverlay.set({
      component: ResumeOnboarding,
      props: {}
    });
  }

  const showMenu = () => {
    if( $showTutorials ) {
      showTutorials.set(false);
    }
    interfaceOverlay.set({
      component: FloatingNavMenu,
      props: {},
      containerStyle: 'bottom: 60px; right: 40px;'
    })
  }

  // set body classes
  $: {
    if( $draggingInProgress ) {
      document.body.classList.add('dragging-in-progress');
    } else {
      document.body.classList.remove('dragging-in-progress');
    }
  }
</script>

<InterfaceOverlay/>
<CornerOverlay>
  {#if $showTutorials}
    <Tutorials/>
  {/if}
  <br/>
  <div class="actions">
    <span class="beta" on:click={showAboutModal}>BETA</span>
    <IconButton on:click={showMenu}>
      <Icon icon="ic:sharp-question-mark" width="18px"/>
    </IconButton>
  </div>
</CornerOverlay>
<Navbars/>
<Main/>
<Views/>

<style lang="scss">
  .beta {
    display: inline-block;
    position: relative;
    top: 4px;
    margin-right: 10px;
    align-self: center;
    background-color: white;
    font-size: 12px;
    padding: 5px;
    border-radius: 3px;
    font-weight: 600;
    color: #616142;
    border: solid 1px #f0f0f0;
    outline: solid 1px #f0f0f0;
    outline-offset: 2px;
    cursor: pointer;
  }

  .actions {
    text-align: right;
  }
</style>
