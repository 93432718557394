function getTailwindClassValue(classString, classPrefix) {
  // Regular expression to find the class with the given prefix
  const regex = new RegExp(`${classPrefix}([\\d]+)`, 'i');
  
  // Match the class in the provided string
  const match = classString.match(regex);

  if (match && match[1]) {
    // Return the numeric part of the class
    return parseInt(match[1], 10);
  } else {
    // Return null or some default value if the class is not found
    return null;
  }
}

// Example usage
export default getTailwindClassValue;
