<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import ListItem from "../atoms/ListItem.svelte";

  export let list: string[];

  const dispatch = createEventDispatcher();

  const itemClick = (itemIndex: number) => {
    dispatch('click', itemIndex);
  }
</script>

<div>
  {#each list as item, index}
    <ListItem on:click={() => itemClick(index)}>{item}</ListItem>
  {/each}
</div>

<style lang="scss">
  div {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    //background-color: $background-color-index-4;
    //border: solid 1px $background-color-index-4-offset;
    //border-radius: $border-radius-2;
  }
</style>