<script lang="ts">
  import Icon from "@iconify/svelte";
  import { SectionLabel } from "../atoms";
  import IconButton from "../atoms/IconButton.svelte";
  import ClassTool from "../organisms/ClassTool.svelte";
  import { selectedElementScaffold } from "../../stores/scaffoldStore";

  let icon = 'eva:clipboard-outline';

  const copyClassList = () => {
    if( $selectedElementScaffold.selectedScaffold.attributes.hasOwnProperty('class') ) {
      const classList = $selectedElementScaffold.selectedScaffold.attributes.class;
      if( classList ) {
        navigator.clipboard.writeText(classList);
      }
    }
  }
</script>

<SectionLabel label="Classes">
  <IconButton variant="outline" title="copy classes" on:click={copyClassList}>
    <Icon icon={icon} width="16px"/>
  </IconButton>
</SectionLabel>
<ClassTool/>

<style lang="scss">

</style>