export const ELEMENT = Object.freeze({
  body: 'body',
  base: 'base',
  container: 'container',
  layout: 'layout',
  layoutCell: 'layout-cell',
  heading: 'heading',
  span: 'span',
  list: 'list',
  listItem: 'list-item',
  paragraph: 'paragraph',
  button: 'button',
  textfield: 'textfield',
  textarea: 'textarea',
  select: 'select',
  checkbox: 'checkbox',
  radio: 'radio',
  image: 'image',
  embed: 'embed',
  icon: 'icon',
  divider: 'divider'
});

export const TOOL = Object.freeze({
  layout: 'layout',
  border: 'border',
  spacing: 'spacing',
  dimensions: 'dimensions',
  shadow: 'shadow',
  background: 'background',
  text: 'text',
  textInput: 'text-input',
  icon: 'icon',
  image: 'image',
  embed: 'embed',
  list: 'list',
  position: 'position',
  class: 'class'
});

export const ELEMENT_CATEGORY = {
  structure: [
    ELEMENT.layout,
    ELEMENT.container,
    ELEMENT.list,
    ELEMENT.listItem,
    ELEMENT.divider
  ],
  typography: [
    ELEMENT.heading,
    ELEMENT.paragraph
  ],
  form: [
    ELEMENT.button,
    ELEMENT.textfield,
    ELEMENT.textarea,
    ELEMENT.select,
    ELEMENT.checkbox,
    ELEMENT.radio
  ],
  media: [
    ELEMENT.image,
    ELEMENT.embed,
    ELEMENT.icon
  ]
}

export const TOOLS_ASSIGNED_ELEMENTS = {
  [ELEMENT.body]: [
    TOOL.background,
    TOOL.border,
    TOOL.spacing,
    TOOL.shadow
  ],
  [ELEMENT.container]: [
    TOOL.layout,
    TOOL.dimensions,
    TOOL.border,
    TOOL.spacing,
    TOOL.shadow,
    TOOL.background,
    TOOL.position
  ],
  [ELEMENT.layout]: [
    TOOL.layout,
    TOOL.border,
    TOOL.spacing,
    TOOL.background,
    TOOL.shadow,
    TOOL.position
  ],
  [ELEMENT.layoutCell]: [
    TOOL.layout,
    TOOL.dimensions,
    TOOL.border,
    TOOL.spacing,
    TOOL.background,
    TOOL.position
  ],
  [ELEMENT.heading]: [
    TOOL.text,
    TOOL.border,
    TOOL.spacing,
    TOOL.background,
    TOOL.shadow,
    TOOL.position
  ],
  [ELEMENT.list]: [
    TOOL.dimensions,
    TOOL.list,
    TOOL.spacing,
    TOOL.position
  ],
  [ELEMENT.listItem]: [
    TOOL.dimensions,
    TOOL.background,
    TOOL.border,
    TOOL.spacing,
    TOOL.list
  ],
  [ELEMENT.paragraph]: [
    TOOL.text,
    TOOL.border,
    TOOL.spacing,
    TOOL.background,
    TOOL.shadow,
    TOOL.position
  ],
  [ELEMENT.button]: [
    TOOL.position
  ],
  [ELEMENT.textfield]: [
    TOOL.textInput,
    TOOL.position,
    TOOL.border
  ],
  [ELEMENT.textarea]: [
    TOOL.textInput,
    TOOL.position,
    TOOL.border
  ],
  [ELEMENT.select]: [],
  [ELEMENT.checkbox]: [],
  [ELEMENT.radio]: [],
  [ELEMENT.image]: [
    TOOL.dimensions,
    TOOL.image,
    TOOL.border,
    TOOL.position,
    TOOL.shadow,
    TOOL.spacing,
  ],
  [ELEMENT.embed]: [
    TOOL.dimensions,
    TOOL.embed
  ],
  [ELEMENT.icon]: [
    TOOL.icon
  ],
  [ELEMENT.divider]: [
    TOOL.dimensions
  ]
}

export const SELF_CLOSING_TAGS = ['area', 'base', 'br', 'col', 'embed', 'hr', 'img', 'input', 'link', 'meta', 'param', 'source', 'track', 'wbr'];

export const BAN_NESTING = SELF_CLOSING_TAGS.concat(['span', 'button', 'textarea', 'p', 'iframe', 'i', 'a', 'svg', 'path', 'select', 'label', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6']);

export const ALLOW_TEXT = [
  ELEMENT.heading,
  ELEMENT.paragraph,
  ELEMENT.button
]

export const BANNED_TOOL_TAGS = {
  [ELEMENT.layout]: ['input', 'img', 'video', 'iframe', 'canvas', 'table', 'td', 'tr', 'tbody', 'svg', 'hr', 'area', 'br', 'col', 'embed', 'i']
}

export const NOT_MOVABLE = [
  ELEMENT.listItem,
];

export const NOT_REMOVABLE = [

];

export const EXPORT_CLEANUP_ATTRIBUTES = ['data-element', 'data-id', 'contenteditable'];

export const FONTAWESOME_CLASSNAMES = ['fa-solid', 'fa-brands', 'fa-regular', 'fa-sharp'];

export const INPUT_TYPES_NOT_TEXT = ['radio', 'checkbox', 'file', '']