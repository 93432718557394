import { ELEMENT } from './ScaffoldRegulator';

const CLASSES = {
  inputField: "bg-gray-100 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:border-blue-500"
}

export const SCAFFOLD_ELEMENTS = Object.freeze({
  [ELEMENT.layout]: {
    hidden: false,
    scaffold: {
      "tag": "div",
      "attributes": {
        "class": "grid grid-cols-3 gap-x-5 gap-y-5",
        "data-id": null,
        "data-element": ELEMENT.layout,
      },
      "nested": [
        {
          "tag": "div",
          "attributes": {
            "data-id": null,
            "data-element": ELEMENT.container
          },
          "nested": []
        },
        {
          "tag": "div",
          "attributes": {
            "data-id": null,
            "data-element": ELEMENT.container
          },
          "nested": []
        },
        {
          "tag": "div",
          "attributes": {
            "data-id": null,
            "data-element": ELEMENT.container
          },
          "nested": []
        }
      ]
    }
  },

  [ELEMENT.container]: {
    hidden: false,
    scaffold: {
      "tag": "div",
      "attributes": {
        "data-id": null,
        "data-element": ELEMENT.container,
      },
      "nested": []
    }
  },

  [ELEMENT.list]: {
    hidden: false,
    scaffold: {
      "tag": "ul",
      "attributes": {
        "class": "list-decimal list-inside",
        "data-id": null,
        "data-element": ELEMENT.list,
      },
      "nested": [
        {
          "tag": "li",
          "attributes": {
            "data-id": null,
            "data-element": ELEMENT.listItem
          },
          "nested": []
        },
        {
          "tag": "li",
          "attributes": {
            "data-id": null,
            "data-element": ELEMENT.listItem
          },
          "nested": []
        },
        {
          "tag": "li",
          "attributes": {
            "data-id": null,
            "data-element": ELEMENT.listItem
          },
          "nested": []
        }
      ]
    }
  },

  [ELEMENT.listItem]: {
    hidden: false,
    scaffold: {
      "tag": "li",
      "attributes": {
        "data-id": null,
        "data-element": ELEMENT.listItem
      },
      "nested": []
    }
  },

  [ELEMENT.divider]: {
    hidden: false,
    scaffold: {
      "tag": "hr",
      "attributes": {
        "data-id": null,
        "data-element": ELEMENT.divider,
      },
    }
  },

  [ELEMENT.heading]: {
    hidden: false,
    scaffold: {
      "tag": "h1",
      "attributes": {
        "class": "text-4xl",
        "data-id": null,
        "data-element": ELEMENT.heading,
        "contenteditable": "plaintext-only"
      },
      "nested": [
        {
          "text": "Hello, world"
        }
      ]
    }
  },

  [ELEMENT.paragraph]: {
    hidden: false,
    scaffold: {
      "tag": "p",
      "attributes": {
        "data-id": null,
        "data-element": ELEMENT.paragraph,
        "contenteditable": "plaintext-only"
      },
      "nested": [
        {
          "text": "Hello, world"
        }
      ]
    }
  },

  [ELEMENT.button]: {
    hidden: false,
    scaffold: {
      "tag": "button",
      "attributes": {
        "class": "bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded",
        "data-id": null,
        "data-element": ELEMENT.button,
        "type": "button",
        "contenteditable": "plaintext-only",
      },
      "nested": [
        {
          "text": "Hello, world"
        }
      ]
    }
  },

  [ELEMENT.textfield]: {
    hidden: false,
    scaffold: {
      "tag": "input",
      "attributes": {
        "class": CLASSES.inputField,
        "placeholder": "input field",
        "data-id": null,
        "data-element": ELEMENT.textfield,
        "type": "text",
      },
    }
  },

  [ELEMENT.textarea]: {
    hidden: false,
    scaffold: {
      "tag": "textarea",
      "attributes": {
        "class": CLASSES.inputField,
        "placeholder": "textarea field",
        "data-id": null,
        "data-element": ELEMENT.textarea,
      }
    }
  },

  [ELEMENT.select]: {
    hidden: false,
    scaffold: {
      "tag": "div",
      "attributes": {
        "class": "grid grid-cols-3 gap-x-5 gap-y-5",
        "data-id": null,
        "data-element": "layout"
      },
      "nested": [
        {
          "tag": "div",
          "attributes": {
            "data-id": null,
            "data-element": "container"
          },
          "nested": [
            {
              "tag": "div",
              "attributes": {
                "data-id": null,
                "data-element": "container",
                "class": "relative"
              },
              "nested": [
                {
                  "tag": "div",
                  "attributes": {
                    "data-id": null,
                    "data-element": "container",
                    "class": "relative"
                  },
                  "nested": [
                    {
                      "tag": "select",
                      "attributes": {
                        "data-id": null,
                        "data-element": "select",
                        "class": "block appearance-none w-full bg-gray-100 border-2 border-gray-200 rounded py-2 px-4 pr-8 leading-tight text-gray-700 focus:outline-none focus:border-blue-500 relative"
                      },
                      "nested": [
                        {
                          "tag": "option",
                          "attributes": {
                            "data-id": null,
                            "data-element": "option",
                          },
                          "nested": [
                            {
                              "text": "Option 1"
                            }
                          ]
                        },
                        {
                          "tag": "option",
                          "attributes": {
                            "data-id": null,
                            "data-element": "option",
                          },
                          "nested": [
                            {
                              "text": "Option 2"
                            }
                          ]
                        },
                        {
                          "tag": "option",
                          "attributes": {
                            "data-id": null,
                            "data-element": "option",
                          },
                          "nested": [
                            {
                              "text": "Option 3"
                            }
                          ]
                        }
                      ]
                    },
                    {
                      "tag": "div",
                      "attributes": {
                        "data-id": null,
                        "data-element": "container",
                        "class": "pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                      },
                      "nested": [
                        {
                          "tag": "i",
                          "attributes": {
                            "data-id": null,
                            "data-element": "icon",
                            "class": "fa-solid fa-chevron-down"
                          },
                          "nested": []
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    },
  },

  [ELEMENT.checkbox]: {
    hidden: false,
    scaffold: {
      "tag": "input",
      "attributes": {
        "type": "checkbox",
        "data-id": null,
        "data-element": ELEMENT.checkbox
      },
    },
  },

  [ELEMENT.radio]: {
    hidden: false,
    scaffold: {
      "tag": "input",
      "attributes": {
        "type": "radio",
        "data-id": null,
        "data-element": ELEMENT.radio,
      },
    }
  },

  [ELEMENT.image]: {
    hidden: false,
    scaffold: {
      "tag": "img",
      "attributes": {
        "src": "/images/elements/image-default.png",
        "class": "w-full",
        "data-id": null,
        "data-element": ELEMENT.image,
      }
    }
  },

  [ELEMENT.embed]: {
    hidden: false,
    scaffold: {
      "tag": "iframe",
      "attributes": {
        "src": "https://formulator.app",
        "data-id": null,
        "data-element": ELEMENT.embed,
      }
    }
  },

  [ELEMENT.icon]: {
    hidden: false,
    scaffold: {
      "tag": "i",
      "attributes": {
        "class": "fa-solid fa-smile",
        "data-id": null,
        "data-element": ELEMENT.icon,
      }
    }
  },
});