function updateTailwindClass(classString, classToUpdate, newValue) {
  // Regular expression to find the class to update
  const regex = new RegExp(`${classToUpdate}[\\d]+`, 'g');

  // Check if the class exists in the string
  if (classString.match(regex)) {
    // Replace the existing class with the new value
    return classString.replace(regex, `${classToUpdate}${newValue}`);
  } else {
    // If the class doesn't exist, append it
    return `${classString} ${classToUpdate}${newValue}`.trim();
  }
}

export default updateTailwindClass;
