<script lang="ts">
  import Icon from "@iconify/svelte";
  import { onMount } from "svelte";
  import { CF_generatorRequest } from "../../firebase/firebase";
  import htmlToScaffold from "../../helpers/htmlToScaffold";
  import saveProgress from "../../helpers/saveProgress";
  import ScaffoldCoordinator from "../../scaffold/ScaffoldCoordinator";
  import { selectedElement } from "../../stores/scaffoldStore";
  import { canvasDOM, interfaceOverlay, newComponent } from "../../stores/uiStore";
  import imageFileBase64Encoder from "../../helpers/imageFileEncoder";
  import { generating } from '../../stores/uiStore';
  import { authUser } from "../../stores/userStore";
  import { UserView } from "../views";
  import logGeneratorRequest from "../../firebase/logGeneratorRequest";

  let fileInputElement: HTMLInputElement;
  let promptInputElement: HTMLInputElement;
  let focused: boolean;

  $: elementSelected = !$selectedElement.id || $selectedElement.element === 'body';

  $: placeholder = elementSelected ? 'Create a colorful pricing table with icons and lots of white space...' : `You selected the '${$selectedElement.element}' element. Describe the changes you want to make...'`;

  const imageClickHandler = () => {
    fileInputElement.click();
  }

  let promptText = '';
  let promptTextWithCode = '';
  let promptImage = null;

  $: {
    if( !$newComponent ) {
      promptText = '';
      promptTextWithCode = '';
      promptImage = null;
    }
  }

  onMount(() => {
    promptInputElement.addEventListener('keydown', (event) => {
      if (event.key === 'Enter' || event.keyCode === 13) {
        event.preventDefault();
        makeRequest();
      }
    });
  });

  const makeRequest = async () => {
    if( !$authUser ) {
      interfaceOverlay.set({
        component: UserView,
        props: {}
      });
      return;
    }
    if( $generating !== false ) return; // prevent spamming if already generating
    let selectedElementId: string | null = $selectedElement.element !== 'body' ? $selectedElement.id : null;
    generating.set(!selectedElementId ? '-1' : selectedElementId);
    saveProgress(); // there's always a chance chatgpt provides code that breaks something. Save progress beforehand

    if( selectedElementId ) {
      const selectedElementHTML = $canvasDOM.querySelector(`[data-id="${selectedElementId}"]`).outerHTML;
      promptTextWithCode = `${promptText}

      ${selectedElementHTML}`
    }

    const prompt = !selectedElementId ? promptText : promptTextWithCode;
    logGeneratorRequest($authUser.uid, prompt); // log prompt

    try {
      const response = await CF_generatorRequest({
        request: prompt,
        imageBlob: promptImage
      });
      const responseHTML = response.data.choices[0].message.content.trim();
      const HTMLScaffold = htmlToScaffold(responseHTML);
      HTMLScaffold.forEach(scaffold => {
        ScaffoldCoordinator.handleGenerator(scaffold, selectedElementId);
      });
      generating.set(false);
    } catch(error) {
      generating.set(false);
      console.error(error);
      alert(error.message);
    }
  }

  const fileInputChangeHandler = async () => {
    if (fileInputElement.files) {
      const file = fileInputElement.files[0];
      try {
        promptImage = await imageFileBase64Encoder(file);
        console.log(promptImage);
      } catch (error) {
        console.error('Error converting file to base64', error);
      }
    }
  }
</script>

<div class="generator" class:focused={focused}>
  <div class="icon">
    {#if $generating === false}
      <Icon icon="eva:flash-outline" width="18" color="#d8a001"/>
    {:else}
      <Icon icon="svg-spinners:blocks-shuffle-3" width="15" color=""/>
    {/if}
  </div>
  <div class="prompt">
    <input
      type="text"
      placeholder={placeholder}
      bind:value={promptText}
      bind:this={promptInputElement}
      on:focus={() => focused = true}
      on:blur={() => focused = false}
    />
  </div>
  <div class="action">
    {#if !promptImage}
    <button type="button" on:click={imageClickHandler}>
      <Icon icon="eva:image-fill" width="18"/>
    </button>
    {:else}
    <button>
      <span class="image-preview" style:background-image={`url('${promptImage}')`} on:click={() => promptImage = null}>
        <Icon icon="eva:close-outline" width="16"/>
      </span>
    </button>
    {/if}
    <button type="button" style:cursor="auto">
      <Icon icon="icon-park-outline:click" width="18" color={elementSelected ? '#FFF' : '#00cea8'}/>
    </button>
    <input type="file" accept="image/*" bind:this={fileInputElement} on:change={fileInputChangeHandler}/>
  </div>
</div>

<style lang="scss">
  .generator {
    display: flex;
    align-items: center;
    width: 700px;
    max-width: 100%;
    box-sizing: border-box;
    padding: 0 4px;
    height: $navbars-buttons-size;
    border-radius: $navbars-buttons-size;
    color: var(--ui-theme);
    background-color: $background-index-1;
    border: solid 4px $background-index-3;

    > div {
      height: 100%;
    }

    &.focused {
      border-color: var(--ui-theme);
    }
  }

  .icon {
    flex-basis: 50px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .prompt {
    flex-basis: 100%;

    input {
      width: 100%;
      @include clear-default-styling;
      background-color: transparent;
      height: 100%;
      color: $lightest;
      line-height: 1;
    }
  }

  .action {
    padding-right: 5px;
    align-items: center;
    display: flex;
    line-height: 1;
    font-size: 0;
    position: relative;

    button {
      height: 100%;
      padding-left: 10px;
      padding-right: 10px;
      color: $lightest;

      &:hover {
        color: var(--ui-theme);
      }
    }

    input[type="file"] {
      width: 0;
      height: 0;
      position: absolute;
      visibility: hidden;
    }
  }

  .image-preview {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      outline: solid 1px $ui-theme-color-backtrack;
    }
  }

  button {
    @include clear-default-button-styling;
  }

  @media screen and (max-width: 1130px) {
    .generator {
      width: 500px;
    }
  }

  @media screen and (max-width: 930px) {
    .generator {
      width: 300px;
    }
  }
</style>