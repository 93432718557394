<script lang="ts">
  import Icon from "@iconify/svelte";
</script>

<button on:click>
  <slot/>
  <span>
    <Icon icon="eva:close-outline" width="16"></Icon>
  </span>
</button>

<style lang="scss">
  button {
    @include clear-default-button-styling;
    background-color: $background-color-index-4;
    border: solid 1px $background-color-index-4-offset;
    padding: 5px;
    border-radius: $border-radius-2;
    box-sizing: border-box;
    font-size: 0.85em;
    display: flex;
    gap: 5px;
    cursor: default;
    align-items: center;
    white-space: nowrap;
    max-width: 325px;
    text-overflow: ellipsis;
    overflow: hidden;

    span {
      color: $background-color-index-4-offset-2;
      line-height: 1;
      font-size: 0;
    }

    &:hover {
      span {
        color: $ui-theme-color-backtrack;
      }
    }
  }
</style>