<script lang="ts">
  import { activeNavItem, viewWidth, newComponent, canvasDOMMutationCounter } from '../stores/uiStore';
  import canvasScaling from '../helpers/canvasScaling';
  import { NAV_ITEM, viewWidths } from '../utils/_nav-items';
  import { DashboardView, CanvasView } from '../components/views';
  import { onMount } from 'svelte';

  let dimensions: string = '';
  let mainRef: HTMLElement;

  // handle dimensions/scaling
  $: {
    dimensions = '';
    if( $viewWidth ) {
      if( !$newComponent || $viewWidth > viewWidths.normal ) {
        dimensions = canvasScaling(mainRef.getBoundingClientRect().right, mainRef.offsetTop, $viewWidth);
      } else {
        dimensions = `width: calc(100vw - 80px - ${$viewWidth}px - 15px)`;
      }
    } else {
      if( $activeNavItem === NAV_ITEM.preview || !$newComponent ) {
        dimensions = `
          transform: scale(1);
          width: 100vw;
          height: 100vh;
          top: 0px;
          left: 0px;
          right: 0px;
          border-radius: 0;
        `;
      }
    }
  }

  // scroll to the top after switching view
  $: {
    if( $newComponent ) {
      mainRef.scrollTop = 0;
    }
  }

  // reapply markers after canvas transition
  onMount(() => {
    mainRef.addEventListener('transitionend', () => {
      //if( $activeNavItem === NAV_ITEM.elements || $activeNavItem === NAV_ITEM.components || $activeNavItem === NAV_ITEM.properties || $activeNavItem === NAV_ITEM.trash || $activeNavItem === NAV_ITEM.move ) {
        canvasDOMMutationCounter.set($canvasDOMMutationCounter + 1);
      //}
    })
  });
</script>

<main bind:this={mainRef} class:canvas-view={$newComponent} class="theme-dark" style={dimensions}>
  {#if $newComponent}
    <CanvasView/>
  {:else}
    <DashboardView/>
  {/if}
</main>

<style lang="scss">
  main {
    background-color: #212121;
    position: absolute;
    overflow: hidden scroll;
    color: $lightest;
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
    z-index: 10;
    margin-left: auto;
    transform: scale(1);
    transform-origin: top right;
    transition-property: transform, width, height, top, left, right;
    transition-duration: $frame-transitions-speed;
    width: calc(100vw - $navbars-thickness - $navbars-buttons-gap);
    height: calc(100% - $navbars-thickness);
    top: $navbars-thickness;
    left: $navbars-thickness;
    right: $navbars-buttons-gap;
    border-top-left-radius: $border-radius-1;
    border-top-right-radius: $border-radius-1;
    box-sizing: border-box;
    border: solid 5px #292929;
    border-right: none;
    border-bottom: none;
    @include custom-scrollbar(#292929, #4b4b4b);

    // TODO: expiremental theme application method.
    &.theme-dark {
      @include theme-dark;
    }

    &.canvas-view {
      background-image: radial-gradient(#3a3a3a 0.75px, rgba(20, 20, 20, 0) 0.75px);
      background-size: 15px 15px;
      background-position: center;
    }
  }
</style>