<script lang="ts">

</script>

<span>
  <slot/>
</span>

<style lang="scss">
  span {
    text-transform: uppercase;
    font-size: 0.80em;
    background-color: var(--theme-secondary);
    padding: 3px 10px;
    margin-right: 5px;
    border-radius: 50px;
  }
</style>