<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import Icon from "@iconify/svelte";
  import { interfaceOverlay } from "../../stores/uiStore";
  import { OptionsMenu } from "../atoms";

  export let type: 'text' | 'number' | 'email' | 'password' = 'text';
  export let value: string | number = '';
  export let unit: string = '';
  export let unitOptions: {label: string, value: string}[] = null;
  export let stepper: boolean = false;
  export let placeholder: string = '';
  export let leftIcon: string | undefined = undefined;
  //export let onUnitClick: Function = () => null;
  export let onStepperClick: Function = () => null;
  export let disabled: boolean = false;

  let inputFieldElement;

  const dispatch = createEventDispatcher();

  const inputHandler = (event: { currentTarget: { value: string | number } }) => {
    value = event.currentTarget.value;
    dispatch('input', value);
  }

  const stepperIncrementHandler = () => {
    value = parseFloat(value) + 1;
    dispatch('input', value);
  }

  const stepperDecrementHandler = () => {
    value = parseFloat(value) - 1;
    dispatch('input', value);
  }

  const unitClickHandler = () => {
    const rect = inputFieldElement.getBoundingClientRect();
    interfaceOverlay.set({
      component: OptionsMenu,
      props: {
        options: unitOptions,
        value: unit,
        onClick: (value) => {
          dispatch('unitClick', value);
          dispatch('input', value);
        }
      },
      containerStyle: `top:${rect.bottom}px; left:${rect.left}px; width:${rect.width}px; --ui-theme: #00cea8;`
    })
  }
</script>

<div class="input-field" class:using-left-icon={leftIcon} class:using-unit={unit} class:using-stepper={stepper} bind:this={inputFieldElement}>
  <div>
    <div class="field">
      {#if unit}
        <button class="unit" on:click={unitClickHandler}>
          {unit}
        </button>
      {/if}
      {#if stepper && type === 'number'}
        <div class="stepper">
          <button on:click={stepperIncrementHandler}><Icon icon="gridicons:dropdown" width="18px" vFlip={true}/></button>
          <button on:click={stepperDecrementHandler}><Icon icon="gridicons:dropdown" width="18px"/></button>
        </div>
      {/if}
      {#if leftIcon}
        <span class="left-icon"><Icon icon={leftIcon} width="18px"/></span>
      {/if}
      <input
        type={type}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        on:input={inputHandler}
        on:focusout
      />
    </div>
  </div>
</div>

<style lang="scss">
  .input-field {
    &.using-unit {
      input {
        padding-right: 34px;
      }
    }

    &.using-stepper {
      input {
        padding-right: 20px;
      }
    }

    &.using-left-icon {
      input {
        padding-left: 27px;
      }
    }
  }

  input {
    @include input-styling;

    &:focus {
      outline: solid 1px var(--ui-theme);
      outline-offset: -1px;
    }
  }

  .field {
    position: relative;
    display: flex;
    align-items: center;
  }

  .unit {
    @include clear-default-button-styling;
    position: absolute;
    right: 0px;
    height: 100%;
    width: 31px;
    text-transform: uppercase;
    text-align: right;
    color: $background-color-index-4-offset-2;
    padding-right: 7px;
    font-size: 0.85em;

    &:hover {
      color: var(--ui-theme);
    }
  }

  .stepper {
    position: absolute;
    top: 1px;
    right: 1px;
    width: 20px;
    height: calc(100% - 2px);
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    border-left: solid 1px $background-color-index-4-offset;
    
    > button {
      @include clear-default-button-styling;
      height: 50%;
      box-sizing: border-box;
      color: $background-color-index-4-offset-2;
      border-bottom-right-radius: calc($border-radius-2 - 2px);

      &:first-child {
        border-bottom: solid 1px $background-color-index-4-offset;
        border-top-right-radius: calc($border-radius-2 - 2px);
        border-bottom-right-radius: 0;
      }

      &:nth-child(2) {
        > svg {
          vertical-align: middle;
        }
      }

      &:hover {
        color: var(--ui-theme);
      }

      &:active {
        background-color: $background-color-index-4-offset;
      }
    }
  }

  .left-icon {
    position: absolute;
    left: 7px;
    font-size: 0;
    color: $background-color-index-4-offset-2;
  }
</style>