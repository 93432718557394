<script lang="ts">
  import Icon from "@iconify/svelte";

  export let accept: string;
  export let loading: boolean;
</script>

<label>
  <span class="icon">
    <Icon icon={!loading ? "uil:file-upload-alt" : "svg-spinners:blocks-shuffle-3"} width="40px" class="icon" color="#00bbff"/>
  </span>
  <h2>Upload Resume</h2>
  <p>click here to select your resume <strong>PDF</strong> file</p>
  <input type="file" accept={accept} on:change/>
</label>

<style lang="scss">
  label {
    border: dashed 2px var(--theme-primary-offset-2);
    background-color: var(--theme-primary-offset-1);
    padding: 30px 20px;
    box-sizing: border-box;
    display: block;
    border-radius: $border-radius-2;
    position: relative;
    text-align: center;
    cursor: pointer;

    &:hover {
      border-color: $ui-theme-color-general;
    }
  }

  .icon {
    width: 70px;
    height: 70px;
    display: flex;
    border-radius: 100%;
    background-color: var(--theme-primary);
    align-items: center;
    justify-content: center;
    border: solid 5px var(--theme-primary-offset-2);
    margin: 0 auto;
    margin-bottom: 10px;
  }

  h2 {
    text-transform: uppercase;
    font-weight: 800;
  }

  h2, p {
    margin: 0;
  }

  p {
    color: #666;
    margin-top: 5px;
  }

  input {
    visibility: hidden;
    position: absolute;
  }
</style>