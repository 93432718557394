<script lang="ts">
  import ScaffoldCoordinator from '../../scaffold/ScaffoldCoordinator';
  import { ELEMENT } from '../../scaffold/ScaffoldRegulator';
  import { selectedElement, selectedElementScaffold } from '../../stores/scaffoldStore';
  import { InputTypeOptions } from '../../utils/_tool-options';
  import { Label, ViewSection } from '../atoms';
  import { InputSelect, InputField } from '../molecules';

  let initialValue, type, placeholder, name = '';
  let disableType = false, disableInitialValue = false;

  $: {
    if( $selectedElementScaffold ) {
      const selectedScaffoldAttr = $selectedElementScaffold.selectedScaffold.attributes;
      initialValue = selectedScaffoldAttr.value ?? '';
      type = selectedScaffoldAttr.type ?? '';
      placeholder = selectedScaffoldAttr.placeholder ?? '';
      name = selectedScaffoldAttr.name ?? '';
      disableType = $selectedElement.element === ELEMENT.textarea;
      disableInitialValue = $selectedElement.element === ELEMENT.textarea;
    }
  }

  const inputHandler = (event, attribute) => {
    ScaffoldCoordinator.setAttribute(attribute, event.detail);
  }
</script>

<ViewSection>
  <div class="setup-fields">
    <div>
      <Label>Initial Value</Label>
      <InputField value={initialValue} on:input={(event) => inputHandler(event, 'value')} disabled={disableInitialValue}/>
    </div>
    <div>
      <Label>Type</Label>
      <InputSelect value={type} options={InputTypeOptions} on:change={(event) => inputHandler(event, 'type')} disabled={disableType}/>
    </div>
    <div>
      <Label>Placeholder</Label>
      <InputField value={placeholder} on:input={(event) => inputHandler(event, 'placeholder')}/>
    </div>
    <div>
      <Label>Name</Label>
      <InputField value={name} on:input={(event) => inputHandler(event, 'name')}/>
    </div>
  </div>
</ViewSection>

<style lang="scss">
  .setup-fields {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 15px;;
    row-gap: 10px;;
  }
</style>