import { signOut } from 'firebase/auth';

import { authUser } from '../stores/userStore';
import { auth } from './firebase';

const signOutUser = () => {
  signOut(auth).then(() => {
    authUser.set(null);
  }).catch((error) => {
    alert('failed to sign out');
    console.error(error);
  })
}

export default signOutUser;