import _ from 'lodash';
import { get } from 'svelte/store';

import {
  scaffold,
  scaffoldDataSource,
  selectedElement,
  selectedElementScaffold,
} from '../stores/scaffoldStore';
import {
  addScaffoldSliceToNest,
  addScaffoldSliceToNestAbove,
  findScaffoldSliceById,
  processScaffold,
  removeScaffoldSlice,
  replaceScaffoldSliceById,
} from './ScaffoldHelperFunctions';
import { twMerge } from 'tailwind-merge'
import { ELEMENT, NOT_REMOVABLE } from './ScaffoldRegulator';
import updateTailwindClass from '../helpers/updateTailwindClass';
import getTailwindClassValue from '../helpers/getTailwindClassValue';
import findObjectWithKeyValue from '../helpers/findByKeyValue';
import removeConflictingClasses from '../helpers/removeConflictingClasses';
import syncScaffoldWithDataSource from '../helpers/syncScaffoldWithDataSource';

export const setScaffold = (newScaffold) => {
  const _S_ = _.cloneDeep(newScaffold);
  scaffold.set(_S_);
  if( window.hasOwnProperty('scaffoldStack') && window.scaffoldStack !== null ) window.scaffoldStack.push(_S_);
}

const ScaffoldCoordinator = {
  // set the initial scaffold and data source states
  setScaffoldWithDataSource: (_S_, dataSource) => {
    if( dataSource ) {
      // modify scaffold so that it includes content from data source
      _S_ = syncScaffoldWithDataSource(_S_, dataSource);
      scaffoldDataSource.set(dataSource);
    }
    setScaffold(_S_);
  },


  // handle generator code
  handleGenerator: (HTMLScaffold, targetId) => {
    const _S_ = _.cloneDeep(get(scaffold));
    // if no target is provided, add the scaffold to the body. If target is provided, replace it by removing the original and adding the new
    if( !targetId ) {
      const addToNest = _S_.nested.concat(HTMLScaffold);
      _S_.nested = addToNest;
      setScaffold(_S_);
    } else if( targetId ) {
      const updatedScaffold = replaceScaffoldSliceById(_S_, targetId, HTMLScaffold);
      setScaffold(updatedScaffold);
      //console.log(updatedScaffold, HTMLScaffold)
      //ScaffoldCoordinator.removeElement(targetId, '');
    }
  },


  // nested
  nesting: (scaffoldSlice: object, nestId: string, droppedAbove: string | false) => {
    // process scaffold
    const processedScaffold = processScaffold(scaffoldSlice);
    const updatedScaffold = !droppedAbove ? addScaffoldSliceToNest(get(scaffold), nestId, processedScaffold) : addScaffoldSliceToNestAbove(get(scaffold), nestId, processedScaffold, droppedAbove);
    setScaffold(updatedScaffold);
  },


  // handle moving nested element
  moveElement: (elementName: string, elementId: string, nestId: string, droppedAbove: string | false) => {
    // TODO: check that dragged element is not being dropped in it's own child, which causes the element itself to disappear
    if( elementId === nestId ) return false;
    // if the drop target is not a nest, drop before
    const _S_ = get(scaffold);
    const { updatedScaffold, removedObject } = removeScaffoldSlice(_S_, elementId);
    let newScaffold;
    if( droppedAbove ) {
      newScaffold = addScaffoldSliceToNestAbove(updatedScaffold, nestId, removedObject, droppedAbove)
    } else {
      newScaffold = addScaffoldSliceToNest(updatedScaffold, nestId, removedObject);
    }
    setScaffold(newScaffold);
  },


  // text editing. Coming from the contenteditable text element when focused out
  textEditing: (elementId: string, text: string) => {
    // TODO: sanitize
    const _S_ = _.cloneDeep(get(scaffold));
    const elementScaffold = findScaffoldSliceById(_S_, elementId);
    elementScaffold.nested[0].text = text;
    setScaffold(_S_);
  },


  // style update
  styleUpdate: (property: string, value: string) => {
    const _S_ = get(selectedElementScaffold);
    if( _S_.selectedScaffold.attributes.hasOwnProperty('style') ) {
      _S_.selectedScaffold.attributes.style[property] = value;
    } else {
      _S_.selectedScaffold.attributes.style = {[property]: value};
    }
    setScaffold(_S_.scaffoldCopy);
  },


  // add tailwind class using twMerge to auto-remove conflicting tailwind classes
  addTailwindClass: (className) => {
    const _S_ = _.cloneDeep(get(selectedElementScaffold));
    if( _S_.selectedScaffold.attributes.hasOwnProperty('class') ) {
      const classList = _S_.selectedScaffold.attributes.class;
      _S_.selectedScaffold.attributes.class = twMerge(classList, className);
    } else {
      _S_.selectedScaffold.attributes.class = className;
    }
    setScaffold(_S_.scaffoldCopy);
  },


  // class update
  tailwindClassUpdate: (classPrefix: string, value: string) => {
    const _S_ = get(selectedElementScaffold);
    if( _S_.selectedScaffold.attributes.hasOwnProperty('class') ) {
      _S_.selectedScaffold.attributes.class = updateTailwindClass(_S_.selectedScaffold.attributes.class, classPrefix, value);
    } else {
      _S_.selectedScaffold.attributes.class = `${classPrefix}${value}`;
    }
    setScaffold(_S_.scaffoldCopy);
  },


  // get class value
  getTailwindClassValue: (classPrefix: string): string | number | null => {
    if( classPrefix ) {
      const _S_ = get(selectedElementScaffold);
      if( _S_.selectedScaffold.hasOwnProperty('attributes') && _S_.selectedScaffold.attributes.hasOwnProperty('class') ) {
        const classList = _S_.selectedScaffold.attributes.class;
        return getTailwindClassValue(classList, classPrefix);
      }
    }
  },


  // get tailwind classes TODO: This gets all classes, it should only get tailwind specific classes
  getAllTailwindClasses: () => {
    const _S_ = get(selectedElementScaffold);
    //const elementScaffold = findScaffoldSliceById(_S_, elementId);
    //console.log(elementScaffold)
    if( _S_ && _S_.hasOwnProperty('selectedScaffold') && _S_.selectedScaffold.hasOwnProperty('attributes') && _S_.selectedScaffold.attributes.hasOwnProperty('class') ) {
      return _S_.selectedScaffold.attributes.class;
    }
  },


  // add class
  addClass: (className, removeConflicting: RegExp | string[]) => {
    if( className ) {
      const _S_ = _.cloneDeep(get(selectedElementScaffold));
      if( _S_.selectedScaffold.attributes.hasOwnProperty('class') ) {
        let classes = _S_.selectedScaffold.attributes.class.split(' ');
        if( removeConflicting ) {
          classes = removeConflictingClasses(classes, removeConflicting);
        }
        classes.push(className);
        _S_.selectedScaffold.attributes.class = classes.join(' ').trim();
      } else {
        _S_.selectedScaffold.attributes.class = className;
      }
      setScaffold(_S_.scaffoldCopy);
    }
  },


  // remove class
  removeClass: (classToRemove: RegExp | string) => {
    if( classToRemove ) {
      const _S_ = _.cloneDeep(get(selectedElementScaffold));
      if( _S_.selectedScaffold.attributes.hasOwnProperty('class') ) {
        const classes = _S_.selectedScaffold.attributes.class.split(' ');
        // handle regex expression
        const removedClass = classToRemove instanceof RegExp ? 
          classes.filter(className => !classToRemove.test(className)) :
          classes.filter(className => className !== classToRemove);
        _S_.selectedScaffold.attributes.class = removedClass.join(' ');
        setScaffold(_S_.scaffoldCopy);
      }
    }
  },


  // toggle class
  toggleClass: (className: string, removeConflicting: string[] = null) => {
    className = className ? className.trim() : null;
    if( className ) {
      const _S_ = get(selectedElementScaffold);
      if( _S_.selectedScaffold.attributes.hasOwnProperty('class') ) {
        if( _S_.selectedScaffold.attributes.class.split(' ').includes(className) ) {
          ScaffoldCoordinator.removeClass(className);
        } else {
          ScaffoldCoordinator.addClass(className, removeConflicting);
        }
      } else {
        ScaffoldCoordinator.addClass(className, removeConflicting);
      }
    }
  },


  // set attribute value
  setAttribute: (attr, value) => {
    if( attr ) {
      const _S_ = _.cloneDeep(get(selectedElementScaffold));
      _S_.selectedScaffold.attributes[attr] = value;
      setScaffold(_S_.scaffoldCopy);
    }
  },



  // update tag TODO: Is this safe?
  setTag: (tag) => {
    if( tag ) {
      const _S_ = _.cloneDeep(get(selectedElementScaffold));
      _S_.selectedScaffold.tag = tag;
      setScaffold(_S_.scaffoldCopy);
    }
  },


  // remove
  removeElement: (id: string, element: string) => {
    // if 'removing' body, just remove all nested instead
    if( element === ELEMENT.body ) {
      const _S_ = _.cloneDeep(get(scaffold));
      _S_.nested = [];
      setScaffold(_S_);
      return;
    }
    if( NOT_REMOVABLE.includes(element) ) {
      alert(`${element} cannot be removed.`);
      return false;
    };
    try {
      const { updatedScaffold } = removeScaffoldSlice(get(scaffold), id);
      setScaffold(updatedScaffold);
    } catch(error) {
      alert(error);
    }
  },


  // undo/redo
  undoRedo: (direction: 'undo' | 'redo') => {
    let _S_;
    if( direction === 'undo' ) {
      _S_ = scaffoldStack.undo();
    } else if( direction === 'redo' ) {
      _S_ = scaffoldStack.redo();
    }
    if( !_S_ ) return;
    // if the new undo/redo scaffold does not contain the currently selected element, unselect that element
    if( !findObjectWithKeyValue(_S_, 'data-id', get(selectedElement).id) ) {
      selectedElement.set({id: null, element: null});
    }
    scaffold.set(_S_);
  },


  // update scaffold from data source
  setFromDataSource: (value: string, path: string) => {
    const _S_ = _.cloneDeep(get(scaffold));
    const scaffoldMatch = findObjectWithKeyValue(_S_, 'data-source-path', path);
    if( !scaffoldMatch ) return;
    const setPath = scaffoldMatch.attributes['data-source-set'];
    _.set(scaffoldMatch, setPath, value ? value : new String(''));
    scaffold.set(_S_);
  },
}

export default ScaffoldCoordinator;