<script lang="ts">
  import Icon from "@iconify/svelte";
  import { quintOut } from "svelte/easing";
  import { fly } from "svelte/transition";
  import { interfaceOverlay, showTutorials } from "../../stores/uiStore";
  import { About } from "../templates";

  const showAbout = () => {
    interfaceOverlay.set({
      component: About,
      props: {}
    });
  }

  const showTutorialsModal = () => {
    showTutorials.set(true);
    interfaceOverlay.set(null);
  }
</script>

<nav transition:fly={{ duration: 300, x: 0, y: 5, easing: quintOut }}>
  <!--<button on:click={showPricing}><Icon icon="eva:pricetags-outline" class="icon"/>Pricing</button>-->
  <button on:click={showTutorialsModal}><Icon icon="eva:info-outline" class="icon"/>Video Tutorials</button>
  <a href="https://docs.google.com/forms/d/e/1FAIpQLScF7M4kuEMKWZBq3n9sjAMwm0pghk0gJHIgXNIGVGIgCzYZug/viewform?usp=sf_link" target="_blank" rel="noreferrer noopener"><Icon icon="eva:message-circle-outline" class="icon"/>Send Feedback</a>
  <a href="https://mailchi.mp/c7c920982ee8/formulatorapp" target="_blank" rel="noreferrer noopener"><Icon icon="eva:email-outline" class="icon"/>Newsletter</a>
  <!--<a href="#" target="_blank" rel="noreferrer noopener"><Icon icon="eva:video-outline" class="icon"/>Tutorials</a>-->
  <button on:click={showAbout}><Icon icon="eva:info-outline" class="icon"/>About</button>
</nav>

<style lang="scss">
  nav {
    font-size: 0.85em;
    padding: 5px;
    background-color: $lightest;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: $border-radius-2;

    button {
      @include clear-default-button-styling;
      width: 100%;
    }

    a, button {
      text-decoration: none;
      color: inherit;
      display: block;
      padding: 10px;
      display: flex;
      gap: 10px;
      align-items: center;

      &:hover {
        background-color: #F7F7F9;
        border-radius: inherit;
      }
    }
  }
</style>