<script lang="ts">
  import { selectedElement, selectedElementPrioritized } from "../../stores/scaffoldStore";
  import { showMarker } from "../../stores/uiStore";

  export let scaffold;
  
  let elementName = scaffold.hasOwnProperty('attributes') && scaffold.attributes['data-element'];
  let elementId = scaffold.hasOwnProperty('attributes') && scaffold.attributes['data-id'];
  
  const elementClickHandler = () => {
    // toggle prioritization
    if( elementId == $selectedElement.id ) {
      selectedElementPrioritized.set(!$selectedElementPrioritized);
    } else {
      selectedElementPrioritized.set(true);
    }
    selectedElement.set({id: elementId, element: elementName});
    showMarker.set(null);
  }
</script>

{#if elementId}
  <div
    id={$selectedElement.id}
    class:selected={elementId === $selectedElement.id}
    on:click={elementClickHandler}
    on:mouseenter={() => showMarker.set(elementId)}
    on:mouseleave={() => showMarker.set(null)}
  >
    <span>{#if elementId === $selectedElement.id && $selectedElementPrioritized}<small>★</small>{/if}&nbsp;&nbsp;{elementName}</span>
  </div>
  {#if scaffold.hasOwnProperty('nested') && scaffold.nested.length}
    <ul>
      {#each scaffold.nested as element}
        <li><svelte:self scaffold={element}/></li>
      {/each}
    </ul>
  {/if}
{/if}

<style lang="scss">
  div {
    width: 500px;
    padding: 10px;
    border-top-left-radius: 3px;
    line-height: 1;

    &:hover, &.selected {
      background-color: #303030;

      & + ul {
        background: #30303063;
      }
    }

    &.selected {
      color: $ui-theme-color-components;
    }
  }

  ul {
    width: 500px;
    margin: 0;
    list-style: none;
    padding-inline-start: 5px;
    border-left: solid 1px #303030;

    li {
      padding: 5px 10px 0 10px;
      box-sizing: border-box;

      &:empty {
        display: none;
      }
    }
  }
</style>