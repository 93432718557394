import findAllByKey from './findAllByKey';
import _ from 'lodash';
import findObjectWithKeyValue from './findByKeyValue';
import { processScaffold } from '../scaffold/ScaffoldHelperFunctions';

const syncScaffoldWithDataSource = (scaffold: object, dataSource: object) => {
  const dataElements = findAllByKey(scaffold, 'data-source-path');

  dataElements.forEach(element => {
    const dataPath = element.attributes['data-source-path'];
    const dataSet = element.attributes['data-source-set'];
    const dataTemplate = element.attributes['data-source-template'];
    let dataValue = _.get(dataSource, dataPath);

    // process array of items. Scaffold of item is referenced from template
    if( dataTemplate ) {
      const template = _.cloneDeep(findObjectWithKeyValue(scaffold, 'data-template', dataTemplate));
      const templateScaffold = template.nested[0];

      if( Array.isArray(dataValue) ) {
        // generate scaffold from list of items, using template
        const processedTemplateScaffoldItems = dataValue.map((data, index) => {
          const templateScaffoldClone = processScaffold(templateScaffold);
          const nestedTemplateData = findAllByKey(templateScaffoldClone, 'data-source-path');
          
          // handle nested elements within the template
          nestedTemplateData.forEach(nestedElement => {
            const path = nestedElement.attributes['data-source-path'];
            nestedElement.attributes['data-source-path'] = path.replace(`[i]`, `[${index}]`); // set index to current item to allow editing
            const nestedElementDataValue = _.get(dataSource, nestedElement.attributes['data-source-path']);
            if( nestedElementDataValue ) {
              _.set(nestedElement, nestedElement.attributes['data-source-set'], nestedElementDataValue);
            }
          });

          return templateScaffoldClone;
        });
        
        dataValue = processedTemplateScaffoldItems;
      }
    }

    //console.log(element, dataSet, dataValue)
    if( dataValue ) {
      _.set(element, dataSet, dataValue);
    }
  });
  
  return scaffold;
}

export default syncScaffoldWithDataSource;