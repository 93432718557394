<script lang="ts">
  import { createEventDispatcher } from "svelte";

  export let placeholder: string = '';
  export let value: string | number = '';

  const dispatch = createEventDispatcher();

  const inputHandler = (event: { currentTarget: { value: string | number } }) => {
    value = event.currentTarget.value;
    dispatch('input', value);
  }
</script>

<div class="input-area">
  <textarea placeholder={placeholder} rows="5" on:input={inputHandler} on:focusout>{value}</textarea>
</div>

<style lang="scss">
  textarea {
    @include input-styling;
    height: auto;

    &:focus {
      outline: solid 1px var(--ui-theme);
      outline-offset: -1px;
    }
  }
</style>