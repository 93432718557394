<script lang="ts">
  import { Label, ViewSection, NotReady } from '../atoms';
  import { InputToggle } from '../molecules';
  import { ExportHTML } from '../organisms';

  const exportOptions = [
    {label: 'HTML', value: 'html'},
    {label: 'React', value: 'react'},
    {label: 'Svelte', value: 'svelte'},
    {label: 'Vue', value: 'vue'},
    {label: 'Figma', value: 'figma'},
    {label: 'Framer', value: 'framer'},
    {label: 'Webflow', value: 'webflow'},
  ] as const;

  type ExportAs = typeof exportOptions[number]['value'];
  let exportAs: ExportAs = 'html';
</script>

<ViewSection>
  <div class="layout">
    <!--<div class="export-options">
      <Label>Export As</Label>
      <InputToggle initialValue={exportAs} options={exportOptions} on:change={(event) => exportAs = event.detail}/>
    </div>-->
    <div>
      {#if exportAs === 'html'}
        <ExportHTML/>
      {:else}
        <NotReady/>
      {/if}
    </div>
  </div>
</ViewSection>

<style lang="scss">
  .export-options {
    margin-bottom: 15px;
  }
</style>