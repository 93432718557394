<script lang="ts">
  import { quintInOut } from "svelte/easing";
  import { fade } from "svelte/transition";
  import { interfaceOverlay } from "../../stores/uiStore";

  export let onClick: Function = null;

  const clickHandler = (event) => {
    if( event.target.classList.contains('blur') ) {
      if( onClick ) onClick();
      interfaceOverlay.set(null);
    }
  }
</script>

<div class="blur" transition:fade={{duration: 500, easing: quintInOut}} on:click={clickHandler}>
  <div>
    <slot/>
  </div>
</div>

<style lang="scss">
  .blur {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: crosshair;

    > * {
      cursor: default;
    }
  }
</style>