const videoTutorials = [
  {
    src: 'https://www.youtube.com/embed/9wrEBOK8qnE?si=XICX_91FkowKhB86',
    title: 'Start a new project',
    length: '2:57',
  },
  {
    src: 'https://www.youtube.com/watch?v=9wrEBOK8qnE',
    title: 'Generate components',
    length: '2:30',
  },
  {
    src: 'https://www.youtube.com/watch?v=9wrEBOK8qnE',
    title: 'Using resume as data source',
    length: '2:30',
  },
  {
    src: 'https://www.youtube.com/watch?v=9wrEBOK8qnE',
    title: 'Building from library',
    length: '2:30',
  },
  {
    src: 'https://www.youtube.com/watch?v=9wrEBOK8qnE',
    title: 'Building from scratch',
    length: '2:30',
  },
  {
    src: 'https://www.youtube.com/watch?v=9wrEBOK8qnE',
    title: 'Publish your site',
    length: '2:30',
  }
]

export default videoTutorials;