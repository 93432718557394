<script lang="ts">
  import { LibItem, ViewSection, SectionLabel } from '../atoms';
  import { ELEMENT_CATEGORY } from '../../scaffold/ScaffoldRegulator';

  const libItemImagePath = '/images/elements/';
</script>

<SectionLabel label="Structure"/>
<ViewSection>
  <div class="layout">
    {#each ELEMENT_CATEGORY.structure as element}
      <LibItem label={element} item="element" image={`${libItemImagePath}${element}.svg`}/>
    {/each}
  </div>
</ViewSection>

<SectionLabel label="Typography"/>
<ViewSection>
  <div class="layout">
    {#each ELEMENT_CATEGORY.typography as element}
      <LibItem label={element} item="element" image={`${libItemImagePath}${element}.svg`}/>
    {/each}
  </div>
</ViewSection>

<SectionLabel label="Form"/>
<ViewSection>
  <div class="layout">
    {#each ELEMENT_CATEGORY.form as element}
      <LibItem label={element} item="element" image={`${libItemImagePath}${element}.svg`}/>
    {/each}
  </div>
</ViewSection>

<SectionLabel label="Media"/>
<ViewSection>
  <div class="layout">
    {#each ELEMENT_CATEGORY.media as element}
      <LibItem label={element} item="element" image={`${libItemImagePath}${element}.svg`}/>
    {/each}
  </div>
</ViewSection>

<style lang="scss">
  .layout {
    @include grid-columns-2;
    margin-bottom: 25px;
  }
</style>