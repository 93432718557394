<script lang="ts">
  import { CodeFormater } from "../molecules";
  import { canvasDOM, newComponent } from "../../stores/uiStore";
  import exportHTMLTemplate from "../../utils/_export-html-template";

  const html = exportHTMLTemplate($newComponent.title, $newComponent.description, $canvasDOM.body.outerHTML);
</script>

<div class="export">
  <!--<InstructionDialog icon={{icon: "bi:exclamation", size: 35}} dialog="Hey! Thanks for using Formulator. Code exporting is in a development phase, which means your exported code might not be optimal for production. Provide feedback to help define export options as the feature gets built." />-->
  <CodeFormater html={html}/>
  <!--<div class="export-button">
    <UserButton icon="eva:copy-fill" on:click={() => exportCode('vanilla')}>Copy to Clipboard</UserButton>
  </div>-->
  <!--<div>
    <span class="label">HTML</span>
    <CodeFormater html={html}/>
  </div>
  <div>
    <span class="label">CSS</span>
    <CodeFormater css={css}/>
  </div>-->
</div>

<style lang="scss">
  .export {
    //display: grid;
    //grid-template-columns: repeat(2, 1fr);
    //grid-gap: 15px;
  }

  .export-button {
    margin-top: 20px;
    display: flex;
    justify-content: center
  }

  .label {
    @include input-label;
  }
</style>