<script lang="ts">
  export let labelFor = null;
</script>

<svelte:element class="label" for={labelFor} this={!labelFor ? 'span' : 'label'}>
  <slot/>
</svelte:element>

<style lang="scss">
  .label {
    @include input-label;
  }
</style>