<script lang="ts">
  import { selectedElement } from '../../stores/scaffoldStore';
  import { activeNavItem } from '../../stores/uiStore';
  import { NAV_ITEM } from '../../utils/_nav-items';
  import { RemoveMarkers, PersistentMarkers } from '../molecules';
</script>

<div class="canvas-wannabe" >
  <div class="canvas-markers" class:element-selected={$selectedElement.id}>
    {#if $activeNavItem === NAV_ITEM.trash}
      <RemoveMarkers/>
    {:else}
      <PersistentMarkers/>
    {/if}
  </div>
</div>

<style lang="scss">
  .canvas-wannabe {
    position: absolute;
    top: 0px;
    left: 0px;
    width: inherit;
    height: inherit;
    z-index: 1;
    //overflow: scroll;
    -webkit-user-select: none;
    user-select: none;
  }

  .canvas-markers {
    width: 100%;
    position: relative;

    // selection marker styles when selected
    &.element-selected {
      :global(.selection-marker.selected) {
        &:before { content: none }
        background: none;
      }

      :global(.selection-marker:not(.selected)) {
        opacity: 0;
      }

      :global(.selection-marker) {
        &:hover {
          opacity: 1;
        }
      }
    }
  }
</style>