<script lang="ts">
  import _ from 'lodash';
  import Label from "../atoms/Label.svelte";
  import SectionLabel from "../atoms/SectionLabel.svelte";
  import ViewSection from "../atoms/ViewSection.svelte";
  import InputArea from "../molecules/InputArea.svelte";
  import InputField from "../molecules/InputField.svelte";
  import IconButton from '../atoms/IconButton.svelte';
  import ImagePreview from '../atoms/ImagePreview.svelte';
  import Icon from "@iconify/svelte";
  import { scaffold, scaffoldDataSource } from "../../stores/scaffoldStore";
  import ScaffoldCoordinator from '../../scaffold/ScaffoldCoordinator';
  import uploadUserImage from '../../firebase/uploadUserImage';

  type sections = 'skills' | 'work' | 'projects' | 'profiles';

  $: resumeValues = {
    name: $scaffoldDataSource?.resume?.basics?.name ?? '',
    label: $scaffoldDataSource?.resume?.basics?.label ?? '',
    image: $scaffoldDataSource?.resume?.basics?.image ?? '',
    email: $scaffoldDataSource?.resume?.basics?.email ?? '',
    location: $scaffoldDataSource?.resume?.basics?.location?.city ?? '',
    summary: $scaffoldDataSource?.resume?.basics?.summary ?? '',
    profiles: $scaffoldDataSource?.resume?.basics?.profiles ?? [],
    skills: $scaffoldDataSource?.resume?.skills ?? [],
    work: $scaffoldDataSource?.resume?.work ?? [],
    projects: $scaffoldDataSource?.resume?.projects ?? [],
  }


  let removeMode: sections | null = null;

  const updateResumeDataHandler = (value, path) => {
    scaffoldDataSource.update(resumeData => {
      const updatedResumeData = _.set(resumeData, path, value);
      return updatedResumeData;
    });
    ScaffoldCoordinator.setFromDataSource(value, path);
  }

  const updateResumeDataImageHandler = async (event, path) => {
    const file = event.target.files[0];
    if( file ) {
      const imageUrl = await uploadUserImage(file);
      updateResumeDataHandler(imageUrl.downloadURL, path);
    }
  }

  const addHandler = (data: sections) => {
    const dataSourceClone = _.cloneDeep($scaffoldDataSource);
    let schema, target;

    switch(data) {
      case 'skills':
        if( !dataSourceClone.resume.skills ) dataSourceClone.resume.skills = [];
        target = dataSourceClone.resume.skills;
        schema = '[skill]';
        break;
      case 'work':
        if( !dataSourceClone.resume.work ) dataSourceClone.resume.work = [];
        schema = {
          "company": "[company name]",
          "position": "[position]",
          "startDate": "[start date]",
          "endDate": "[end date]",
          "summary": "[summary]"
        }
        target = dataSourceClone.resume.work;
        break;
      case 'projects':
        if( !dataSourceClone.resume.projects ) dataSourceClone.resume.projects = [];
        schema = {
          "name": "[project name]",
          "url": "",
          "image": "https://fakeimg.pl/600x400?text=Project&font=bebas"
        }
        target = dataSourceClone.resume.projects;
        break;
      case 'profiles':
        if( !dataSourceClone.resume.basics.profiles ) dataSourceClone.resume.basics.profiles = [];
        schema = {
          "network": "[network name]",
          "url": ""
        }
        target = dataSourceClone.resume.basics.profiles
        break;
    }

    try {
      target.push(schema);
      ScaffoldCoordinator.setScaffoldWithDataSource($scaffold, dataSourceClone);
      //scaffoldDataSource.set(dataSourceClone);
    } catch(error) {
      console.error(error);
    }

    if( removeMode ) {
      removeMode = null
    }
  }

  const toggleRemoveMode = (removeSection) => {
    if( removeSection !== removeMode ) {
      removeMode = removeSection;
    } else {
      removeMode = null;
    }
  }

  const removeHandler = (path, index: number, mode) => {
    if( removeMode && removeMode === mode ) {
      const dataSourceClone = _.cloneDeep($scaffoldDataSource);
      const removeFrom = _.get(dataSourceClone, path);
      removeFrom.splice(index, 1);
      ScaffoldCoordinator.setScaffoldWithDataSource($scaffold, dataSourceClone);
    }
  }
</script>

<SectionLabel label="Basics" sticky/>
<ViewSection>
  <div class="layout">
    <div class="user-layout layout cols-2">
      <div>
        <Label>Photo</Label>
        <ImagePreview src={resumeValues.image} on:change={(event) => updateResumeDataImageHandler(event, 'resume.basics.image')}/>
      </div>
      <div class="inputs-layout">
        <div>
          <Label>Name</Label>
          <InputField
            placeholder="John Doe"
            value={resumeValues.name}
            leftIcon="eva:person-outline"
            on:input={(event) => updateResumeDataHandler(event.detail, 'resume.basics.name')}
          />
        </div>
        <div>
          <Label>Title</Label>
          <InputField
            placeholder="Software Engineer"
            value={resumeValues.label}
            leftIcon="ic:outline-badge"
            on:input={(event) => updateResumeDataHandler(event.detail, 'resume.basics.label')}
          />
        </div>
      </div>
    </div>
    <div>
      <Label>Email Address</Label>
      <InputField
        placeholder="johndoe@example.com"
        value={resumeValues.email}
        leftIcon="eva:email-outline"
        on:input={(event) => updateResumeDataHandler(event.detail, 'resume.basics.email')}
      />
    </div>
    <div>
      <Label>Location</Label>
      <InputField
        placeholder="New York"
        value={resumeValues.location}
        leftIcon="majesticons:map-marker-area-line"
        on:input={(event) => updateResumeDataHandler(event.detail, 'resume.basics.location.city')}
      />
    </div>
    <div>
      <Label>Summary</Label>
      <InputArea
        placeholder="Short summary"
        value={resumeValues.summary}
        on:input={(event) => updateResumeDataHandler(event.detail, 'resume.basics.summary')}
      />
    </div>
  </div>
</ViewSection>

<SectionLabel label="Skills" sticky>
  <IconButton variant="outline" on:click={() => toggleRemoveMode('skills')}>
    <Icon icon="eva:minus-outline" width="16px" color={removeMode === 'skills' ? '#fa5b5a' : null}/>
  </IconButton>
  <IconButton variant="outline" on:click={() => addHandler('skills')}>
    <Icon icon="eva:plus-outline" width="16px"/>
  </IconButton>
</SectionLabel>
<ViewSection>
  <div class="layout cols-2">
    {#each resumeValues.skills as skill, index}
      <div class:remove-mode={removeMode === 'skills'} on:click={() => removeHandler(`resume.skills`, index, 'skills')}>
        <Label>Skill {index + 1}</Label>
        <InputField
          placeholder="skill name"
          value={skill}
          on:input={(event) => updateResumeDataHandler(event.detail, `resume.skills[${index}]`)}
        />
      </div>
    {/each}
  </div>
</ViewSection>

<SectionLabel label="Experience" sticky>
  <IconButton variant="outline" on:click={() => toggleRemoveMode('work')}>
    <Icon icon="eva:minus-outline" width="16px" color={removeMode === 'work' ? '#fa5b5a' : null}/>
  </IconButton>
  <IconButton variant="outline" on:click={() => addHandler('work')}>
    <Icon icon="eva:plus-outline" width="16px"/>
  </IconButton>
</SectionLabel>
<ViewSection>
  {#each resumeValues.work as work, index}
    <div class="work-layout layout cols-2" class:remove-mode={removeMode === 'work'} on:click={() => removeHandler(`resume.work`, index, 'work')}>
      <div>
        <Label>Company</Label>
        <InputField
          placeholder="BuyNLarge"
          value={work.company}
          on:input={(event) => updateResumeDataHandler(event.detail, `resume.work[${index}].company`)}
        />
      </div>
      <div>
        <Label>Position</Label>
        <InputField
          placeholder="Software Engineer"
          value={work.position}
          on:input={(event) => updateResumeDataHandler(event.detail, `resume.work[${index}].position`)}
        />
      </div>
      <div>
        <Label>Start Date</Label>
        <InputField
          placeholder=""
          value={work.startDate}
          leftIcon="eva:calendar-outline"
          on:input={(event) => updateResumeDataHandler(event.detail, `resume.work[${index}].startDate`)}
        />
      </div>
      <div>
        <Label>End Date</Label>
        <InputField
          placeholder=""
          value={work.endDate}
          leftIcon="eva:calendar-outline"
          on:input={(event) => updateResumeDataHandler(event.detail, `resume.work[${index}].endDate`)}
        />
      </div>
      <div class="col-summary">
        <Label>Summary</Label>
        <InputArea
          placeholder=""
          value={resumeValues.work[index].summary}
          on:input={(event) => updateResumeDataHandler(event.detail, `resume.work[${index}].summary`)}
        />
      </div>
    </div>
  {/each}
</ViewSection>

<SectionLabel label="Profiles" sticky>
  <IconButton variant="outline" on:click={() => toggleRemoveMode('profiles')}>
    <Icon icon="eva:minus-outline" width="16px" color={removeMode === 'profiles' ? '#fa5b5a' : null}/>
  </IconButton>
  <IconButton variant="outline" on:click={() => addHandler('profiles')}>
    <Icon icon="eva:plus-outline" width="16px"/>
  </IconButton>
</SectionLabel>
<ViewSection>
  {#each resumeValues.profiles as profile, index}
    <div class="profiles-layout layout cols-2" class:remove-mode={removeMode === 'profiles'} on:click={() => removeHandler(`resume.basics.profiles`, index, 'profiles')}>
      <div>
        <Label>Profile Network</Label>
        <InputField
          placeholder="LinkedIn"
          value={profile.network}
          on:input={(event) => updateResumeDataHandler(event.detail, `resume.basics.profiles[${index}].network`)}
        />
      </div>
      <div>
        <Label>Profile Link</Label>
        <InputField
          placeholder="https://linkedin.com/johndoe"
          value={profile.url}
          leftIcon="eva:link-2-outline"
          on:input={(event) => updateResumeDataHandler(event.detail, `resume.basics.profiles[${index}].url`)}
        />
      </div>
    </div>
  {/each}
</ViewSection>

<SectionLabel label="Projects" sticky>
  <IconButton variant="outline" on:click={() => toggleRemoveMode('projects')}>
    <Icon icon="eva:minus-outline" width="16px" color={removeMode === 'projects' ? '#fa5b5a' : null}/>
  </IconButton>
  <IconButton variant="outline" on:click={() => addHandler('projects')}>
    <Icon icon="eva:plus-outline" width="16px"/>
  </IconButton>
</SectionLabel>
<ViewSection>
  {#each resumeValues.projects as project, index}
    <div class="projects-layout layout cols-2" class:remove-mode={removeMode === 'projects'} on:click={() => removeHandler(`resume.projects`, index, 'projects')}>
      <div>
        <Label>Preview Image</Label>
        <ImagePreview src={resumeValues.projects[index].image} on:change={(event) => updateResumeDataImageHandler(event, `resume.projects[${index}].image`)}/>
      </div>
      <div class="inputs-layout">
        <div>
          <Label>Project Name</Label>
          <InputField
            placeholder=""
            value={project.name}
            on:input={(event) => updateResumeDataHandler(event.detail, `resume.projects[${index}].name`)}
          />
        </div>
        <div>
          <Label>Project Link</Label>
          <InputField
            placeholder="https://formulator.app"
            value={project.url}
            leftIcon="eva:link-2-outline"
            on:input={(event) => updateResumeDataHandler(event.detail, `resume.projects[${index}].url`)}
          />
        </div>
      </div>
    </div>
  {/each}
</ViewSection>

<style lang="scss">
  .layout {
    display: grid;
    row-gap: 15px;

    &.cols-2 {
      grid-template-columns: 1fr 1fr;
      column-gap: 15px;
    }

    &.projects-layout, .user-layout {
      margin-top: 10px;
      display: grid;
      grid-template-columns: 101px 1fr;
      column-gap: 15px;
    }
  }

  .inputs-layout {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 6px;
    row-gap: 10px;
  }

  .work-layout, .projects-layout, .profiles-layout {
    &:not(:last-child) {
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: solid 1px $background-color-index-4;
    }

    .col-summary {
      grid-column-start: 1;
      grid-column-end: 3;
    }
  }

  // remove mode
  .remove-mode {
    &:hover {
      outline: solid 1px $ui-theme-color-backtrack;
      outline-offset: 5px;
      cursor: initial;
      border-radius: 1px;
    }
  }

  :global(.remove-mode) {
    * {
      pointer-events: none;
    }
  }
</style>