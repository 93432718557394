import {
  getDownloadURL,
  ref,
} from '@firebase/storage';

import {
  storage
} from './firebase'

const getUserComponentScaffold = async (downloadURL) => {
  const httpsReference = ref(storage, downloadURL);
  
  // Get the download URL and fetch the content
  try {
    const url = await getDownloadURL(httpsReference);
    const response = await fetch(url);
    const data = await response.text(); // or .json(), .blob(), etc. based on the file type

    // Return or use the content
    return data;

  } catch (error) {
    console.error('Error fetching file:', error);
    throw error;
  }
}

export default getUserComponentScaffold;
