<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import InputField from "./InputField.svelte";

  export let value: string;
  export let disabled: boolean = false;

  const dispatch = createEventDispatcher();

  const onInputHandler = () => {
    dispatch('input', value);
  }
</script>

<div>
  <div class="inputs">
    <div class="color-input">
      <InputField bind:value type="text" on:input={onInputHandler} disabled={disabled}/>
    </div>
    <div class="color-picker" class:disabled={disabled}>
      <input bind:value type="color" on:input={onInputHandler} disabled={disabled}/>
    </div>
  </div>
</div>

<style lang="scss">
  .inputs {
    display: flex;
    padding: 0;
    font-size: inherit;

    & :global(.input-field input) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .color-input {
    border-right: solid 2px $background-index-1;
    flex-grow: 1;
  }

  .color-picker {
    background-color: $background-color-index-4;
    border-radius: 0 $border-radius-2 $border-radius-2 0;
    border: solid 1px $background-color-index-4-offset;

    &.disabled {
      @include input-disabled;
    }
  }

  input[type="color"] {
    @include clear-default-button-styling;
    height: 100%;
    width: 36px;

    &::-webkit-color-swatch {
      border-radius: calc($border-radius-2 - 2px);
    }

    &::-moz-color-swatch {
      border-radius: calc($border-radius-2 - 2px);
    }
  }
</style>