<script lang="ts">
  import _ from 'lodash';
  import updateFieldInFirestore from "../../firebase/updateUserComponentData";
  import { newComponent } from "../../stores/uiStore";
  import { authUser } from "../../stores/userStore";
  import { ViewSection, Label, SectionLabel } from "../atoms";
  import { InputArea } from "../molecules";
  import InputField from "../molecules/InputField.svelte";

  let titleValue = $newComponent.title;
  let descriptionValue = $newComponent.description;

  const updateMetadata = async (field: 'title' | 'description') => {
    if( !$authUser ) return;
    let newValue = field === 'title' ? titleValue : descriptionValue;
    let newComponentState = _.cloneDeep($newComponent);
    try {
      await updateFieldInFirestore({
        userId: $authUser.uid,
        componentId: $newComponent.id,
        fieldName: field,
        newValue: newValue
      });
      // update local state with new value
      newComponentState[field] = newValue;
      newComponent.set(newComponentState);
    } catch(error) {
      alert(`An error occured while updating the ${field}`);
      console.error(error);
    }
  }
</script>

<SectionLabel label="Meta Data"/>
<ViewSection>
  <div class="layout">
    <div>
      <Label>Page Title</Label>
      <InputField placeholder="Appears in the browser tab" bind:value={titleValue} on:focusout={() => updateMetadata('title')}/>
    </div>
    <div>
      <Label>Page Description</Label>
      <InputArea bind:value={descriptionValue} placeholder="Recommended length between 120-155 characters" on:focusout={() => updateMetadata('description')}/>
    </div>
  </div>
</ViewSection>

<style lang="scss">
  .layout {
    display: grid;
    row-gap: 15px;
  }
</style>