<script lang="ts">
  import Icon from "@iconify/svelte";
  import { interfaceOverlay } from "../../stores/uiStore";
  import VideoModal from "../templates/VideoModal.svelte";

  export let videos;

  const showVideoModal = (src: string) => {
    interfaceOverlay.set({
      component: VideoModal,
      props: {
        src: src
      }
    });
  }
</script>

<div class="videos">
  {#each videos as video}
    <div class="video" on:click={() => showVideoModal(video.src)}>
      <div class="icon">
        <Icon icon="eva:play-circle-fill" width="20"/>
      </div>
      <div>
        <h4>{video.title}</h4>
        <p>{video.length}</p>
      </div>
    </div>
  {/each}
</div>

<style lang="scss">
  .videos {
    background-color: inherit;
    overflow-x: hidden;
    overflow-y: scroll;
    border-radius: inherit;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      width: 2px;
      height: 100%;
      left: 19px;
      background-color: #666;
    }
  }

  .video {
    display: flex;
    padding: 10px;
    gap: 10px;
    align-items: center;
    cursor: pointer;
    background-color: inherit;

    &:hover {
      background-color: #212121;

      .icon {
        color: $ui-theme-color-output;
      }
    }

    h4 {
      color: $lightest;
      font-size: 0.90em;
    }

    h4, p {
      margin: 0;
    }

    p {
      font-size: 0.80em;
    }
  }

  .icon {
    z-index: 1;
    position: relative;
    background-color: inherit;
    line-height: 1;
    font-size: 0;
  }
</style>