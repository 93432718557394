<script lang="ts">
  import Icon from "@iconify/svelte";

  export let src: string = '';
</script>

<label class:checkerbg={!src} style:background-image={!src ? '' : `url(${src})`}>
  <span class="image-icon">
    <Icon icon="gridicons:add-image" width={48}/>
  </span>
  <input type="file" accept="image/*" on:change/>
</label>

<style lang="scss">
  label {
    cursor: pointer;
    width: 100%;
    height: 101px;
    opacity: 0.8;
    justify-content: center;
    display: flex;
    align-content: center;
    flex-wrap: wrap;
    position: relative;
    border-radius: $border-radius-2;

    &.checkerbg {
      @include chessboard-background-pattern;
    }

    &:not(.checkerbg) {
      background-size: contain;
    }

    &:hover {
      .image-icon {
        display: block;
      }
    }
  }

  .image-icon {
    display: none;
    color: $darkest;
    filter: invert(1);
  }

  input {
    visibility: hidden;
    position: absolute;
  }
</style>