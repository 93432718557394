import { EXPORT_CLEANUP_ATTRIBUTES } from "../scaffold/ScaffoldRegulator";

const cleanExportHTML = (html: string) => {
  const nodes = new DOMParser().parseFromString(html, 'text/html');

  // cleanup attribues
  EXPORT_CLEANUP_ATTRIBUTES.forEach(attr => {
    const elements = nodes.querySelectorAll(`[${attr}]`);
    elements.forEach(el => {
      el.removeAttribute(attr);
    });
  });

  // convert back to string
  var serializer = new XMLSerializer();
  return serializer.serializeToString(nodes.querySelector('html'));
}

export default cleanExportHTML;