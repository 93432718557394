<script lang="ts">
  import Icon from "@iconify/svelte";

  export let value: string;
  export let type: 'password' | 'text' | 'email' = 'text';
  export let label: string = '';
  export let rightLabel: string = '';
  export let rightLabelClick: Function = () => null;
  export let icon: string;
  export let disabled: boolean = null;
  export let style: string = null;
  export let required = null;
  export let placeholder = '';

  const typeAction = (node) => {
    node.type = type;
  }
</script>

<label style={style}>
  <span>
    {label}{#if rightLabel}<small on:click={rightLabelClick}>{rightLabel}</small>{/if}
  </span>
  <div class="input-field">
    <i class="icon"><Icon class="icon" icon={icon} width="18px"/></i>
    <input bind:value use:typeAction disabled={disabled} required={required} placeholder={placeholder}/>
  </div>
</label>

<style lang="scss">
  label {
    display: block;

    > span {
      margin-bottom: 3px;
      display: block;
      text-transform: uppercase;
      font-size: 0.8em;
      font-weight: 600;
      margin-bottom: 8px;
      display: block;
      line-height: 1;

      small {
        float: right;
        cursor: pointer;
        text-transform: capitalize;
        font-size: 1em;
        font-weight: 400;

        &:hover {
          color: var(--ui-theme);
        }
      }
    }
  }

  .input-field {
    position: relative;
    height: 45px;
    display: flex;
    align-items: center;

    input {
      @include clear-default-styling;
      border: solid 1px #e5e5e5;
      display: block;
      width: 100%;
      box-sizing: border-box;
      height: inherit;
      padding: 10px;
      padding-left: 32px;
      font-size: 0.85em;
      font-family: $font-family;
      border-radius: $border-radius-2;

      &[disabled] {
        background-color: #efefef;
        cursor: not-allowed;
      }

      &:focus {
        border-color: var(--ui-theme);
      }
    }

    .icon {
      position: absolute;
      left: 10px;
      line-height: 1;
      font-size: 0;
    }
  }
</style>