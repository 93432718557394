<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import InputField from "./InputField.svelte";

  export let initialValue: number | string = 0;
  export let unitOptions: {label: string, value: string}[] = [];
  export let unit: string | null = null;
  export let maxRange: number = 500;
  export let minRange: number = 0;
  export let disabled: boolean = false;

  $: value = initialValue;

  const dispatch = createEventDispatcher();

  const inputHandler = () => {
    dispatch('input', value);
  }
</script>

<div>
  <div class="inputs" class:disabled={disabled}>
    <div class="text-input">
      <InputField
        type="number"
        unit={unit}
        unitOptions={unitOptions}
        disabled={disabled}
        bind:value={value}
        on:input={inputHandler}
        on:unitClick
      />
    </div>
    <div class="range-input">
      <input type="range" max="{maxRange}" min="{minRange}" bind:value={value} on:input={inputHandler} disabled={disabled}/>
    </div>
  </div>
</div>

<style lang="scss">
  .inputs {
    display: flex;
    padding: 0;
    font-size: inherit;

    & :global(.input-field input) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &.disabled {
      .range-input {
        @include input-disabled;
      }
    }
  }

  .text-input {
    flex-basis: 65px;
    flex-shrink: 0;
    border-right: solid 2px $background-index-1;
  }

  .range-input {
    @include input-styling;
    align-self: center;
    width: 100%;
    padding: 0 5px;
    font-size: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  input {
    width: 100%;
    -webkit-appearance: none;
    margin: 0;
    height: inherit;
    vertical-align: middle;
    background-color: transparent;
    margin-top: -1px;

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        width: 18px;
        height: 18px;
        border-radius: 18px;
        margin-top: -9px;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
        background-color: var(--ui-theme);
        border: solid 2px #FFF;
        cursor: pointer;
    }

    &::-moz-range-thumb {
        -webkit-appearance: none;
        width: 18px;
        height: 18px;
        border-radius: 18px;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
        background-color: var(--ui-theme);
        border: solid 2px #FFF;
    }

    &::-webkit-slider-runnable-track {
        width: 100%;
        height: 1px;
        background-color: rgba(255, 255, 255, 0.3);
    }

    &::-moz-range-track {
        width: 100%;
        height: 1px;
        background-color: #D7D7D9;
    }
  }
</style>