<script lang="ts">
  export let title: string;
</script>

<div>
  <h2 class="view-title">{title}</h2>
</div>

<style lang="scss">
  div {
    margin: 20px $view-content-edge-spacing;
    line-height: 1;
  }

  h2 {
    font-size: 1.2em;
    font-weight: 500;
    margin: 0;
    text-transform: capitalize;
  }
</style>