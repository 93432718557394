import { getAnalytics } from 'firebase/analytics';
import { initializeApp, type FirebaseApp} from 'firebase/app';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import { getPerformance } from 'firebase/performance';
import { getFunctions, httpsCallable, connectFunctionsEmulator } from 'firebase/functions';
import { getStorage, connectStorageEmulator } from 'firebase/storage';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import type {generateJSONResumeData, generateJSONResumeResult} from '../../functions/src/generateJSONResume'

export type pdfData = {pdf:{ contents: string; name: string; type: string }}
export type HandlerResult<T=pdfData> = T extends pdfData ? T & {doc_id:string} : never

const config = {
  apiKey: "AIzaSyA44pNaNsi4fPkvikuVjFUO52cZ1M_rkXk",
  authDomain: "formulator-da024.firebaseapp.com",
  projectId: "formulator-da024",
  storageBucket: "formulator-da024.appspot.com",
  messagingSenderId: "1046938680615",
  appId: "1:1046938680615:web:36038cd16d013e3a50fd02",
  measurementId: "G-503K8MCKMH"
};

// Initialize Firebase
const app = initializeApp(config);

const ifNotProdUndefined = <T>(fn: (app: FirebaseApp) => T): T | undefined => {
  if (!import.meta.env.PROD) {
    return undefined;
  } 
  return fn(app);
};

const analytics = ifNotProdUndefined(getAnalytics);
const auth = getAuth(app);
const perf = ifNotProdUndefined(getPerformance);
const functions = getFunctions(app, 'us-central1');
const storage = getStorage(app)
const firestore = getFirestore(app)

const CF_generatorRequest = httpsCallable(functions, 'generatorRequest');
const CF_publishPage = httpsCallable(functions, 'publishPage');
const CF_askYourPDF = httpsCallable<pdfData,HandlerResult>(functions, 'askYourPDF');
const CF_generateResume = httpsCallable<generateJSONResumeData,generateJSONResumeResult>(functions, 'generateJSONResume');

//const CF_getSpreadsheetData = httpsCallable(functions, 'getSpreadsheetData');

if (!import.meta.env.PROD) {
  // Using Firebase default ports
  connectAuthEmulator(auth, 'http://127.0.0.1:9099', { disableWarnings: true })
  connectFunctionsEmulator(functions, 'localhost', 5001)
  connectStorageEmulator(storage, 'localhost', 9199)
  connectFirestoreEmulator(firestore, 'localhost', 8080)  
}

export {
  analytics,
  auth,
  perf,
  storage,
  firestore,
  CF_generatorRequest,
  CF_publishPage,
  CF_askYourPDF,
  CF_generateResume,
};
