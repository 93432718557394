import { sendEmailVerification } from 'firebase/auth';

import { auth } from './firebase';

const sendEmailVerif = async (): Promise<string> => {
  try {
    await sendEmailVerification(auth.currentUser);
    return 'Verification email sent.';
  } catch (error) {
    console.error(error);
    return `Error: ${error.message}`;
  }
};

export default sendEmailVerif;