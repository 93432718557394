<script lang="ts">
  import logoLight from '/images/business/formulator-light.svg';
  import logoDark from '/images/business/formulator-dark.svg';

  export let place: 'dashboard' | 'about' | null = null;
</script>

<img src={place !== 'about' ? logoLight : logoDark} alt="Formulator" class={place}/>

<style lang="scss">
  img {
    font-family: 'Outfit', 'Linter', sans-serif;
    color: $lightest;
    font-size: 1.30em;
    letter-spacing: 1.1px;
    text-align: center;
    
    &.dashboard {
      text-align: left;
      margin: 0;
      width: 160px;
    }

    &.about {
      max-width: 200px;
    }
  }
</style>