<script lang="ts">
  import uploadUserImage from "../../firebase/uploadUserImage";
  import getClassFromSet from "../../helpers/getClassFromSet";
  import getTailwindArbitraryValue from "../../helpers/getTailwindArbitraryValue";
  import ScaffoldCoordinator from "../../scaffold/ScaffoldCoordinator";
  import { selectedElementScaffold } from "../../stores/scaffoldStore";
  import TAILWIND_SETS from "../../utils/_tailwind-sets";
  import { sizeOptions } from "../../utils/_tool-options";
  import { BackgroundPreview, Label, ViewSection } from "../atoms";
  import { InputToggle } from "../molecules";
  import InputColor from "../molecules/InputColor.svelte";

  let values;

  const arbitraryRegex = (property: string): RegExp => new RegExp(`^${property}-\\[[^\\]]+\\]`);
  
  $: {
    const classList = $selectedElementScaffold.selectedScaffold?.attributes?.class ?? '';
    values = {
      color: getTailwindArbitraryValue(getClassFromSet(classList, arbitraryRegex('bg')) ?? '') ?? '',
      image: '',
      size: getClassFromSet(classList, TAILWIND_SETS.backgroundSize),
      attachment: ''
    }
  }

  const colorHandler = (event) => {
    if( event.detail !== '' && event.detail !== '#' ) {
      ScaffoldCoordinator.addTailwindClass(`bg-[${event.detail}]`);
      values.color = event.detail
    } else {
      ScaffoldCoordinator.removeClass(arbitraryRegex('bg'));
    }
  }

  const sizeHandler = (event) => {
    ScaffoldCoordinator.addTailwindClass(event.detail);
    values.size = event.detail;
    console.log(event.detail)
  }

  const attachmentHandler = (event) => {
    ScaffoldCoordinator.addTailwindClass(event.detail);
    values.attachment = event.detail;
  }

  const imageHandler = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const uploadedImage = await uploadUserImage(file);
      ScaffoldCoordinator.addTailwindClass(`bg-[url('${uploadedImage.downloadURL}')]`);
    }
  }
</script>

<ViewSection>
  <!--<InputToggle initialValue={activeToggle} options={backgroundToggleOptions} onChange={toggleHandler}/>-->
  <div class="color">
    <div>
      <Label>Select Image</Label>
      <BackgroundPreview color={values.color} image={values.image} on:change={imageHandler}/>
    </div>
    <div class="values">
      <div>
        <Label>Color</Label>
        <InputColor value={values.color} on:input={colorHandler}/>
      </div>
      <!--<div>
        <Label>Image Path</Label>
        <InputField type="text" leftIcon="eva:file-text-outline" placeholder="/images/cat.jpg"/>
      </div>-->
      <!--<div>
        <Label>Attachment</Label>
        <InputToggle options={attachmentOptions} on:change={attachmentHandler}/>
      </div>-->
      <!--<div>
        <Label>Opacity</Label>
        <InputRange units={['%']}/>
      </div>-->
      <div>
        <Label>Image Size</Label>
        <InputToggle initialValue={values.size} options={sizeOptions} on:change={sizeHandler} disabled={!values.image}/>
      </div>
      <!--<div class="col-2">
        <div>
          <Label>Clip</Label>
          <InputSelect options={clipOptions} disabled={!values.image}/>
        </div>
        <div>
          <Label>Attachment</Label>
          <InputToggle options={attachmentOptions}/>
        </div>
      </div>-->
    </div>
  </div>
  <!--{#if activeToggle === 'image'}
  <div class="image">
    <div>
      <Label>Select Image</Label>
      <BackgroundPreview type="image"/>
    </div>
    <div class="values">
      <div>
        <Label>Size</Label>
        <InputToggle options={sizeOptions} onChange={sizeToggleHandler} disableTransition/>
      </div>
      {#if sizeToggle === 'custom'}
        <div class="col-2">
          <div>
            <Label>Width</Label>
            <InputField unit="px"/>
          </div>
          <div>
            <Label>Height</Label>
            <InputField unit="px"/>
          </div>
        </div>
      {/if}
      <div class="col-2">
        <div>
          <Label>Clip</Label>
          <InputSelect options={clipOptions}/>
        </div>
        <div>
          <Label>Attachment</Label>
          <InputToggle options={attachmentOptions} disableTransition/>
        </div>
      </div>
      <div>
        <Label>Repeat</Label>
        <InputToggle options={repeatOptions} disableTransition/>
      </div>
      <div>
        <Label>Image Path</Label>
        <InputField type="text" leftIcon="eva:file-text-outline" placeholder="/images/cat.jpg"/>
      </div>
    </div>
  </div>
  {/if}-->
  <!--{#if activeToggle === 'gradient'}
    <div style:height="200px">
      <NotReady/>
    </div>
  {/if}-->
</ViewSection>

<style lang="scss">
  .values {
    display: grid;
    grid-template-rows: 1fr 1fr;
    row-gap: 10px;
  }

  .color, .image {
    margin-top: 10px;
    display: grid;
    grid-template-columns: 101px 1fr;
    column-gap: 15px;
  }

  .col-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 6px;
  }
</style>