import { collection, query, where, getDocs, updateDoc, DocumentReference } from 'firebase/firestore';
import { firestore } from './firebase';

type UpdateFieldParams = {
    userId: string;
    componentId: string;
    fieldName: string;
    newValue: any;
};

async function updateFieldInFirestore({ userId, componentId, fieldName, newValue }: UpdateFieldParams): Promise<void> {
    const collectionRef = collection(firestore, 'user-components');
    const q = query(collectionRef, where('userId', '==', userId), where('componentId', '==', componentId));

    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
        // Assuming there's only one document that matches
        const docRef: DocumentReference = querySnapshot.docs[0].ref;

        // Update the field
        await updateDoc(docRef, {
            [fieldName]: newValue
        });
    } else {
        console.log('No document found with the given userId and componentId');
    }
}

export default updateFieldInFirestore;
