import { get } from "svelte/store";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { storage } from "./firebase";
import { v4 as uuidv4 } from 'uuid';
import { authUser } from "../stores/userStore";

const uploadUserImage = async (image: File) => {
  try {
    if (!image) {
      alert('No file selected');
      console.error("No file selected");
      return;
    }

    const userId = get(authUser).uid;
    const fileExtension = image.name.split('.').pop();
    const fileName = `${uuidv4()}.${fileExtension}`;

    const metadata = {
      cacheControl: "public, max-age=15768000"  // 6 months in seconds
    };

    const storageRef = ref(storage, `user-images/${userId}/${fileName}`);
    await uploadBytes(storageRef, image, metadata);

    const downloadURL = await getDownloadURL(storageRef);

    return { fileName, downloadURL };
  } catch (error) {
    console.error("Error uploading image: ", error);
    // You can also implement more complex error handling here
  }
}

export default uploadUserImage;