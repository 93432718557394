<script lang="ts">
  export let variant: string = '';
  export let title: string = '';
</script>

<button on:click class:outline={variant === 'outline'} title={title}>
  <slot/>
</button>

<style lang="scss">
  button {
    @include clear-default-button-styling;
    width: 34px;
    height: 34px;
    color: $lightest;
    background-color: $background-color-index-4;
    border-radius: 100%;
    border: solid 1px $background-color-index-4-offset;
    box-sizing: border-box;
    position: relative;

    :global(> *) {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }

    &:hover {
      background-color: $background-color-index-4-offset;
    }

    &:active {
      color: var(--ui-theme);
    }

    &.outline {
      background-color: transparent;
      border: solid 2px $background-color-index-4;
    }
  }
</style>