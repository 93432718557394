<script lang="ts">
  import { quintInOut } from "svelte/easing";
  import { fly } from "svelte/transition";
  import { authUser } from "../../stores/userStore";
  import { BlurBackgroundFilter } from "../atoms";
  import { UserEntry, UserProfile } from "../templates";
</script>

<BlurBackgroundFilter>
  <main class:profile-view={$authUser} transition:fly={{duration: 500, x: 0, y: -50, easing: quintInOut}}>
    {#if !$authUser}
      <UserEntry/>
    {:else}
      <UserProfile/>
    {/if}
  </main>
</BlurBackgroundFilter>

<style lang="scss">
  main {
    @include overlaying-content-container;
    --ui-theme: #{$ui-theme-color-output};
    transition: width .30s;

    &.profile-view {
      max-width: 500px;
    }
  }
</style>