<script lang="ts">
  import Icon from "@iconify/svelte";
  import { createEventDispatcher } from "svelte";

  export let options: {label: string | {icon: string, width: string, hFlip: boolean, rotate: number}, value: string}[];
  export let initialValue: string;
  export let disabled: boolean = false;
  
  $: value = initialValue;

  const dispatch = createEventDispatcher();

  const optionClickHandler = (thisValue: string) => {
    value = thisValue;
    dispatch('change', thisValue);
  }
</script>

<div class="input-toggle" class:disabled={disabled}>
  <div class="wrapper">
    <div class="options">
      {#each options as option}
        <div>
          <button on:click={() => optionClickHandler(option.value)} class:selected={option.value === value}>
            {#if typeof option.label === 'string'}
              {option.label}
            {:else}
              <Icon icon={option.label.icon} width={option.label.width} hFlip={option.label.hFlip} rotate={option.label.rotate}/>
            {/if}
          </button>
        </div>
      {/each}
    </div>
  </div>
</div>

<style lang="scss">
  .input-toggle {
    &.disabled {
      @include input-disabled;
    }
  }

  .wrapper {
    position: relative;
  }

  .options {
    height: 38px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    font-size: 0.85em;
    position: relative;
    border: solid 2px var(--theme-secondary);
    background-color: var(--theme-primary);
    border-radius: $border-radius-2;

    > div {
      height: inherit;
      flex-basis: 100%;
      flex-grow: 1;
      box-sizing: border-box;
      padding: 4px 2px;
    }
  }

  button {
    @include clear-default-button-styling;
    height: 100%;
    width: 100%;
    display: block;
    line-height: 1;
    z-index: 2;
    position: relative;
    align-items: center;
    text-align: center;
    cursor: pointer;

    &:hover {
      color: var(--ui-theme);
    }

    &.selected {
      border: solid 1px var(--theme-secondary-offset-1);
      background-color: var(--theme-secondary);
      border-radius: calc($border-radius-2 - 3px);
    }
  }

  // TODO: apply label styles without this. Maybe make it an atom.
  .label {
    @include input-label;
  }
</style>