<script lang="ts">
  import { quintOut } from "svelte/easing";
  import { fly } from "svelte/transition";
  import { InputField } from "../molecules";
  import { IconLibItem } from "../atoms";
</script>

<div class="icons-lib" transition:fly={{ duration: 300, x: 0, y: -15, easing: quintOut }}>
  <section class="filter">
    <h3>Icons Library</h3>
    <InputField leftIcon="eva:search-outline" placeholder="Search through 150,000+ icons"/>
  </section>
  <section class="results">
    <IconLibItem icon="eva:search-outline"/>
    <IconLibItem icon="eva:search-outline"/>
    <IconLibItem icon="eva:search-outline"/>
    <IconLibItem icon="eva:search-outline"/>
    <IconLibItem icon="eva:search-outline"/>
    <IconLibItem icon="eva:search-outline"/>
    <IconLibItem icon="eva:search-outline"/>
    <IconLibItem icon="eva:search-outline"/>
    <IconLibItem icon="eva:search-outline"/>
    <IconLibItem icon="eva:search-outline"/>
    <IconLibItem icon="eva:search-outline"/>
    <IconLibItem icon="eva:search-outline"/>
    <IconLibItem icon="eva:search-outline"/>
    <IconLibItem icon="eva:search-outline"/>
    <IconLibItem icon="eva:search-outline"/>
    <IconLibItem icon="eva:search-outline"/>
    <IconLibItem icon="eva:search-outline"/>
    <IconLibItem icon="eva:search-outline"/>
    <IconLibItem icon="eva:search-outline"/>
    <IconLibItem icon="eva:search-outline"/>
    <IconLibItem icon="eva:search-outline"/>
    <IconLibItem icon="eva:search-outline"/>
    <IconLibItem icon="eva:search-outline"/>
    <IconLibItem icon="eva:search-outline"/>
    <IconLibItem icon="eva:search-outline"/>
    <IconLibItem icon="eva:search-outline"/>
    <IconLibItem icon="eva:search-outline"/>
    <IconLibItem icon="eva:search-outline"/>
    <IconLibItem icon="eva:search-outline"/>
    <IconLibItem icon="eva:search-outline"/>
    <IconLibItem icon="eva:search-outline"/>
    <IconLibItem icon="eva:search-outline"/>
    <IconLibItem icon="eva:search-outline"/>
    <IconLibItem icon="eva:search-outline"/>
    <IconLibItem icon="eva:search-outline"/>
    <IconLibItem icon="eva:search-outline"/>
    <IconLibItem icon="eva:search-outline"/>
    <IconLibItem icon="eva:search-outline"/>
    <IconLibItem icon="eva:search-outline"/>
    <IconLibItem icon="eva:search-outline"/>
    <IconLibItem icon="eva:search-outline"/>
    <IconLibItem icon="eva:search-outline"/>
    <IconLibItem icon="eva:search-outline"/>
    <IconLibItem icon="eva:search-outline"/>
    <IconLibItem icon="eva:search-outline"/>
    <IconLibItem icon="eva:search-outline"/>
    <IconLibItem icon="eva:search-outline"/>
    <IconLibItem icon="eva:search-outline"/>
    <IconLibItem icon="eva:search-outline"/>
    <IconLibItem icon="eva:search-outline"/>
    <IconLibItem icon="eva:search-outline"/>
  </section>
</div>

<style lang="scss">
  .icons-lib {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: auto 1fr;
    --ui-theme: #{$ui-theme-color-components};
    @include interface-overlay-container-styling($border-radius-1);
  }

  .filter {
    height: fit-content;
    padding: 0 20px;
    padding-bottom: 15px;
    border-bottom: solid 1px #5b5b5b;
  }

  .results {
    overflow-y: scroll;
    padding: 10px 0px;
    margin: 0 20px;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 10px;
    @include custom-scrollbar(#2f2f2f, #5b5b5b);
  }

  h3 {
    font-size: 1.15em;
    font-weight: 400;
    margin: 15px 0;
    line-height: 1;
  }
</style>