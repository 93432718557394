import {
  collection,
  doc,
  getDoc,
} from 'firebase/firestore';

import {
  firestore
} from './firebase'

const getUserCollections = async (userId) => {
  const userCollection = collection(firestore, 'user-collection');
  
  // Reference to the document with the ID as userId
  const userDoc = doc(userCollection, userId);

  // Fetch the document
  const docSnapshot = await getDoc(userDoc);

  if (docSnapshot.exists()) {
    // Document data will be stored in a JSON object
    const metadata = docSnapshot.data();
    return metadata;
  } else {
    // Handle the case where there is no document with the specified ID
    console.log("No such document!");
    return null;
  }
}

export default getUserCollections;