<script lang="ts">
  import uploadUserImage from "../../firebase/uploadUserImage";
  import ScaffoldCoordinator from "../../scaffold/ScaffoldCoordinator";
  import { selectedElementScaffold } from "../../stores/scaffoldStore";
  import { ImagePreview, Label, ViewSection } from "../atoms";
  import { InputField } from "../molecules";

  let altText: string;
  let src: string;

  $: {
    if( $selectedElementScaffold && $selectedElementScaffold.hasOwnProperty('selectedScaffold') && $selectedElementScaffold.selectedScaffold.hasOwnProperty('attributes') ) {
      const selectedScaffoldAttr = $selectedElementScaffold.selectedScaffold.attributes
      altText = selectedScaffoldAttr.alt ?? '';
      src = selectedScaffoldAttr.src ?? '';
    }
  }

  const inputHandler = (event, attribute) => {
    ScaffoldCoordinator.setAttribute(attribute, event.detail);
  }

  const imageHandler = async (event) => {
    const file = event.target.files[0];
    if( file ) {
      const imageUrl = await uploadUserImage(file);
      src = imageUrl.downloadURL;
      ScaffoldCoordinator.setAttribute('src', imageUrl.downloadURL);
    }
  }
</script>

<ViewSection>
  <div class="layout">
    <div>
      <Label>Select Image</Label>
      <ImagePreview src={src} on:change={imageHandler}/>
    </div>
    <div class="inputs">
      <div>
        <Label>Alt Text</Label>
        <InputField
          value={altText}
          placeholder="Add a short image description..."
          leftIcon="fluent:image-alt-text-24-regular"
          on:input={(event) => inputHandler(event, 'alt')}
        />
      </div>
      <div>
        <Label>Image Path</Label>
        <InputField
          value={src}
          placeholder="/images/cat.jpg"
          leftIcon="eva:file-text-outline"
          on:input={(event) => inputHandler(event, 'src')}
        />
      </div>
    </div>
  </div>
</ViewSection>

<style lang="scss">
  .layout {
    margin-top: 10px;
    display: grid;
    grid-template-columns: 101px 1fr;
    column-gap: 15px;
  }

  .inputs {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 6px;
    row-gap: 10px;
  }

  .rotate, .size {
    grid-column-start: 1;
    grid-column-end: 3;
  }
</style>