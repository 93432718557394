import {
  firestore,
} from './firebase'
import { collection, arrayUnion, doc, getDoc, setDoc, updateDoc } from 'firebase/firestore';

async function logGeneratorRequest(userId, prompt) {
  const userGeneratorCollection = collection(firestore, 'user-generator-prompts');
  const userDoc = doc(userGeneratorCollection, userId); 
  
  // First, check if the document exists
  const docSnap = await getDoc(userDoc);
  
  if (docSnap.exists()) {
    // Update the existing document
    await updateDoc(userDoc, {
      "prompts": arrayUnion(prompt)
    });
  } else {
    // Document doesn't exist yet, create it
    await setDoc(userDoc, {
      "prompts": [prompt] // Make sure to pass an array
    });
  }
}


export default logGeneratorRequest;
