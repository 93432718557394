<script lang="ts">
  import { Label, ViewSection } from "../atoms";
  import { InputField } from "../molecules";
  import ScaffoldCoordinator from "../../scaffold/ScaffoldCoordinator";
  import { selectedElementScaffold } from "../../stores/scaffoldStore";
  import InputToggle from "../molecules/InputToggle.svelte";

  let colsValue: string | number,
      rowsValue: string | number,
      gapValueX: string | number,
      gapValueY: string | number = null;

  /*$: {
    if( $selectedElementScaffold ) {
      colsValue = ScaffoldCoordinator.getTailwindClassValue('grid-cols-');
      rowsValue = ScaffoldCoordinator.getTailwindClassValue('grid-rows-');
      gapValueX = ScaffoldCoordinator.getTailwindClassValue('gap-x-');
      gapValueY = ScaffoldCoordinator.getTailwindClassValue('gap-y-');
    }
  }*/

  const layoutTypeOptions = [
    {label: 'None', value: 'none'},
    {label: 'Grid', value: 'grid'},
    {label: 'Flexbox', value: 'flexbox'},
  ]

  const inputChangeHandler = (value, classPrefix) => {
    //ScaffoldCoordinator.tailwindClassUpdate(classPrefix, value);
  }
</script>

<ViewSection>
  <div class="layout-type">
    <Label>Type</Label>
    <InputToggle options={layoutTypeOptions} initialValue="none"/>
  </div>
  <div class="layout">
    <div>
      <div>
        <Label>Columns</Label>
        <InputField value={colsValue} type="number" stepper on:input={(event) => inputChangeHandler(event.detail, 'grid-cols-')}/>
      </div>
      <div>
        <Label>Cols Gap</Label>
        <InputField value={gapValueX} type="number" stepper on:input={(event) => inputChangeHandler(event.detail, 'gap-x-')}/>
      </div>
    </div>
    <div>
      <div>
        <Label>Rows</Label>
        <InputField value={rowsValue} type="number" stepper on:input={(event) => inputChangeHandler(event.detail, 'grid-rows-')}/>
      </div>
      <div>
        <Label>Rows Gap</Label>
        <InputField value={gapValueY} type="number" stepper on:input={(event) => inputChangeHandler(event.detail, 'gap-y-')}/>
      </div>
    </div>
  </div>
</ViewSection>

<style lang="scss">
  .layout {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 15px;
    row-gap: 10px;

    > div {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 6px;
    }
  }

  .layout-type {
    margin-bottom: 10px;
  }
</style>