import {
  derived,
  type Readable,
  writable,
  type Writable,
} from 'svelte/store';

import findObjectWithKeyValue from '../helpers/findByKeyValue';

export const scaffold: Writable<object | null> = writable(null);
export const scaffoldDataSource: Writable<object | null> = writable(null);
export const selectedElement: Writable<{id: string | null, element: string | null}> = writable({id: null, element: null});
export const selectedElementPrioritized: Writable<boolean> = writable(false);
export const selectedElementScaffold: Readable<{scaffoldCopy: object; selectedScaffold: object} | null> = derived([selectedElement, scaffold], ([selectedElement, scaffold]) => { // TODO: this store belongs more in the data-store store file. Also, maybe selected element store
  const scaffoldCopy = JSON.parse(JSON.stringify(scaffold));
  const selectedScaffold = findObjectWithKeyValue(scaffoldCopy, 'data-id', selectedElement.id);
  return {scaffoldCopy, selectedScaffold}; // return the selected element scaffold object, and the full scaffold for reference used for faster editing
});
export const copiedScaffold: Writable<object | null> = writable(null);