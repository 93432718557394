export const viewWidths = {
  normal: 390,
  wide: 800
}

export const NAV_ITEM = Object.freeze({
  generator: 'generator',
  preferences: 'preferences',
  collection: 'collection',
  pages: 'pages',
  responsive: 'responsive',
  elements: 'elements',
  components: 'components',
  store: 'store',
  blog: 'blog',
  email: 'email',
  analytics: 'analytics',
  preview: 'preview',
  export: 'export',
  publish: 'publish',
  colors: 'colors',
  properties: 'properties',
  interaction: 'interaction',
  trash: 'trash',
  undo: 'undo',
  redo: 'redo',
  data: 'data',
  DOMNavigator: 'DOMNavigator'
});

export default Object.freeze({
  [NAV_ITEM.generator]: {
    view: 'GeneratorView',
    viewWidth: viewWidths.normal
  },
  [NAV_ITEM.data]: {
    view: 'DataView',
    viewWidth: viewWidths.normal
  },
  [NAV_ITEM.preferences]: {
    view: 'PreferencesView',
    viewWidth: viewWidths.normal
  },
  [NAV_ITEM.collection]: {
    view: 'CollectionView',
    viewWidth: viewWidths.normal
  },
  [NAV_ITEM.pages]: {
    view: 'PreferencesView',
    viewWidth: viewWidths.normal
  },
  [NAV_ITEM.responsive]: {
    view: 'NotReadyView',
    viewWidth: viewWidths.normal
  },
  [NAV_ITEM.elements]: {
    view: 'ElementsView',
    viewWidth: viewWidths.normal
  },
  [NAV_ITEM.components]: {
    view: 'ComponentsView',
    viewWidth: viewWidths.wide
  },
  [NAV_ITEM.store]: {
    view: 'PreferencesView',
    viewWidth: viewWidths.wide
  },
  [NAV_ITEM.blog]: {
    view: 'PreferencesView',
    viewWidth: viewWidths.wide
  },
  [NAV_ITEM.email]: {
    view: 'PreferencesView',
    viewWidth: viewWidths.wide
  },
  [NAV_ITEM.analytics]: {
    view: 'PreferencesView',
    viewWidth: viewWidths.wide
  },
  [NAV_ITEM.preview]: {
    view: null,
    viewWidth: null
  },
  [NAV_ITEM.export]: {
    view: 'ExportView',
    viewWidth: viewWidths.wide
  },
  [NAV_ITEM.publish]: {
    view: 'PublishView',
    viewWidth: viewWidths.normal
  },
  [NAV_ITEM.colors]: {
    view: null,
    viewWidth: viewWidths.normal
  },
  [NAV_ITEM.properties]: {
    view: 'PropertiesView',
    viewWidth: viewWidths.normal
  },
  [NAV_ITEM.interaction]: {
    view: 'NotReadyView',
    viewWidth: viewWidths.normal
  },
  [NAV_ITEM.DOMNavigator]: {
    view: 'DOMNavigatorView',
    viewWidth: viewWidths.normal
  },
  [NAV_ITEM.trash]: {},
  [NAV_ITEM.undo]: {},
  [NAV_ITEM.redo]: {},
});