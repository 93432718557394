function getParentTree(element: HTMLElement | null): HTMLElement[] {
  const parents: HTMLElement[] = [];

  // Start with the given element
  let currentElement: HTMLElement | null = element;

  // Traverse up the DOM tree until the body element is reached
  while (currentElement && currentElement.tagName !== 'BODY') {
    const parentElement: HTMLElement | null = currentElement.parentElement;
    if (parentElement) {
      parents.push(parentElement); // Add the parent element to the array
    }
    currentElement = parentElement; // Set the current element to its parent
  }

  return parents;
}

export default getParentTree;