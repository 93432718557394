<script lang="ts">
  import { IconPreview, Label, ViewSection } from "../atoms";
  import { InputColor, InputRange, InputToggle, InputToggleMultiple } from "../molecules";

  const rotateOptions = [
    {label: {icon: 'tabler:x', width: '20px', hFlip: false}, value: 'left'},
    {label: {icon: 'tabler:rotate-rectangle', width: '20px', hFlip: false, rotate: 1}, value: 'center'},
    {label: {icon: 'tabler:rotate-rectangle', width: '20px', hFlip: false, rotate: 2}, value: 'right'},
    {label: {icon: 'tabler:rotate-rectangle', width: '20px', hFlip: false, rotate: 3}, value: 'right'},
  ]

  const flipOptions = [
    {label: {value: 'horizontal', icon: 'ic:round-flip', width: '19px', hFlip: true}},
    {label: {value: 'vertical', icon: 'ic:round-flip', width: '18px', hFlip: false, rotate: 3}},
  ]
</script>

<ViewSection>
  <div class="layout">
    <div>
      <Label>Select Icon</Label>
      <IconPreview/>
    </div>
    <div class="inputs">
      <div>
        <Label>Color</Label>
        <InputColor value="#FFF"/>
      </div>
      <div>
        <Label>Flip</Label>
        <InputToggleMultiple value={flipOptions[0]} options={flipOptions}/>
      </div>
      <div class="size">
        <Label>Size</Label>
        <InputRange unit="px"/>
      </div>
      <div class="rotate">
        <Label>Rotate</Label>
        <InputToggle options={rotateOptions} disableTransition/>
      </div>
    </div>
  </div>
</ViewSection>

<style lang="scss">
  .layout {
    margin-top: 10px;
    display: grid;
    grid-template-columns: 101px 1fr;
    column-gap: 15px;
  }

  .inputs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 6px;
    row-gap: 10px;
  }

  .rotate, .size {
    grid-column-start: 1;
    grid-column-end: 3;
  }
</style>