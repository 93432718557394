import {
  derived,
  type Readable,
  writable,
  type Writable,
} from 'svelte/store';

export const mode: Writable<'spreadsheet' | 'component'> = writable('component'); // TODO: Get rid of 'modes'. Being able to get and apply spreadsheet data to elements should be available in all cases without needing to switch modes.
export const activeNavItem: Writable<string | null> = writable(null);
export const viewWidth: Writable<number | null> = writable(null); // TODO: import nav and apply default view width of activeNavItem. For some reason, import nav causes initialization issue.
export const canvasIframe: Writable<HTMLIFrameElement | null> = writable(null);
export const canvasWindow: Readable<Window | null> = derived(canvasIframe, ($canvasIframe) => $canvasIframe && $canvasIframe.contentWindow);
export const canvasDOM: Readable<Document> = derived(canvasIframe, ($canvasIframe) => $canvasIframe && $canvasIframe.contentDocument);
export const canvasDOMMutationCounter: Writable<number> = writable(0);
export const newComponent: Writable<null | {id: string; name: string; docId: string; dataSource: null | object; method: 'from-scratch' | 'from-generator' | 'from-library' | 'from-existing' | 'from-data-source', title: string, description: string}> = writable(null);
export const interfaceOverlay: Writable<{component: ConstructorOfATypedSvelteComponent, props: object, containerStyle?: string} | null> = writable(null);
export const draggingInProgress: Writable<boolean> = writable(false);
export const generating: Writable<false | string> = writable(false);
export const showMarker: Writable<string | null> = writable(null);
export const showTutorials: Writable<boolean> = writable(true);