<script lang="ts">
  import Icon from "@iconify/svelte";

  export let heading: string | number;
</script>

<section>
  <!--<h2>{heading}</h2>-->
  <div class="icon">
    <Icon icon="eva:shopping-bag-outline" width="50px" color="#00bbff"/>
  </div>
  <p>Browse ready-to-use templates and components you can customize.<br/>supercharge your web projects and ship faster than ever.</p>
</section>

<style lang="scss">
  section {
    text-align: center;
    padding: 70px 0;
    font-size: 1.65em;
    background-image: url('../../../public/images/library/lib-bg.png');
    background-size: cover;
    position: relative;
    text-shadow: 0px 2px 5px black;
    background-position: center;

    &:before {
      content: "";
      position: absolute;
      width: 100%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 0;
      background: rgb(16,16,16);
      background: linear-gradient(180deg, rgba(16,16,16,1) 0%, rgba(16,16,16,0.9069581582633054) 20%, rgba(16,16,16,0.9069581582633054) 80%, rgba(16,16,16,1) 100%);
    }
  }

  .icon {
    position: relative;
    margin-bottom: 12px;
  }

  p, h2 {
    margin: 0;
    z-index: 1;
    position: relative;
  }

  p {
    font-size: 0.75em;
    line-height: 1.4;
  }

  h2 {
    color: var(--ui-theme);
    margin-bottom: 10px;
    line-height: 1;
  }
</style>