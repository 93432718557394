<script lang="ts">
  import { Label, ViewSection } from "../atoms";
  import { InputField } from "../molecules";
  import ScaffoldCoordinator from "../../scaffold/ScaffoldCoordinator";
  import InputRange from "../molecules/InputRange.svelte";
  import { dimensionsUnitOptions } from "../../utils/_tool-options";
  import getClassFromSet from "../../helpers/getClassFromSet";
  import extractNumberFromString from "../../helpers/getNumberFromString";
  import { selectedElementScaffold } from "../../stores/scaffoldStore";
  import getUnitFromArbitraryClass from "../../helpers/getUnitFromArbitraryClass";

  const arbitraryRegex = (property: string): RegExp => new RegExp(`^${property}-\\[[^\\]]+\\]`);

  const maxHeightRange = 1000, maxWidthRange = 1000;

  let values;

  $: {
    const classList = $selectedElementScaffold.selectedScaffold?.attributes?.class ?? '';
    values = {
      width: extractNumberFromString(getClassFromSet(classList, arbitraryRegex('w')) ?? ''),
      height: extractNumberFromString(getClassFromSet(classList, arbitraryRegex('h')) ?? ''),
      maxWidth: extractNumberFromString(getClassFromSet(classList, arbitraryRegex('max-w')) ?? ''),
      maxHeight: extractNumberFromString(getClassFromSet(classList, arbitraryRegex('max-h')) ?? ''),
      widthUnit: getUnitFromArbitraryClass(getClassFromSet(classList, arbitraryRegex('w')) ?? '') ?? 'px',
      heightUnit: getUnitFromArbitraryClass(getClassFromSet(classList, arbitraryRegex('h')) ?? '') ?? 'px',
      widthMaxUnit: getUnitFromArbitraryClass(getClassFromSet(classList, arbitraryRegex('max-w')) ?? '') ?? 'px',
      heightMaxUnit: getUnitFromArbitraryClass(getClassFromSet(classList, arbitraryRegex('max-h')) ?? '') ?? 'px'
    }
  }

  const conflictingWidthRegex = arbitraryRegex('w');
  const conflictingHeightRegex = arbitraryRegex('h');
  const conflictingWidthMaxRegex = arbitraryRegex('max-w');
  const conflictingHeightMaxRegex = arbitraryRegex('max-h');

  const widthHandler = (event) => {
    if( event.detail === null ) return;
    if( event.detail !== '' ) {
      ScaffoldCoordinator.addTailwindClass(`w-[${event.detail}${values.widthUnit}]`);
    } else {
      ScaffoldCoordinator.removeClass(conflictingWidthRegex);
    }
  }

  const heightHandler = (event) => {
    if( event.detail === null ) return;
    if( event.detail !== '' ) {
      ScaffoldCoordinator.addTailwindClass(`h-[${event.detail}${values.heightUnit}]`);
    } else {
      ScaffoldCoordinator.removeClass(conflictingHeightRegex);
    }
  }

  const widthMaxHandler = (event) => {
    if( isNaN(event.detail) ) return;
    if( event.detail !== '' ) {
      ScaffoldCoordinator.addTailwindClass(`max-w-[${event.detail}${values.widthMaxUnit}]`);
    } else {
      ScaffoldCoordinator.removeClass(conflictingWidthMaxRegex);
    }
  }

  const heightMaxHandler = (event) => {
    if( isNaN(event.detail) ) return;
    if( event.detail !== '' ) {
      ScaffoldCoordinator.addTailwindClass(`max-h-[${event.detail}${values.heightMaxUnit}]`);
    } else {
      ScaffoldCoordinator.removeClass(conflictingHeightMaxRegex);
    }
  }

  const unitChangeHandler = (unit, property) => {
    switch(property) {
      case 'w':
        values.widthUnit = unit;
        break;
      case 'h':
        values.heightUnit = unit;
        break;
      case 'max-w':
        values.widthMaxUnit = unit;
        break;
      case 'max-h':
        values.heightMaxUnit = unit;
    }
  }
</script>

<ViewSection>
  <div class="layout">
    <div class="width-height">
      <div>
        <Label>Width</Label>
        <InputRange
          initialValue={values.width}
          unit={values.widthUnit}
          unitOptions={dimensionsUnitOptions}
          maxRange={maxWidthRange}
          minRange={0}
          on:input={widthHandler}
          on:unitClick={(event) => unitChangeHandler(event.detail, 'w')}
        />
      </div>
      <div>
        <Label>Height</Label>
        <InputRange
          initialValue={values.height}
          unit={values.heightUnit}
          unitOptions={dimensionsUnitOptions}
          maxRange={maxHeightRange}
          minRange={0}
          on:input={heightHandler}
          on:unitClick={(event) => unitChangeHandler(event.detail, 'h')}
        />
      </div>
    </div>
    <div class="max-width-height">
      <div>
        <Label>Max Width</Label>
        <InputField
          unit={values.widthMaxUnit}
          value={values.maxWidth}
          unitOptions={dimensionsUnitOptions}
          type="number"
          on:input={widthMaxHandler}
          on:unitClick={(event) => unitChangeHandler(event.detail, 'max-w')}
        />
      </div>
      <div>
        <Label>Max Height</Label>
        <InputField
          unit={values.heightMaxUnit}
          value={values.maxHeight}
          unitOptions={dimensionsUnitOptions}
          type="number"
          on:input={heightMaxHandler}
          on:unitClick={(event) => unitChangeHandler(event.detail, 'max-h')}
        />
      </div>
  </div>
</ViewSection>

<style lang="scss">
  .layout {
    display: grid;
    grid-template-columns: 3fr 1fr;
    gap: 10px;
  }

  .width-height, .max-width-height {
    display: grid;
    gap: 10px;
  }
</style>