<script lang="ts">
  import { interfaceOverlay } from "../../stores/uiStore";

  const overlayClicked = (event) => {
    if( !event.target.classList.contains('interface-overlay') ) return false;
    $interfaceOverlay = null;
  }
</script>

{#if $interfaceOverlay}
  <div class="interface-overlay" on:click={overlayClicked}>
    <div class="positioning-container" style={$interfaceOverlay.containerStyle}>
      <svelte:component this={$interfaceOverlay.component} props={$interfaceOverlay.props}/>
    </div>
  </div>
{/if}

<style>
  .interface-overlay {
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    position: absolute;
    z-index: 100;
  }

  .positioning-container {
    position: absolute;
  }
</style>