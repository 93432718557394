import _ from 'lodash';

const findAllByKey = (obj: object, key: string) => {
  let result = [];

  // Recursive function to traverse the object
  const traverse = (node) => {
    if (_.isObject(node)) {
      // Check if the node has the key and push it to the result if it does
      if (_.get(node, ['attributes', key])) {
        result.push(node);
      }

      // If the node has a 'nested' property, traverse each child
      if (node.nested && _.isArray(node.nested)) {
        node.nested.forEach(child => traverse(child));
      }
    }
  };

  // Start traversing from the root
  traverse(obj);

  return result;
};

export default findAllByKey;