<script lang="ts">
  import { quintOut } from "svelte/easing";
  import { fly } from "svelte/transition";
  import { interfaceOverlay } from "../../stores/uiStore";

  export let props;

  const optionClickHandler = (value) => {
    props.onClick(value);
    $interfaceOverlay = null;
  }

  function getOptionsSubset(options) {
    if (options.length > 20) {
      return options.slice(0, 20)
    } else {
      return options
    }
  }

</script>

<ul role="menu" transition:fly={{ duration: 300, x: 0, y: -5, easing: quintOut }}>
  {#each getOptionsSubset(props.options) as option}
    {#if option.hasOwnProperty('options')}
      <span class="group-label">{option.label}</span>
      {#each option.options as groupOption}
        <li class="group-option" class:is-button={groupOption.hasOwnProperty('onClick')}>
          <button
            on:click={() => groupOption.hasOwnProperty('onClick') ? groupOption.onClick() : optionClickHandler(groupOption.value)}
            role="option"
            tabindex="-1"
            aria-selected={props.value === groupOption.value}
          >
            {groupOption.label}
          </button>
        </li>
      {/each}
    {:else}
      <li>
        <button 
          on:click={() => optionClickHandler(option.value)}
          role="option"
          tabindex="-1"
          aria-selected={props.value === option.value}
        >
          {option.label}
      </button>
      </li>
    {/if}
  {/each}
</ul>

<style lang="scss">
  ul {
    width: inherit;
    max-height: 100%;
    overflow-y: auto;
    list-style: none;
    color: $lightest;
    margin: 0;
    padding: 5px 0;
    font-size: 0.85em;
    box-sizing: border-box;
    @include interface-overlay-container-styling($border-radius-2);
    @include custom-scrollbar;
  }

  li {
    &.group-option {
      padding-left: 10px;
    }

    &.is-button {
      padding-right: 10px;

      button {
        background-color: rgba(0, 0, 0, 0.1);
        cursor: pointer;
        border-radius: 3px;

        &:hover {
          color: $lightest;
          background-color: rgba(0, 0, 0, 0.2);
        }
      }
    }

    button {
      @include clear-default-button-styling;
      padding: 5px 8px;
      box-sizing: border-box;
      cursor: default;
      font-size: 0.85em;
      display: block;
      width: 100%;
      text-align: left;

      &:hover,
      &[aria-selected="true"] {
        color: var(--ui-theme)
      }
    }
  }

  .group-label {
    text-transform: uppercase;
    font-size: 0.70em;
    font-weight: 600;
    padding-left: 10px;
    margin-top: 5px;
    display: block;
    color: $background-color-index-4-offset-2;
  }
</style>