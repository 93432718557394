<script lang="ts">
  import ScaffoldCoordinator from "../../scaffold/ScaffoldCoordinator";
  import { selectedElementScaffold } from "../../stores/scaffoldStore";
  import { Label, ViewSection } from "../atoms";
  import { InputField } from "../molecules";

  let src: string;

  $: src = $selectedElementScaffold.selectedScaffold?.attributes?.src ?? '';

  const handleEmbed = (event) => {
    ScaffoldCoordinator.setAttribute('src', event.detail);
  }
</script>

<ViewSection>
  <Label>Embed Content URL</Label>
  <InputField value={src} on:input={handleEmbed} placeholder="https://example.com" leftIcon="eva:link-2-fill"/>
</ViewSection>