<script lang="ts">
  import { quintInOut } from "svelte/easing";
  import { BlurBackgroundFilter, IconButton } from "../atoms";
  import { fly } from "svelte/transition";
  import Icon from "@iconify/svelte";
  import { interfaceOverlay } from "../../stores/uiStore";
</script>

<BlurBackgroundFilter>
  <main class="theme-light" transition:fly={{duration: 500, x: 0, y: -50, easing: quintInOut}}>
    <div class="close-container">
      <IconButton on:click={() =>  interfaceOverlay.set(null)}>
        <Icon icon="uil:times"/>
      </IconButton>
    </div>
    <header>
      <!--<Logo place="about"/>-->
      <h1>🎉&nbsp;&nbsp;&nbsp;Welcome to Formulator Open Beta!&nbsp;&nbsp;&nbsp;🎉</h1>
    </header>
    <article>
      <p>Hey, there 👋
        
      <br/><br/>We’re thrilled to finally release the open beta of Formulator. Before saying anything else; expect some bugs, missing functionality, and questionable design decisions while we continue to shape Formulator into a tool that many people find useful.
      
      <br/><br/>Formulator is a website and components builder that aims to integrate the latest advancements in AI in a way that is truly helpful and reliable. You can generate websites and components by writing text prompts, then iterate by customizing with precision using the Formulator builder tools. When its hard to articulate what you envision, or when AI is just not getting it; Formulator allows you to move forward by giving you the tools to make those changes manually, still without writing a single line of code.
      
      <br/><br/>With this beta, we hope to gain insight into how people use Formulator, so we can prioritize feature development, and make better decisions on what to focus on, and what Formulator should ultimately be. Please provide feedback and tell us how Formulator has helped you prototype and build faster at jarolin.vargas@formulator.app
      
      <br/><br/><em>Have fun Formulating!</em> 👨‍🔬</p>
    </article>
    <footer>
      <a href="https://twitter.com/FormulatorApp" target="_blank"><Icon width="20px" icon="simple-icons:x"/></a>
      <!--<a href="https://www.reddit.com/r/FormulatorApp/" target="_blank"><Icon width="25px" icon="dashicons:reddit"/></a>-->
      <!--<a href="https://discord.gg/cZ7aaN4V" target="_blank"><Icon width="25px" icon="ic:baseline-discord"/></a>-->
      <!--<a href="https://www.youtube.com/@FormulatorApp" target="_blank"><Icon width="25px" icon="mdi:youtube"/></a>-->
      <a href="https://discord.gg/WEnefzNxqR" target="_blank"><Icon width="25px" icon="simple-icons:discord"/></a>
      <a href="https://www.linkedin.com/company/102122535/admin/feed/posts/" target="_blank"><Icon width="25px" icon="simple-icons:linkedin"/></a>
      <a href="mailto:jarolin.vargas@formulator.app" target="_blank"><Icon width="25px" icon="mdi:email-edit-outline"/></a>
    </footer>
  </main>
</BlurBackgroundFilter>

<style lang="scss">
  main {
    @include overlaying-content-container;
    --ui-theme: #{$ui-theme-color-output};
    transition: width .30s;
    overflow-y: scroll;
    max-width: 900px;

    &.theme-light {
      @include theme-light;
    }
  }

  header {
    text-align: center;
    padding: 50px 30px;
    position: sticky;
    top: 0;
    z-index: 0;

    h1 {
      font-weight: 600;
      margin: 0;
    }
  }

  article {
    padding: 0px 30px;
    margin-bottom: 100px;
  }

  footer {
    text-align: center;
    display: flex;
    justify-content: center;
    position: sticky;
    bottom: 50px;

    a {
      background-color: $ui-theme-color-components;
      display: flex;
      margin-bottom: 15px;
      border-radius: 5px;
      width: 50px;
      height: 50px;
      text-decoration: none;
      color: inherit;
      color: $lightest;
      font-size: 0;
      border-radius: 100%;
      text-align: center;
      margin: 0 5px;
      align-items: center;
      justify-content: center;

      &[href*="twitter"] {
        background-color: black;
      }

      &[href*="discord"] {
        background-color: #5865f2;
      }

      &[href*="reddit"] {
        background-color: #ff4501;
      }

      &[href*="youtube"] {
        background-color: #ff0001;
      }

      &[href*="producthunt"] {
        background-color: #d44f28;
      }

      &[href*="linkedin"] {
        background-color: #0077b5;
      }
    }
  }

  article {
    position: relative;
    z-index: 10;
  }

  p {
    background-color: #F7F7F9;
    padding: 30px;
    border-radius: $border-radius-1;
    line-height: 2;
    font-size: 1.3em;

    &:first-child {
      margin-top: 0;
    }
  }

  .close-container {
    @include close-container;
  }
</style>