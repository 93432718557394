<script lang="ts">
  import Icon from "@iconify/svelte";
  import { IconButton, Label, ViewSection } from "../atoms";
  import { InputRange, InputToggle, InputColor } from "../molecules";

  const shadowDirections = [
    {label: 'outside', value: 'outside'},
    {label: 'inside', value: 'inside'}
  ]
</script>

<ViewSection>
  <div class="buttons">
    <div class="selection">
      <IconButton on:click={() => alert('test')}><Icon icon="eva:plus-fill" width="20px"/></IconButton>
      <IconButton on:click={() => alert('test')} variant="outline"><Icon icon="clarity:circle-solid" width="12px"/></IconButton>
    </div>
    <div class="selection-effects">
      <IconButton on:click={() => alert('test')}><Icon icon="eva:eye-outline" width="20px"/></IconButton>
      <IconButton on:click={() => alert('test')}><Icon icon="eva:trash-2-outline" width="20px"/></IconButton>
    </div>
  </div>
  <div class="controls">
    <div>
      <Label>Direction</Label>
      <InputToggle value="outside" options={shadowDirections} disableTransition onChange={() => console.log('test')}/>
    </div>
    <div>
      <Label>Color</Label>
      <InputColor value="#000"/>
    </div>
    <div>
      <Label>Blur</Label>
      <InputRange units={['px', '%']}/>
    </div>
    <div>
      <Label>Spread</Label>
      <InputRange units={['px', '%']}/>
    </div>
    <div>
      <Label>X Offset</Label>
      <InputRange units={['px', '%']}/>
    </div>
    <div>
      <Label>Y Offset</Label>
      <InputRange units={['px', '%']}/>
    </div>
  </div>
</ViewSection>

<style lang="scss">
  .selection {
    flex-basis: 100%;
    display: flex;
    gap: 7px;
  }

  .selection-effects {
    display: flex;
    flex-shrink: 0;
    gap: 7px;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .controls {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 15px;
    row-gap: 10px;
  }
</style>