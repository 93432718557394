<script lang="ts">
  import { onMount } from "svelte";
  import ScaffoldCoordinator from "../../scaffold/ScaffoldCoordinator";
  import { selectedElement, selectedElementPrioritized } from "../../stores/scaffoldStore";
  import { canvasDOM, canvasDOMMutationCounter } from "../../stores/uiStore";
  import { RemoveMark } from "../atoms";

  let markers = [];
  let elements: HTMLElement[] = [];

  const removeElement = (event) => {
    if (event.key === 'Backspace' || event.key === 'Delete') {
      ScaffoldCoordinator.removeElement($selectedElement.id, $selectedElement.element);
      selectedElement.set({id: null, element: null});
    }
  }

  onMount(() => {
    document.addEventListener('keydown', removeElement);

    return () => {
      selectedElement.set({id: null, element: null});
      document.removeEventListener('keydown', removeElement);
    }
  });
  
  $: {
    if( $canvasDOMMutationCounter ) {
      elements = Array.from($canvasDOM.querySelectorAll('[data-id]'));
      markers = elements.map(element => {
        const dimensions = element.getBoundingClientRect();
        return {
          id: element.getAttribute('data-id'),
          element: element.getAttribute('data-element'),
          y: dimensions.y,
          x: dimensions.x,
          width: dimensions.width,
          height: dimensions.height
        }
      });
    }
  }

  const clickHandler = (elementId, elementName) => {
    selectedElementPrioritized.set(false);
    if( $selectedElement.id === elementId ) {
      selectedElement.set({id: null, element: null});
    } else {
      selectedElement.set({id: elementId, element: elementName}); 
    }
  }
</script>

{#each markers as marker}
  <RemoveMark
    on:click={() => clickHandler(marker.id, marker.element)}
    id={marker.id}
    element={marker.element}
    top={marker.y}
    left={marker.x}
    width={marker.width} 
    height={marker.height}
    isSelected={$selectedElement.id === marker.id}
  />
{/each}

<style lang="scss">

</style>