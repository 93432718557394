import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

export function processScaffold(scaffold) {
  // Deep clone the original object to keep it unchanged
  const newObj = _.cloneDeep(scaffold);

  // Recursive function to update 'data-id'
  const recursiveUpdate = (node) => {
    // Update 'data-id' if exists
    if (_.has(node, 'attributes.data-id')) {
      node.attributes['data-id'] = uuidv4();
    }

    // remove id's to avoid duplication
    if(_.has(node, 'attributes.id')) {
      delete node.attributes['id'];
    }

    // Recur on nested elements
    if (_.has(node, 'nested')) {
      node.nested.forEach(recursiveUpdate);
    }
  };

  // Start the recursive update
  recursiveUpdate(newObj);

  return newObj;
}


export function addScaffoldSliceToNest(scaffold, nestId, sliceToAdd) {
  const result = _.cloneDeep(scaffold);

  function updateObjectById(obj: object, id: string, newObject: object) {
    if (obj.attributes && obj.attributes['data-id'] === id) {
      if (obj.hasOwnProperty('nested')) {
        obj.nested.push(newObject);
      }
      return true;
    } else {
      _.forEach(obj, (value) => {
        if (_.isObject(value)) {
          updateObjectById(value, id, newObject);
        }
      });
    }
    return obj;
  }

  updateObjectById(result, nestId, sliceToAdd);
  return result;
}


export function addScaffoldSliceToNestAbove(scaffold, nestId, sliceToAdd, droppedAbove) {
  const result = _.cloneDeep(scaffold);

  function insertBefore(obj: object, id: string, newObject: object) {
    if( obj['attributes'] && obj['attributes']['data-id'] === droppedAbove ) {
      if( obj.hasOwnProperty('nested') ) {
        const index = obj['nested'].findIndex((child) => child['attributes']['data-id'] === nestId);
        obj['nested'].splice(index, 0, sliceToAdd);
      }
      return true;
    } else {
      _.forEach(obj, (value) => {
        if( _.isObject(value) ) {
          insertBefore(value, id, newObject);
        }
      });
    }
    return obj;
  }

  insertBefore(result, nestId, sliceToAdd)
  return result;
}


export function removeScaffoldSlice(scaffold, id) {
  const result = _.cloneDeep(scaffold);
  let removedObject = null;

  const recursiveRemove = (node) => {
    if (_.has(node, 'nested')) {
      // Filter out elements with matching data-id and keep the removed one
      const newNested = [];
      node.nested.forEach((nestedNode) => {
        if (_.get(nestedNode, 'attributes.data-id') !== id) {
          newNested.push(nestedNode);
        } else {
          removedObject = nestedNode;
        }
      });

      node.nested = newNested;

      // Recur on remaining nested elements
      node.nested.forEach(recursiveRemove);
    }
  };

  // Start the recursive remove
  recursiveRemove(result);

  return { updatedScaffold: result, removedObject };
}


export function findScaffoldSliceById(node, id) {
  // Check if this node has the matching 'data-id'
  if (node.attributes && node.attributes['data-id'] === id) {
    return node;
  }

  // If 'nested' exists, apply recursion
  if (Array.isArray(node.nested)) {
    for (let i = 0; i < node.nested.length; i++) {
      const found = findScaffoldSliceById(node.nested[i], id);
      if (found) {
        return found; // Return the found object
      }
    }
  }

  return null; // Return null if no object was found
}


export function replaceScaffoldSliceById(rootObject, targetId, newObject) {
  // Function to recursively search and replace the object
  function replace(obj) {
      if (_.isArray(obj.nested)) {
          for (let i = 0; i < obj.nested.length; i++) {
              if (obj.nested[i].attributes && obj.nested[i].attributes['data-id'] === targetId) {
                  // Replace the entire object
                  obj.nested[i] = newObject;
                  return true; // Stop searching after the match is found
              }

              // Continue searching in nested objects
              if (replace(obj.nested[i])) {
                  return true; // Stop searching once a match is found in deeper levels
              }
          }
      }
      return false; // No match found at this level
  }

  // Clone the object to avoid mutating the original
  let clonedObject = _.cloneDeep(rootObject);

  // Start the recursive replacement
  replace(clonedObject);

  // Return the updated object
  return clonedObject;
}