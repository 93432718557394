<script lang="ts">
  import { draggingInProgress } from "../../stores/uiStore";

  export let label: string;
  export let item: string;
  export let mode: 'component' | 'element' = 'element';
  export let image: string;
  export let libraryComponent: {collectionId: string, componentId: string} = null;

  const onDragStart = (event) => {
    draggingInProgress.set(true);
    if( !libraryComponent ) {
      event.dataTransfer.setData('scaffoldElement', label);
    } else {
      event.dataTransfer.setData('scaffoldComponent', JSON.stringify(libraryComponent));
    }
  }

  const dragEndHandler = () => {
    draggingInProgress.set(false);
  }
</script>

<div
  class="item {mode}"
  draggable="true"
  aria-dropeffect="execute"
  role="listitem"
  class:element={item === 'element'}
  on:dragstart={onDragStart}
  on:dragend={dragEndHandler}
>
  <span>{label}</span>
  <div class="image" style:background-image={`url(${image})`}/>
</div>


<style lang="scss">
  .item {
    height: 120px;
    cursor: move;
    position: relative;
    z-index: 1;
    box-sizing: border-box;
    background: linear-gradient(45deg, #1b1b1b, #2a2a2a);
    box-shadow: 0px 0px 5px black;
    position: relative;
    border-radius: 5px;
    border: solid 1px #353535;

    &.element {
      height: 100px;
    }

    &:hover {
      border-color: var(--ui-theme);
    }

    &.component {
      .image {
        background-size: cover;
      }

      &:after {
        content: "";
        position: absolute;
        bottom: 0px;
        height: 30px;
        width: 100%;
        background: rgb(16, 16, 16);
        background: linear-gradient(180deg, rgba(16, 16, 16, 0) 0%, rgba(16, 16, 16, 0.6853335084) 100%);
      }
    }
  }

  .image {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    border-radius: inherit;
    background-size: 80px;
    background-repeat: no-repeat;
    background-position-x: 90%;
    background-position-y: center;
  }

  span {
    position: absolute;
    bottom: 5px;
    left: 10px;
    font-size: 0.90em;
    text-transform: capitalize;
    z-index: 1;
  }
</style>