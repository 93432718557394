<script lang="ts">
  import { AuthForm } from "../organisms";

  let formType: 'sign-up' | 'sign-in' | 'forgot-password' = "sign-up";

  $: toggleLabel = formType === 'sign-up' ? ['Already have an account?', 'Sign In'] : [`Don't have an account?`, 'Sign Up'];

  const toggleFormType = (type) => {
    formType = formType === 'sign-up' ? 'sign-in' : 'sign-up';
  }
</script>

<div class="content">
  <div class="presentation">

  </div>
  <div class="actions">
    <div class="sign-up">
      <AuthForm type={formType}/>
    </div>
    <div class="sign-in">
      {toggleLabel[0]}
      <button class="form-toggle-btn" on:click={toggleFormType}>
        {toggleLabel[1]}
      </button>
    </div>
  </div>
</div>

<style lang="scss">
  .content {
    display: grid;
    grid-template-columns: auto 500px;
    height: 100%;
    max-height: 100%;
  }

  .presentation {
    background-color: #30cefc;
    background-image: url('/images/business/auth-banner.png');
    background-size: contain;
    background-position: right;
    background-repeat: no-repeat;
  }

  .actions {
    padding: 0 30px 0 30px;
    display: grid;
    overflow-y: scroll;
    grid-template-rows: auto 1fr;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  }

  .sign-up {
    //overflow-y: scroll;
    flex-grow: 1;
    padding: 30px 0;
  }

  .sign-in {
    border-top: solid 1px #d8d8d8;
    align-self: end;
    text-align: right;
    line-height: 1;
  }

  .form-toggle-btn {
    @include clear-default-button-styling;
    font-weight: 500;
    padding: 20px 0 20px 20px;

    &:hover {
      color: var(--ui-theme);
    }
  }
</style>