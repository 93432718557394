<script lang="ts">
  import getClassFromSet from "../../helpers/getClassFromSet";
  import ScaffoldCoordinator from "../../scaffold/ScaffoldCoordinator";
  import { selectedElementScaffold } from "../../stores/scaffoldStore";
  import { interfaceOverlay } from "../../stores/uiStore";
  import TAILWIND_SETS from "../../utils/_tailwind-sets";
  import { tagOptions, styleOptions, weightOptions, webSafeFontsOptions, textAlignOptions } from "../../utils/_tool-options";
  import { Label, ViewSection } from '../atoms';
  import { InputColor, InputRange, InputSelect, InputToggle, InputToggleMultiple } from "../molecules";
  import { FontsLib } from "../templates";

  let values;

  $: {
    const classList = $selectedElementScaffold.selectedScaffold?.attributes?.class ?? '';
    values = {
      align: getClassFromSet(classList, TAILWIND_SETS['textAlign']),
      tag: $selectedElementScaffold.selectedScaffold.tag,
      fontSize: TAILWIND_SETS['fontSize'].indexOf(getClassFromSet(classList, TAILWIND_SETS['fontSize'])),
      lineHeight: TAILWIND_SETS['lineHeight'].indexOf(getClassFromSet(classList, TAILWIND_SETS['lineHeight'])),
      color: '',
      fontFamily: getClassFromSet(classList, TAILWIND_SETS.fontFamily),
      weight: getClassFromSet(classList, TAILWIND_SETS.fontWeight),
      styleValues: {
        'uppercase': Boolean(getClassFromSet(classList, ['uppercase'])),
        'italic': Boolean(getClassFromSet(classList, ['italic'])),
        'underline': Boolean(getClassFromSet(classList, ['underline'])),
        'line-through': Boolean(getClassFromSet(classList, ['line-through']))
      }
    }
  }

  const addFontClickHandler = () => {
    interfaceOverlay.set({
      component: FontsLib,
      props: {},
      containerStyle: `
        width: 700px;
        height: 500px;
        max-width: 100%;
        max-height: 100%;
        margin: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      `
    });
  }

  const fontOptions = [
    {
      label: 'Auto',
      value: 'auto'
    },
    {
      label: 'Added Fonts',
      options: [
        {label: 'Poppins', value: 'font-[poppins]'},
        //{label: '+ Add font', value: null, onClick: addFontClickHandler},
      ]
    },
    {
      label: 'Web-Safe Fonts',
      options: webSafeFontsOptions
    }
  ]

  const colorHandler = (event) => {
    ScaffoldCoordinator.addTailwindClass(`text-[${event.detail}]`);
  }

  const tagHandler = (event) => {
    ScaffoldCoordinator.setTag(event.detail);
  }

  const alignHandler = (event) => {
    if( event.detail !== values.align ) {
      ScaffoldCoordinator.addTailwindClass(event.detail);
    } else {
      ScaffoldCoordinator.removeClass(event.detail);
    }
  }

  const styleHandler = (event) => {
    if( !values.styleValues[event.detail] ) {
      ScaffoldCoordinator.addTailwindClass(event.detail);
    } else {
      ScaffoldCoordinator.removeClass(event.detail);
    }
  }

  const weightHandler = (event) => {
    if( event.detail !== values.weight ) {
      ScaffoldCoordinator.addTailwindClass(event.detail);
    } else {
      ScaffoldCoordinator.removeClass(event.detail);
    }
  }

  const sizeHandler = (event) => {
    if( !event.detail ) {
      ScaffoldCoordinator.removeClass(TAILWIND_SETS['fontSize'][values.fontSize]);
    } else {
      ScaffoldCoordinator.toggleClass(TAILWIND_SETS['fontSize'][event.detail], TAILWIND_SETS['fontSize']);
    }
  }

  const lineHandler = (event) => {
    if( !event.detail ) {
      ScaffoldCoordinator.removeClass(TAILWIND_SETS['lineHeight'][values.lineHeight]);
    } else {
      ScaffoldCoordinator.toggleClass(TAILWIND_SETS['lineHeight'][event.detail], TAILWIND_SETS['lineHeight']);
    }
  }

  const fontHandler = (event) => {
    if( event.detail === 'auto' ) {
      ScaffoldCoordinator.removeClass(values.fontFamily);
      return
    } 
    if( !event.detail ) {
      ScaffoldCoordinator.removeClass(values.fontFamily);
    } else {
      ScaffoldCoordinator.addTailwindClass(event.detail);
    }
  }
</script>

<ViewSection>
  <div class="layout">
    <div>
      <Label>Font</Label>
      <InputSelect
        options={fontOptions}
        value={values.fontFamily}
        on:change={fontHandler}
      />
    </div>
    <div>
      <Label>Align</Label>
      <InputToggle
        options={textAlignOptions}
        initialValue={values.align}
        on:change={alignHandler}
      />
    </div>
    <div>
      <Label>Text Element</Label>
      <InputSelect
        options={tagOptions}
        value={values.tag}
        on:change={tagHandler}
      />
    </div>
    <div>
      <Label>Size</Label>
      <InputRange
        initialValue={values.fontSize}
        maxRange={TAILWIND_SETS['fontSize'].length}
        minRange={0}
        on:input={sizeHandler}
      />
    </div>
    <div>
      <Label>Style</Label>
      <InputToggleMultiple
        value={values.styleValues}
        options={styleOptions}
        on:change={styleHandler}
      />
    </div>
    <div>
      <Label>Line Height</Label>
      <InputRange
        initialValue={values.lineHeight}
        maxRange={TAILWIND_SETS['lineHeight'].length}
        minRange={0}
        on:input={lineHandler}
      />
    </div>
    <div>
      <Label>Font Weight</Label>
      <InputSelect
        options={weightOptions}
        value={values.weight}
        on:change={weightHandler}
        leftIcon="fluent:text-bold-24-regular"
      />
    </div>
    <div>
      <Label>Color</Label>
      <InputColor
        value={values.color}
        on:input={colorHandler}
      />
    </div>
  </div>
</ViewSection>

<style lang="scss">
  .layout {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 15px;
    row-gap: 10px;
  }
</style>