<script lang="ts">
  import createUser from "../../firebase/createUser";
  import signInUser from "../../firebase/signInUser";
  import resetPassword from "../../firebase/resetPassword";
  import { UserInputField } from "../atoms";

  export let type: 'sign-in' | 'sign-up' | 'forgot-password' = 'sign-up';
  
  let heading: string;
  let subHeading: string;
  let statusMessage: string = '';

  let emailAddress: string = null;
  let password: string = null;

  let formElement: HTMLFormElement;

  $: {
    statusMessage = ''; // clear status message
    if( type === 'sign-in' ) {
      heading = 'Sign in to your account';
      subHeading = 'Get back to Formulating';
    } else if( type === 'sign-up' ) {
      heading = 'Start Formulating';
      subHeading = 'Sign up to access all features and save your progress';
    } else if( type === 'forgot-password' ) {
      heading = 'Forgot your password?';
      subHeading = 'Provide your account email to receive the reset link.';
    }
  }

  const submitHandler = async (using: 'email' | 'google' | 'apple' | 'github') => {
    statusMessage = '';
    // validate form
    const areInputsValid = Array.from(formElement.querySelectorAll('input')).every(input => input.checkValidity());
    if (!areInputsValid) {
      console.error("Form validation failed. Please check your inputs.");
      return false;
    }
    // create user
    if( type === 'sign-up' ) {
      statusMessage = await createUser(using, emailAddress, password);
    } else if( type === 'sign-in' ) {
      statusMessage = await signInUser(using, emailAddress, password);
    } else if( type === 'forgot-password' ) {
      statusMessage = await resetPassword(emailAddress);
    }
  }

  const forgotPasswordHandler = () => {
    type = 'forgot-password';
  }
</script>

<div>
  <img src="/images/business/formulator-icon.jpg" class="logo" alt="Formulator Logo"/>
  <h2>{heading}</h2>
  <h4>{subHeading}</h4>
  <form bind:this={formElement} on:submit={(event) => event.preventDefault()}>
    <UserInputField
      label="Email Address"
      type="email"
      icon="eva:email-outline"
      required
      bind:value={emailAddress}
      style={'margin-bottom: 20px;'}
      placeholder="email@example.com"
    />
    {#if type !== 'forgot-password'}
      <UserInputField
        label="Password"
        rightLabel={type === 'sign-in' ? "Forgot password?" : null}
        rightLabelClick={forgotPasswordHandler}
        icon="eva:lock-outline"
        type="password"
        required
        bind:value={password}
        style={'margin-bottom: 20px;'}
        placeholder="(6+ characters)"
      />
    {/if}
    {#if statusMessage}
      <p class="status-message">{statusMessage}</p>
    {/if}
    {#if type === 'sign-up'}
      <button type="button" class="submit" on:click={() => submitHandler('email')}>Create Account</button>
    {:else if type === 'sign-in'}
      <button type="button" class="submit" on:click={() => submitHandler('email')}>Sign In</button>
    {:else if type === 'forgot-password'}
      <button type="button" class="submit" on:click={() => submitHandler('email')}>Send Reset Link</button>
    {/if}
  </form>
  <!--
  {#if type !== 'forgot-password'}
    <div class="divider"></div>
    <div class="third-party">
      <div><button class="third-party-btn" on:click={() => submitHandler('apple')}><Icon icon="logos:apple"/> Apple</button></div>
      <div><button class="third-party-btn" on:click={() => submitHandler('github')}><Icon icon="logos:github-icon"/> Github</button></div>
      <div><button class="third-party-btn" on:click={() => submitHandler('google')}><Icon icon="logos:google-icon"/> Google</button></div>
    </div>
  {/if}-->
</div>

<style lang="scss">
  .logo {
    width: 50px;
    padding-bottom: 20px;
    margin: 0 auto;
    display: block;
  }

  h2 {
    margin: 0 0 15px 0;
    font-size: 1.65em;
    text-align: center;
  }

  h4 {
    margin: 0;
    font-weight: 500;
    text-align: center;
  }

  form {
    margin-top: 45px;
    color: #666;
  }

  .submit {
    @include userButton;
  }

  .divider {
    width: 100%;
    height: 2px;
    background: #F7F7F9;
    color: #666;
    margin: 40px 0px;
    text-align: center;
    display: flex;
    align-items: center;

    &:before {
      content: "OR";
      margin: 0 auto;
      display: block;
      background-color: $lightest;
      padding: 0 10px;
      font-weight: 700;
      font-size: 0.85em;
    }
  }

  .third-party {
    display: flex;
    gap: 10px;

    > div {
      flex: 1;
    }
  }

  .third-party-btn {
    @include clear-default-button-styling;
    text-align: center;
    width: 100%;
    border: solid 1px #e5e5e5;
    padding: 15px 10px;
    border-radius: $border-radius-2;
    text-transform: uppercase;
    font-weight: 500;
    line-height: 1;

    &:hover {
      border-color: var(--ui-theme);
    }
  }

  .status-message {
    font-size: 0.85em;
    color: $ui-theme-color-backtrack;
  }
</style>