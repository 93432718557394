<script lang="ts">
  import Icon from "@iconify/svelte";

  export let dialog: string;
  export let icon: {icon: string, size: number};
</script>

<div class="dialog">
  <div class="icon">
    <Icon icon={icon.icon} width={`${icon.size}px`}/>
  </div>
  <div>
    <p>{dialog}</p>
  </div>
</div>

<style lang="scss">
  .dialog {
    border: dashed 2px $background-color-index-4;
    box-sizing: border-box;
    padding: 20px;
    border-radius: $border-radius-2;
    display: flex;
    gap: 20px;
    align-items: center;
  }

  .icon {
    color: var(--ui-theme);
  }

  p {
    margin: 0;
    line-height: 1.40;
  }
</style>