<script lang="ts">
  import Icon, { type IconifyIcon } from "@iconify/svelte";
  import { OptionsMenu } from "../atoms";
  import { interfaceOverlay } from "../../stores/uiStore";
  import { createEventDispatcher } from "svelte";

  export let value: string = '';
  export let options: {label: string, value: string}[];
  export let leftIcon: IconifyIcon | undefined = undefined;
  export let disabled: boolean = false;

  let selectInputElement;

  const dispatch = createEventDispatcher();

  const valueChangeHandler = (newValue) => {
    dispatch('change', newValue);
  }

  const showOptionsMenu = () => {
    if( disabled ) return;
    const rect = selectInputElement.getBoundingClientRect();
    interfaceOverlay.set({
      component: OptionsMenu,
      props: {
        options: options,
        value: value,
        onClick: valueChangeHandler
      },
      containerStyle: `top:${rect.bottom}px; left:${rect.left}px; width:${rect.width}px; --ui-theme: #fa5b5a` // TODO: color should match the view location
    })
  }
</script>

<div class="input-select" class:using-left-icon={leftIcon} bind:this={selectInputElement}>
  <div>
    <div class="select-trigger" on:click={showOptionsMenu}>
      {#if leftIcon}
        <span class="left-icon"><Icon icon={leftIcon} width="18px"/></span>
      {/if}
      <span class="arrow-icon">
        <Icon icon="gridicons:dropdown" width="22px"/>
      </span>
      <select disabled={disabled}>
        {#each options as option}
        {#if option.hasOwnProperty('options')}
          <optgroup label={option.label}>
            {#each option.options as groupOption}
              {#if groupOption.value !== null}
                <option value={groupOption.value} selected={groupOption.value === value}>{groupOption.label}</option>
              {/if}
            {/each}
          </optgroup>
        {:else}
          <option value={option.value} selected={option.value === value}>{option.label}</option>
        {/if}
        {/each}
      </select>
    </div>
  </div>
</div>

<style lang="scss">
  .input-select {
    position: relative;

    &.using-left-icon {
      select {
        padding-left: 27px;
      }
    }
  }

  .select-trigger {
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      .arrow-icon {
        color: var(--ui-theme);
      }
    }
  }

  select {
    @include input-styling;
    pointer-events: none;
    padding-right: 20px;

    &:focus {
      outline: solid 1px var(--ui-theme);
      outline-offset: -1px;
    }
  }

  .arrow-icon {
    position: absolute;
    z-index: 1;
    right: 0px;
    margin: auto;
    font-size: 0;
    color: $background-color-index-4-offset-2;
  }

  .left-icon {
    position: absolute;
    left: 7px;
    font-size: 0;
    color: $background-color-index-4-offset-2;
  }
</style>