import './app.scss';
import App from './App.svelte';
import * as Sentry from "@sentry/svelte";
import Hotjar from '@hotjar/browser';

// initialize hotjar
const siteId = 3813937;
const hotjarVersion = 6;
Hotjar.init(siteId, hotjarVersion);

// initialize sentry
Sentry.init({
  dsn: "https://302673169d78378bb8f4a104f56b3d06@o4506531336486912.ingest.sentry.io/4506531340419072",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["https://formulator.app", "https://beta.formulator.app", "https://beta.formulator.app/template/index.html"],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const app = new App({
  target: document.getElementById("app"),
});

export default app;
