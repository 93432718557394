<script lang="ts">
  import { LibItem, SectionLabel, ViewSection } from "../atoms";
  import components from "../../fixtures/components-lib.json";

  export let collectionId: string;

  const collection = components.components.find(c => c.id === collectionId);
</script>

<SectionLabel label={collection.name} sticky={true}/>
<ViewSection>
  <div class="layout">
    {#each collection.includes as component}
      <LibItem
        label={component.name}
        item="element"
        mode="component"
        image="/images/library/{collectionId}/{component.thumbnail}"
        libraryComponent={{collectionId: collectionId, componentId: component.id}}
      />
    {/each}
  </div>
</ViewSection>

<style lang="scss">
.layout {
  @include grid-columns-2;
  margin-bottom: 25px;
}
</style>