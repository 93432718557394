<script lang="ts">
  import { UploadFile } from "../atoms";
  import { ComponentLibItem, Stepper } from "../molecules";
  import components from "../../fixtures/components-lib.json";
  import { AuthForm } from ".";
  import { authUser } from "../../stores/userStore";
  import Icon from "@iconify/svelte";
  import createProjectWithDataSource from "../../helpers/createProjectWithDataSource";
  import { scaffold } from "../../stores/scaffoldStore";
  import { interfaceOverlay } from "../../stores/uiStore";

  const steps = [
    {label: 'Upload your resume. Instantly get a personal portfolio', complete: false},
    {label: 'Choose a Template', complete: false},
    {label: 'Create Account', complete: false},
    {label: 'Creating your personal portfolio...', complete: false}
  ]

  let activeStepperIndex = 0;
  let userResumeFile: File | null = null;
  let selectedTemplateId: string;
  const componentsData = components.components;

  $: {
    if( $authUser ) {
      steps[2].complete = true;
      activeStepperIndex = 3;
    } else {
      steps[2].complete = false;
    }
  }

  $: {
    if( activeStepperIndex == 3 && userResumeFile !== null) {
      createProjectWithDataSource(selectedTemplateId, userResumeFile);
    }
  }

  const changeStep = (event) => {
    if( activeStepperIndex === 3 ) return; 
    activeStepperIndex = event.detail;
  }

  const resumeUploadHandler = (event) => {
    const file = event.target.files[0];
    if( file ) {
      userResumeFile = file;
      activeStepperIndex = 1;
      steps[0].complete = true;
    } else {
      userResumeFile = null;
      steps[0].complete = false;
    }
  }

  const templateClickHandler = (id:string) => {
    selectedTemplateId = id;
    activeStepperIndex = 2;
    steps[1].complete = true;
  }

  // project has been created, hide the modal
  $: {
    if( $scaffold ) {
      interfaceOverlay.set(null);
    }
  }
</script>

<div>
  <section class="stepper">
    <Stepper steps={steps} activeIndex={activeStepperIndex} on:click={changeStep}/>
  </section>
  {#if activeStepperIndex === 0}
    <section class="upload-resume">
      <UploadFile on:change={resumeUploadHandler} accept="pdf"/>
    </section>
  {:else if activeStepperIndex === 1}
    <section class="templates">
      <h1>Choose a Template</h1>
      <div class="templates-layout">
        {#each componentsData as component}
          <ComponentLibItem
            id={component.id}
            name={component.name}
            tags={component.tags}
            premium={component.premium}
            thumbnail={component.images.thumbnail}
            previewURL={component.previewURL}
            on:click={() => templateClickHandler(component.id)}
          />
        {/each}
      </div>
    </section>
  {:else if activeStepperIndex === 2}
    <section class="sign-up">
      <AuthForm/>
    </section>
  {:else if activeStepperIndex === 3}
    <section class="generating">
      <h1>{steps[activeStepperIndex].label}</h1>
      <Icon icon="svg-spinners:blocks-shuffle-3" width="50" color="#00bbff"/>
    </section>
  {/if}
</div>

<style lang="scss">
  section {
    margin: 30px;
  }

  .stepper {
    padding: 20px 0px;
    margin: 0px;
    border-bottom: solid 1px #e5e5e5;
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: $lightest;
  }

  .upload-resume,
  .templates h1,
  .generating {
    text-align: center;
    margin-top: 50px;
  }

  .upload-resume {
    @include theme-light;
  }

  .templates-layout {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }

  .templates h1 {
    margin-top: 0;
    margin-bottom: 30px;
  }
</style>