import { sendPasswordResetEmail } from '@firebase/auth';

import { auth } from './firebase';

const resetPassword = async (email: string): Promise<string> => {
  try {
    await sendPasswordResetEmail(auth, email);
    return 'Reset email sent. Click the link in the email to reset your password.';
  } catch (error) {
    console.error(error);
    return `Error: ${error.message}`;
  }
}

export default resetPassword;