<script lang="ts">
  export let selected: boolean = false;
  export let prioritized: boolean = false;
</script>

<button class:selected={selected} on:click on:mouseenter on:mouseleave>
  {#if prioritized}★&nbsp;{/if}
  <slot/>
  <span>
    <svg width="10" height="30" viewBox="0 0 7 28" style="--path-fill: #212121;" aria-hidden="true" focusable="false">
      <path fill="transparent" d="M.5 0l6 14-6 14H7V0z"></path>
      <path fill="#4f4f4f" d="M1 0H0l6 14-6 14h1l6-14z"></path>
    </svg>
  </span>
</button>

<style lang="scss">
  button {
    @include clear-default-button-styling;
    font-size: 1em;
    height: inherit;
    padding-left: 5px;
    padding-right: 5px;
    cursor: initial;
    display: flex;
    gap: 5px;
    outline: none;
    align-items: center;

    &.selected, &:hover {
      color: var(--ui-theme);
    }
  }

  span {
    font-size: 0;
  }
</style>