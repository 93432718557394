import { signInWithEmailAndPassword } from 'firebase/auth';

import { authUser } from '../stores/userStore';
import { auth } from './firebase';

const signInUser = async (using: 'email' | 'google' | 'github' | 'apple', email: string, password: string): Promise<string> => {
  try {
    switch(using) {
      case 'email':
        // TODO: Add password/email verification
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
        authUser.set(user);
        return 'Successfully signed in.';

      case 'google':
        // Implement Google authentication
        break;

      case 'github':
        // Implement GitHub authentication
        break;

      case 'apple':
        // Implement Apple authentication
        break;

      default:
        return 'Invalid authentication method';
    }
  } catch (error) {
    console.error(error);
    return `Error: ${error.message}`;
  }
}

export default signInUser;
