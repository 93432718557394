import { get } from 'svelte/store';

import {
  collection,
  getDocs,
  orderBy,
  query,
  where,
} from '@firebase/firestore';

import {
  firestore
} from './firebase'

import { authUser } from '../stores/userStore';

async function getUserComponentsList(userId) {
  const user = get(authUser);

  if (!user) {
    throw new Error("User is not authenticated");
  }

  const componentCollection = collection(firestore, "user-components");
  const q = query(componentCollection, where("userId", "==", userId), orderBy("timestamp", "desc"));

  const querySnapshot = await getDocs(q);
  const components = [];

  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    components.push({ id: doc.id, ...doc.data() });
  });

  return components;
}

export default getUserComponentsList;
