<script lang="ts">
  import { canvasDOMMutationCounter, canvasDOM, viewWidth, activeNavItem, draggingInProgress } from "../../stores/uiStore";
  import { PersistentMark } from "../atoms";
  import { selectedElement, selectedElementPrioritized, selectedElementScaffold } from "../../stores/scaffoldStore";
  import nav, { NAV_ITEM } from "../../utils/_nav-items";
  import { BAN_NESTING } from "../../scaffold/ScaffoldRegulator";
  import ScaffoldCoordinator from "../../scaffold/ScaffoldCoordinator";
  import { SCAFFOLD_ELEMENTS } from "../../scaffold/ScaffoldElements";
  import getLibraryComponentScaffold from "../../firebase/getLibraryComponentScaffold";

  let markers = [];

  const clickHandler = (elementId, elementName) => {
    selectedElement.set({id: elementId, element: elementName});
    viewWidth.set(nav[NAV_ITEM.properties].viewWidth);
    activeNavItem.set(NAV_ITEM.properties);
    selectedElementPrioritized.set(false);
  }

  const dblClickHandler = (elementId) => {
    if( $selectedElementScaffold.selectedScaffold.hasOwnProperty('nested') && $selectedElementScaffold.selectedScaffold.nested[0] && $selectedElementScaffold.selectedScaffold.nested[0].hasOwnProperty('text') ) {
      $canvasDOM.querySelector(`[data-id="${elementId}"]`).focus();
    }
  }

  const dragStartHandler = (event, elementId, elementName) => {
    event.dataTransfer.setData('dragging-id', event.target.getAttribute('data-id'));
    event.dataTransfer.setDragImage($canvasDOM.querySelector(`[data-id="${elementId}"]`), 0, 0);
    draggingInProgress.set(true);
  }

  const onElementDrop = (elementName: string, nestId: string, droppedAbove: string | false) => {
    ScaffoldCoordinator.nesting(SCAFFOLD_ELEMENTS[elementName].scaffold, nestId, droppedAbove);
  }

  const onComponentDrop = async (libItemIds: {collectionId: string, componentId: string}, nestId, droppedAbove: string | false) => {
    const scaffold = await getLibraryComponentScaffold(libItemIds.collectionId, libItemIds.componentId);
    ScaffoldCoordinator.nesting(scaffold, nestId, droppedAbove);
  }

  const elementMoveHandler = ({element, elementId, nestId, droppedAboveParentId}) => {
    const droppedElementId: string = event.dataTransfer.getData('dragging-id');
    ScaffoldCoordinator.moveElement(element, droppedElementId, nestId, droppedAboveParentId);
  }

  const dragEndHandler = () => {
    draggingInProgress.set(false);
  }

  $: {
    if( $canvasDOMMutationCounter ) { // counter is used to redraw markers when dom changes occur in the canvas
      markers = Array.from($canvasDOM.querySelectorAll('[data-element]')).map(element => {
        const dimensions = element.getBoundingClientRect();
        return {
          id: element.getAttribute('data-id'),
          element: element.getAttribute('data-element'),
          y: dimensions.y,
          x: dimensions.x,
          width: dimensions.width,
          height: dimensions.height,
          allowsNesting: !BAN_NESTING.includes(element.tagName.toLowerCase())
        }
      });
    }
  }
</script>

{#each markers as marker}
  <PersistentMark
    id={marker.id}
    element={marker.element}
    top={marker.y}
    left={marker.x}
    width={marker.width} 
    height={marker.height}
    allowsNesting={marker.allowsNesting}
    onElementDrop={onElementDrop}
    onComponentDrop={onComponentDrop}
    on:click={() => clickHandler(marker.id, marker.element)}
    on:dblclick={() => dblClickHandler(marker.id, marker.element)}
    on:dragstart={() => dragStartHandler(event, marker.id, marker.element)}
    on:dragend={() => dragEndHandler()}
    on:elementmove={(event) => elementMoveHandler(event.detail)}
  />
{/each}

<style lang="scss">

</style>