<script lang="ts">
  import { quintInOut } from "svelte/easing";
  import { BlurBackgroundFilter } from "../atoms";
  import { fly } from "svelte/transition";
  import { ResumeOnboardingFlow } from "../organisms";
</script>

<BlurBackgroundFilter>
  <main class="theme-light" transition:fly={{duration: 500, x: 0, y: -50, easing: quintInOut}}>
    <div class="banner">
      <h1 class="dark-highlight">Stand Out With A Personal Website That Complements Your Resume</h1>
      <p class="dark-highlight">Showcase Your Skills, Projects, and Professional Journey Through Your Personal Website. No Coding Required — Just Upload Your Resume and Let Us Do the Rest!</p>
      <img src="/images/business/resume-onboarding-banner.png" alt="Formulator Resume Onboarding"/>
    </div>
    <div class="flow">
      <ResumeOnboardingFlow/>
    </div>
  </main>
</BlurBackgroundFilter>

<style lang="scss">
  main {
    @include overlaying-content-container;
    --ui-theme: #{$ui-theme-color-output};
    transition: width .30s;
    overflow-y: scroll;
    max-width: 1700px;
    max-height: 900px;

    &.theme-light {
      @include theme-light;
    }
    
    display: grid;
    grid-template-columns: 1fr 600px;
  }

  .banner {
    height: inherit;
    max-height: 900px;
    position: sticky;
    top: 0;
    padding: 30px;
    box-sizing: border-box;
    background-size: cover;
    background-position: center;
    color: $lightest;
    font-size: 1em;
    background: linear-gradient(88.7deg, rgb(205, 33, 42) 10.6%, rgb(236, 95, 5) 90%);
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.4);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;

    h1 {
      font-size: 2.5em;
      line-height: 1.5;
      margin: 0;
    }

    p {
      font-size: 1em;
      line-height: 1.6;
      max-width: 400px;
    }

    img {
      max-width: 100%;
      margin: 0 auto;
      display: block;
    }
  }

  .dark-highlight {
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px 20px;
    border-radius: $border-radius-2;
    box-sizing: border-box;
  }

  @media screen and (max-width: 1250px) {
    .banner {
      font-size: 0.65em;
    }
  }
</style>