export const removeConflictingClasses = (
  originalClasses: string[], 
  classesToRemove: RegExp | string[]
): string[] => {
  if (classesToRemove instanceof RegExp) {
    return originalClasses.filter(className => !classesToRemove.test(className));
  } else {
    return originalClasses.filter(className => !classesToRemove.includes(className));
  }
}


export default removeConflictingClasses;