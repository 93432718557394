<script lang="ts">
  import ViewTitle from "../atoms/ViewTitle.svelte";
  import Data from '../templates/Data.svelte';

  let activeDataView = 'resume';

  const dataOptions = [
    {label: 'Resume', value: 'resume'},
    {label: 'Restaurant Menu', value: 'restaurant-menu'},
    {label: 'Spreadsheet', value: 'spreadsheet'},
  ];
</script>

<ViewTitle title="Data Source"/>
<!--<ViewSection>
  <InputToggle initialValue={activeDataView} options={dataOptions} on:change={(event) => activeDataView = event.detail}/>
</ViewSection>-->
<Data activeDataView={activeDataView}/>

<style lang="scss">

</style>