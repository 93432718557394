<script lang="ts">
  import { createEventDispatcher } from "svelte";

  export let selected;

  const dispatch = createEventDispatcher();

  const clickHandler = (selection) => {
    dispatch('select', selection);
  }
</script>

<div class="spacing-selector">
    <div class="spacing-toggle" class:selected={selected.center} on:click={() => clickHandler('center')}></div>
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 105 105">
        <defs>
            <clipPath id="clip-Artboard_1">
            <rect width="100%" height="100%"/>
            </clipPath>
        </defs>
        <g id="Artboard_1" data-name="Artboard – 1" clip-path="url(#clip-Artboard_1)">
            <path class="top-margin" class:selected={selected.marginTop} on:click={() => clickHandler('marginTop')} data-name="top margin" d="M.177-.037l103.614.016L86.775,16.994,17.2,16.978Z" transform="translate(0.5 0.022)"/>
            <path class="bottom-margin" class:selected={selected.marginBottom} on:click={() => clickHandler('marginBottom')} data-name="bottom margin" d="M.177,16.994l103.614-.016L86.775-.037,17.2-.022Z" transform="translate(0.5 88.022)"/>
            <path class="left-margin" class:selected={selected.marginLeft} on:click={() => clickHandler('marginLeft')} data-name="left margin" d="M-.121-.048,103.457-.03l-17,17-69.581-.007Z" transform="translate(0.037 104.177) rotate(-90)"/>
            <path class="right-margin" class:selected={selected.marginRight} on:click={() => clickHandler('marginRight')} data-name="right margin" d="M0,17.016,103.578,17l-17-17L17,.007Z" transform="translate(87.989 104.298) rotate(-90)"/>
            <path class="top-padding" class:selected={selected.paddingTop} on:click={() => clickHandler('paddingTop')} data-name="top padding" d="M-.105-.022l65.6,0L49.145,16.32l-32.9.008Z" transform="translate(19.808 19.022)"/>
            <path class="bottom-padding" class:selected={selected.paddingBottom} on:click={() => clickHandler('paddingBottom')} data-name="bottom padding" d="M-.105,16.328l65.6,0L49.145-.014l-32.9-.008Z" transform="translate(19.808 69.682)"/>
            <path class="left-padding" class:selected={selected.paddingLeft} on:click={() => clickHandler('paddingLeft')} data-name="left padding" d="M-.86-.037H64.72L48.381,16.293H15.462Z" transform="translate(19.037 84.43) rotate(-90)"/>
            <path class="right-padding" class:selected={selected.paddingRight} on:click={() => clickHandler('paddingRight')} data-name="right padding" d="M-.02,16.3H65.61L49.268-.045,16.339-.04Z" transform="translate(69.7 85.31) rotate(-90)"/>
        </g>
    </svg>
</div>

<style lang="scss">
  .spacing-selector {
    width: 101px;
    height: 101px;
    position: relative;
    font-size: 0;

    svg {
      border-radius: $border-radius-2;

      path {
        fill: $background-color-index-4;
        stroke: $background-color-index-4-offset;
        stroke-linejoin: round;

        &:hover {
          fill: $background-color-index-4-offset;
        }

        &.selected {
          fill: var(--ui-theme);
        }
      }
    }

    .spacing-toggle {
      width: 34px;
      height: 34px;
      position: absolute;
      top: 0px;
      bottom: 0px;
      left: 0px;
      right: 0px;
      margin: auto;

      &:before {
        content: "";
        position: absolute;
        margin: auto;
        box-sizing: border-box;
        width: 15px;
        height: 15px;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        border-radius: 100%;
        box-sizing: border-box;
        border: solid 3px #FFF;
        background-color: var(--ui-base-background);
      }

      &.selected {
        &:before {
          border-radius: 0;
        }
      }

      &:hover {
        &:before {
          border-color: var(--ui-theme-color-components);
        }
      }

      &.all-selected-padding {
        &:before {
          border-radius: 15px;
        }
      }
    }
  }
</style>