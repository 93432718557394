import { get } from 'svelte/store';

import ScaffoldCoordinator from '../scaffold/ScaffoldCoordinator';
import {
  canvasDOMMutationCounter,
  canvasIframe,
} from '../stores/uiStore';

const canvasMessageHandler = (event) => {
  if( event.origin !== location.origin ) {
    console.debug('event origin does not match location origin', event.origin, location.origin)
    return
  }
  
  if( event.data === 'document-ready' ) {
    canvasIframe.set(event.source.window.frameElement);
  }
  
  if( event.data === 'mutation' ) {
    // handle DOM mutations
    canvasDOMMutationCounter.set(get(canvasDOMMutationCounter) + 1);
  } else if( typeof event.data === 'object' ) {
    // handle text editing
    if( event.data.event === 'text-editing' ) {
      ScaffoldCoordinator.textEditing(event.data.data.id, event.data.data.text);
    }
  }
}

export default canvasMessageHandler;
