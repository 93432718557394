<script lang="ts">
  import { ComponentsIntroBanner, ViewSection } from "../atoms";
  import { ComponentLibItem } from "../molecules";
  import components from "../../fixtures/components-lib.json";
  import { interfaceOverlay } from "../../stores/uiStore";
  import ComponentPreview from "../templates/ComponentPreview.svelte";

  const componentsData = components.components;

  const clickHandler = (id: string, name: string, tags: string[], description: string, images) => {
    interfaceOverlay.set({
      component: ComponentPreview,
      props: {
        id: id,
        name: name,
        tags: tags,
        description: description,
        banner: images.banner,
        showcase: images.showcase
      }
    });
  }
</script>

<!--<div class="sticky">
  <ComponentsFilter/>
</div>-->
<ComponentsIntroBanner heading={componentsData.length}/>
<ViewSection>
  <div class="components-layout">
    {#each componentsData as component}
      <ComponentLibItem
        id={component.id}
        name={component.name}
        tags={component.tags}
        premium={component.premium}
        thumbnail={component.images.thumbnail}
        previewURL={component.previewURL}
        on:click={() => clickHandler(component.id, component.name, component.tags, component.description, component.images)}
      />
    {/each}
  </div>
</ViewSection>

<style lang="scss">
  .components-layout {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 15px;
    row-gap: 20px;
  }

  .sticky {
    z-index: 10;
    position: sticky;
    top: 0px;
    margin: -15px 0 0 0;
    padding: calc($view-content-edge-spacing - 5px) $view-content-edge-spacing;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: rgb(17 17 17 / 65%);
  }
</style>