<script lang="ts">
  import getClassFromSet from "../../helpers/getClassFromSet";
  import ScaffoldCoordinator from "../../scaffold/ScaffoldCoordinator";
  import { selectedElementScaffold } from "../../stores/scaffoldStore";
  import TAILWIND_SETS from "../../utils/_tailwind-sets";
  import { listOptions, listPositionOptions } from "../../utils/_tool-options";
  import { Label, ViewSection } from "../atoms";
  import { InputToggle } from "../molecules";

  let type, pos;

  $: {
    const classList = $selectedElementScaffold.selectedScaffold?.attributes?.class ?? '';
    type = getClassFromSet(classList, TAILWIND_SETS['list'])
    pos = getClassFromSet(classList, TAILWIND_SETS['listPosition'])
  }

  const typeHandler = (event) => {
    if( event.detail === type ) {
      ScaffoldCoordinator.removeClass(type);
    } else {
      ScaffoldCoordinator.addTailwindClass(event.detail);
    }
  }

  const posHandler = (event) => {
    if( event.detail === pos ) {
      ScaffoldCoordinator.removeClass(pos);
    } else {
      ScaffoldCoordinator.addTailwindClass(event.detail);
    }
  }
</script>

<ViewSection>
  <div class="layout">
    <div>
      <Label>Type</Label>
      <InputToggle
        initialValue={type}
        options={listOptions}
        on:change={typeHandler}
        disableTransition
      />
    </div>
    <div>
      <Label>Position</Label>
      <InputToggle
        initialValue={pos}
        options={listPositionOptions}
        on:change={posHandler}
      />
    </div>
  </div>
</ViewSection>

<style lang="scss">
  .layout {
    display: grid;
    grid-template-columns: 1fr 120px;
    grid-gap: 15px;
  }
</style>