<script lang="ts">
  import Icon from "@iconify/svelte";
  import getParentTree from "../../helpers/getElementParentTree";
  import { selectedElement, selectedElementPrioritized } from "../../stores/scaffoldStore";
  import { activeNavItem, canvasDOM, viewWidth, showMarker } from "../../stores/uiStore";
  import nav, { NAV_ITEM } from "../../utils/_nav-items";
  import { DomNode } from "../atoms";

  $: elements = [{element: 'body', id: '-1'}];

  $: {
    if( $canvasDOM && $selectedElement.id !== null ) {
      const parents = getParentTree($canvasDOM.querySelector(`[data-id="${$selectedElement.id}"]`)).reverse();
      elements = parents.map(el => {
        return {element: el.getAttribute('data-element'), id: el.getAttribute('data-id')}
      });
    }
  }

  const selectElement = (id: string, element: string) => {
    if( $selectedElement.id === id ) {
      selectedElementPrioritized.set(!$selectedElementPrioritized);
    } else {
      selectedElement.set({id, element});
      selectedElementPrioritized.set(true);
    }
  }

  const DOMTreeClickHandler = () => {
    if( $activeNavItem === NAV_ITEM.DOMNavigator ) {
      activeNavItem.set(null);
      viewWidth.set(null)
      return;
    }
    activeNavItem.set(NAV_ITEM.DOMNavigator);
    viewWidth.set(nav[NAV_ITEM.DOMNavigator].viewWidth);
  }
</script>

<nav data-active={$activeNavItem}>
  <button class="domtree" class:selected={$activeNavItem === NAV_ITEM.DOMNavigator} on:click={DOMTreeClickHandler}>
    <Icon icon="ri:node-tree" width="16"/>
  </button>
  {#each elements as element}
    <DomNode 
      selected={$selectedElement.id === element.id}
      on:click={() => selectElement(element.id, element.element)}
      on:mouseenter={() => showMarker.set(element.id)}
      on:mouseleave={() => showMarker.set(null)}
    >
      {element.element}
    </DomNode>
  {/each}
  {#if $selectedElement.id}
    <DomNode 
      selected={true}
      prioritized={$selectedElementPrioritized}
      on:click={() => selectElement($selectedElement.id, $selectedElement.element)}
      on:mouseenter={() => showMarker.set($selectedElement.id)}
      on:mouseleave={() => showMarker.set(null)}
    >
      {$selectedElement.element}
    </DomNode>
  {/if}
</nav>

<style lang="scss">
  nav {
    height: 30px;
    width: calc(100% + 2px);
    margin-left: -1px;
    background-color: #292929; // TODO: store as variable
    box-sizing: border-box;
    border-bottom: 0;
    font-size: .65rem;
    overflow: hidden;
    display: flex;
    white-space: nowrap;
    word-break: normal;
    border: solid 1px #4f4f4f;
    border-bottom: 0;
    --ui-theme: #00cea8; // default theme
  }

  .domtree {
    @include clear-default-button-styling;
    padding: 0 10px;
    font-size: 0;
    margin-right: 7px;
    border-right: solid 1px #4f4f4f;

    &.selected {
      color: $ui-theme-color-components;
    }
  }
</style>