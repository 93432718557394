import { get } from "svelte/store";
import { v4 as uuidv4 } from 'uuid';
import uploadUserComponent from "../firebase/uploadUserComponent";
import ScaffoldCoordinator from "../scaffold/ScaffoldCoordinator";
import { newComponent } from "../stores/uiStore";
import { authUser } from "../stores/userStore";
import scaffoldJSON from '../scaffold/ScaffoldInitial.json';

const createProject = async (projectName: string, method, presets: null | {scaffold: object, dataSourceId: string | null, name: string, method} = null, dataSource: object | null = null) => {
  if( !presets && projectName.trim() === '' ) {
    alert('Provide a project name')
    return false;
  }
  let _S_ = scaffoldJSON;
  let name = projectName;
  //const dataSourceId = presets && presets.hasOwnProperty('dataSourceId') ? presets.dataSourceId : null;
  // if starting from featured template, override with presets
  if( presets !== null ) {
    _S_ = presets.scaffold;
    name = presets.name;
    method = presets.method;
  }
  // save user's component to storage
  const componentId = uuidv4();
  let docId;
  
  if( get(authUser) ) {
    try {
      docId = (await uploadUserComponent(get(authUser).uid, componentId, name, JSON.stringify(_S_), dataSource)).docId;
    } catch(error) {
      alert(error);
      console.error(error);
    }
  }
  
  // set the scaffold data source
  // TODO: fetch data source from firestore
  ScaffoldCoordinator.setScaffoldWithDataSource(_S_, dataSource);

  newComponent.set({
    id: componentId,
    docId: docId,
    name: name,
    method: method,
    dataSource: dataSource,
    title: "",
    description: ""
  });
}

export default createProject;