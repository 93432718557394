<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import { selectedElement, selectedElementPrioritized } from "../../stores/scaffoldStore";
  import { canvasDOM, generating, showMarker } from "../../stores/uiStore";

  export let top: number, left: number, width: number, height: number;
  export let id: string;
  export let onElementDrop: Function;
  export let onComponentDrop: Function;
  export let element: string;
  export let allowsNesting: boolean;

  let draggableOver = false;
  let draggableOverAbove = false;
  let dragging = false;

  const dispatch = createEventDispatcher();

  $: isSelected = $selectedElement.id === id;
  $: prioritize = isSelected && $selectedElementPrioritized;

  const dropped = (event, nestId: string, droppedAbove: boolean = false) => {
    const elementName = event.dataTransfer.getData('scaffoldElement');
    const componentScaffold = event.dataTransfer.getData('scaffoldComponent');
    const elementId = event.dataTransfer.getData('dragging-id');
    let droppedAboveParentId: string | false = false;
    if( droppedAbove ) {
      const parentElement = $canvasDOM.querySelector(`[data-id="${nestId}"]`).parentElement;
      droppedAboveParentId = parentElement.getAttribute('data-id');
    }
    if( elementName || componentScaffold ) {
      if( elementName ) {
        onElementDrop(elementName, nestId, droppedAboveParentId);
      }
      if( componentScaffold ) {
        onComponentDrop(JSON.parse(componentScaffold), nestId, droppedAboveParentId);
      }
    } else if( elementId ) {
      dispatch('elementmove', {element, elementId, nestId, droppedAboveParentId});
    }
    draggableOver = false;
    draggableOverAbove = false;
    dragging = false;
  }
</script>

<div
  class="persistent-marker"
  class:dragging={dragging}
  class:prioritize={prioritize}
  class:selected={isSelected}
  class:draggable-over={draggableOver}
  class:allows-nesting={allowsNesting === true}
  class:generating={$generating === id}
  class:show-marker={$showMarker === id}
  role="button"
  data-element={!prioritize ? element : `★ ${element}`}
  data-id={id}
  style:top="{top}px"
  style:left="{left}px"
  style:width="{width}px"
  style:height="{height}px"
  draggable={true}
  on:click
  on:dblclick
  on:dragstart={(event) => {dragging = true; dispatch('dragstart', event)}}
  on:dragend={(event) => {dragging = false; dispatch('dragend', event)}}
  on:dragenter={allowsNesting ? () => draggableOver = true : null}
  on:dragleave={allowsNesting ? () => draggableOver = false : () => draggableOverAbove = false}
  on:drop={allowsNesting ? (event) => dropped(event, id) : null}
  on:dragover={(event) => event.preventDefault()}
>
  {#if !allowsNesting}
    <div 
      class="droppable-above"
      class:draggable-over-above={draggableOverAbove === true}
      on:dragenter={() => draggableOverAbove = true}
      on:dragleave={() => draggableOverAbove = false}
      on:drop={(event) => dropped(event, id, true)}
      on:dragover={(event) => event.preventDefault()}
    />
  {/if}
</div>

<style lang="scss">
  div {
    position: absolute;

    &.selected:not(.generating) {
      @include marker-selected($ui-theme-color-components);
    }

    &:not(.selected):not(.prioritize) {
      &:hover {
        @include marker-pattern($ui-theme-color-components);
        @include marker-outline($ui-theme-color-components);
      }

      &:hover, &.allows-nesting.draggable-over {
        &:before {
          content: attr(data-element);
        }
      }
    }

    // prioritize
    &.prioritize {
      @include marker-outline($ui-theme-color-components);
      z-index: 100000;
      &:before {
        content: attr(data-element);
      }
    }

    // drag
    &.draggable-over {
      @include marker-pattern($ui-theme-color-components);
      @include marker-outline($ui-theme-color-components);
    }

    /*&.allows-nesting {
      @include marker-outline($ui-theme-color-general);

      &.draggable-over {
        @include marker-pattern($ui-theme-color-general);

        &:before {
          content: attr(data-element);
        }
      }
    }*/

    .droppable-above {
      display: none;
      width: inherit;
      height: 15px;
      top: -15px;
      pointer-events: all;
      z-index: 10;
      position: relative;

      &.draggable-over-above {
        @include marker-pattern($ui-theme-color-components);
        outline: none;
      }
    }

    &.generating {
      --angle: 0deg;
      border: 2px solid;
      box-sizing: border-box;
      border-image: conic-gradient(from var(--angle), red, yellow, lime, aqua, blue, magenta, red) 1;
      animation: 3s rotate linear infinite;

      @keyframes rotate {
        to {
          --angle: 360deg;
        }
      }
    }

    &.show-marker {
      @include marker-outline($ui-theme-color-components);
    }
  }

  :global(body.dragging-in-progress) {
    .persistent-marker {
      &.allows-nesting {
        @include marker-outline($ui-theme-color-components);
      }

      &:not(.allows-nesting):not(.dragging) {
        pointer-events: none;
      }
    }

    .droppable-above {
      display: block;
    }
  }

  // border gradient animation
  @property --angle {
    syntax: '<angle>';
    initial-value: 0deg;
    inherits: false;
  }
</style>