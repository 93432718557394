<script lang="ts">
  import { quintInOut } from "svelte/easing";
  import { fly } from "svelte/transition";
  import { BlurBackgroundFilter, IconButton } from "../atoms";
  import { interfaceOverlay } from "../../stores/uiStore";
  import Icon from "@iconify/svelte";

  export let props;
</script>

<BlurBackgroundFilter>
  <main transition:fly={{duration: 500, x: 0, y: -50, easing: quintInOut}}>
    <div class="close-container">
      <IconButton on:click={() =>  interfaceOverlay.set(null)}>
        <Icon icon="uil:times"/>
      </IconButton>
    </div>
    <iframe
      width="100%"
      height="600px"
      src={props.src}
      title="YouTube video player"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowfullscreen
    >
    </iframe>
  </main>
</BlurBackgroundFilter>

<style lang="scss">
  main {
    @include overlaying-content-container;
    --ui-theme: #{$ui-theme-color-output};
    max-width: 1000px;
    height: auto;
    padding: 30px;
    padding-top: 15px;
    max-height: 100%;

    &.theme-light {
      @include theme-light;
    }
  }

  .close-container {
    @include close-container;
    padding-top: 0;
    padding-right: 0;
    margin-bottom: 15px;
  }
</style>