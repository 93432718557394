<script lang="ts">
  import { onMount } from "svelte";
  import { InstructionDialog, ViewTitle, ViewSection } from "../atoms";
  import { ComponentsCollection } from "../templates";
  import getUserCollections from "../../firebase/getUserCollections";
  import { authUser, authUserCollection } from "../../stores/userStore";

  onMount(async () => {
    if( $authUser ) {
      const userCollection = await getUserCollections($authUser.uid);
      if( userCollection ) {
        authUserCollection.set(userCollection.collectionIds);
      }
    }
  });
</script>

<ViewTitle title="Your Collection"/>
{#if $authUserCollection.length}
  {#each $authUserCollection as collectionId}
    <ComponentsCollection collectionId={collectionId}/>
  {/each}
{:else}
  <ViewSection>
    <InstructionDialog icon={{icon: "eva:shopping-bag-outline", size: 35}} dialog="Your collection is empty"/>
  </ViewSection>
{/if}

<style lang="scss">

</style>