<script lang="ts">
  import { onMount } from "svelte";
  import Icon from "@iconify/svelte";
  import UserButtonSmall from "./UserButtonSmall.svelte";
  import sendEmailVerif from "../../firebase/sendEmailVerification";
  import checkEmailVerification from "../../firebase/checkEmailVerification";

  export let email: string;

  // check if the email has been verified every 5 seconds.
  // if it's verified, this component unmounts from the userProfile and in turn the interval check is cleared 
  onMount(() => {
    const interval = window.setInterval(() => {
      checkEmailVerification();
    }, 5000);
    return () => clearInterval(interval);
  });

  const sendAgainHandler = () => {
    sendEmailVerif();
  }
</script>

<div class="content">
  <span class="email-icon"><Icon icon="material-symbols:mark-email-read-outline-rounded" width="40"/></span>
  <h3>Verify your email address</h3>
  <p>We sent a verification email to <strong>{email}</strong><br/>Click the link in the email to access features.</p>
  <UserButtonSmall icon="eva:refresh-outline" on:click={sendAgainHandler}>send again</UserButtonSmall>
</div>

<style lang="scss">
  .content {
    padding: 30px;
    font-size: 0.95em;
    text-align: center;
  }

  .email-icon {
    color: var(--ui-theme);
  }

  h3 {
    margin: 0;
    margin-bottom: 10px;
    font-weight: 500;
  }

  p {
    margin: 0;
    line-height: 1.25;
  }
</style>