import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  where,
} from 'firebase/firestore';

import {
  deleteObject,
  ref,
} from 'firebase/storage';

import {
  firestore,
  storage
} from './firebase'

async function deleteUserComponent(userId, componentId) {  
  try {
    // Step 1: Delete metadata from Firestore

    // Query the Firestore to find the document ID by userId and componentId
    const componentsCollection = collection(firestore, 'user-components');
    const q = query(componentsCollection, where("userId", "==", userId), where("componentId", "==", componentId));

    const querySnapshot = await getDocs(q);
    let docId;
    
    querySnapshot.forEach((document) => {
      docId = document.id;
    });

    // If a document is found, delete it
    if (docId) {
      const docRef = doc(firestore, 'user-components', docId);
      await deleteDoc(docRef);
    } else {
      return { success: false, error: 'Component not found in Firestore' };
    }

    // Step 2: Delete the component file from Firebase Storage

    // Define the path to the file in Firebase Storage
    const path = `user-components/${userId}/${componentId}.json`;
    const storageRef = ref(storage, path);

    // Delete the file
    await deleteObject(storageRef);

    return { success: true };
  } catch (error) {
    console.error("Error deleting component:", error);
    return { success: false, error: error.message };
  }
}

export default deleteUserComponent;