const MODE_TEXT = {
  component: {
    newHeading: 'New Project',
    newOption1Description: 'Create your own combination of elements to formulate original templates and components.',
    newOption2Description: 'Describe the component or template you need and let Generator AI formulate it for you.',
    newOption3Description: 'Browse from a selection of pre-made templates you can customize and make your own.',
    newOptionInputPlaceholder: 'Enter project name...',
    dashboardMenuHeading: 'Your Projects',
    dashboardMenuOption2: 'Kits',
    dashboardMenuNoItems: 'You have not purchased any UI kits.'
  },
  spreadsheet: {
    newHeading: 'New Project',
    newOption1Description: 'Create your own combination of elements to formulate spreadsheet apps.',
    newOption2Description: 'Describe the spreadsheet app you need and let Generator AI formulate it for you.',
    newOption3Description: 'Browse from a selection of pre-made spreadsheet app templates.',
    newOptionInputPlaceholder: 'Enter spreadsheet app name...',
    dashboardMenuHeading: 'Your Projects',
    dashboardMenuOption2: 'Templates',
    dashboardMenuNoItems: 'You have not purchased any templates.'
  }
}

export default MODE_TEXT