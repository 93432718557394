<script lang="ts">
  import Icon from "@iconify/svelte";
  import { activeNavItem, newComponent, viewWidth } from "../../stores/uiStore";
  import { copiedScaffold, scaffold, scaffoldDataSource, selectedElement, selectedElementScaffold } from "../../stores/scaffoldStore";
  import { createEventDispatcher } from "svelte";
  import saveProgress from "../../helpers/saveProgress";
  import ScaffoldCoordinator from "../../scaffold/ScaffoldCoordinator";
  import { BAN_NESTING } from "../../scaffold/ScaffoldRegulator";

  const dispatch = createEventDispatcher();
  let saving: boolean = false;
  let copyDisabled = true;
  let pasteDisabled = true;

  $: {
    if( $selectedElement.id !== null ) {
      copyDisabled = !$selectedElement.id || $selectedElement.element === 'body';
      pasteDisabled = $copiedScaffold !== null && $selectedElement?.id !== null && !BAN_NESTING.includes($selectedElementScaffold?.selectedScaffold?.tag) ? false : true;
    } else {
      copyDisabled = true;
      pasteDisabled = true;
    }
  }

  const backToDashboardHandler = async () => {
    await save();
    activeNavItem.set(null);
    viewWidth.set(null);
    newComponent.set(null);
    scaffold.set(null);
    scaffoldDataSource.set(null);
  }

  // TODO: Improve error handling
  const save = async () => {
    saving = true;
    await saveProgress();
    saving = false;
  }

  const deviceClickHandler = (device: 'desktop' | 'tablet' | 'smartphone') => {
    dispatch('deviceclick', device);
  }

  const copyHandler = () => {
    copiedScaffold.set($selectedElementScaffold.selectedScaffold);
  }

  const pasteHandler = () => {
    ScaffoldCoordinator.nesting($copiedScaffold, $selectedElement.id, false);
  }
</script>

<nav>
  <button class="icon-button" type="button" on:click={backToDashboardHandler}>
    <Icon icon="eva:arrow-left-outline" width="19px"/>
    Back to Dashboard
  </button>
  <div class="save">
    <button on:click={save}>
      {#if !saving}
        <Icon icon="eva:save-outline" width="18"/>
        Save
      {:else}
        <Icon icon="svg-spinners:blocks-shuffle-3" width="12"/>
        Saving...
      {/if}
    </button>
  </div>
  <div class="devices">
    <button class="desktop" on:click={() => deviceClickHandler('desktop')}><Icon icon="eva:monitor-outline" width="18"/></button>
    <button class="tablet" on:click={() => deviceClickHandler('tablet')}><Icon icon="tabler:device-ipad-horizontal" width="18"/></button>
    <button class="smartphone" on:click={() => deviceClickHandler('smartphone')}><Icon icon="eva:smartphone-outline" width="18"/></button>
  </div>
  <div class="utilities">
    <button class="copy" on:click={copyHandler} disabled={copyDisabled}><Icon icon="eva:copy-outline" width="18"/>Copy</button>
    <button class="paste" on:click={pasteHandler} disabled={pasteDisabled}><Icon icon="eva:copy-fill" width="18"/>Paste</button>
  </div>
</nav>

<style lang="scss">
  nav {
    width: fit-content;
    display: flex;
    font-size: 15px;
    gap: 30px;
  }

  button {
    @include clear-default-button-styling;
    border-radius: $border-radius-2;
    border: solid 1px $background-color-index-4-offset;
    box-sizing: border-box;
    font-size: 0.85em;
    padding: 10px 10px;
    line-height: 1;
    gap: 5px;
    display: flex;
    align-items: center;

    &:hover {
      border-color: #3b3b3b;
    }

    &:active {
      background-color: #252525;
    }

    &[disabled] {
      @include input-disabled;
    }
  }

  .devices, .save, .utilities {
    display: flex;
    gap: 2px;

    button {
      &.desktop {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
      }

      &.tablet {
        border-radius: 0;
      }

      &.smartphone {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
      }
    }
  }

  .utilities {
    button {
      &.copy {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      &.paste {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }

  .safe {
    justify-self: flex-end;
  }
</style>