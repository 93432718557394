<script lang="ts">
  export let message: string = 'This feature is not ready yet...';
</script>

<section>
  <h4>{@html message}</h4>
</section>

<style lang="scss">
  section {
    padding: 40px;
    text-align: center;
    
    h4 {
      line-height: 1.5;
      max-width: 365px;
      margin-left: auto;
      margin-right: auto;
    }

    :global(a) {
      text-decoration: none;
      color: var(--ui-theme);

      &:hover {
        text-decoration: underline;
      }
    }
  }
</style>