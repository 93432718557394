<script lang="ts">
  import InstructionDialog from "../atoms/InstructionDialog.svelte";
  import SectionLabel from "../atoms/SectionLabel.svelte";
  import ViewSection from "../atoms/ViewSection.svelte";
  import ResumeDataForm from "../organisms/ResumeDataForm.svelte";
  import { scaffold, scaffoldDataSource } from "../../stores/scaffoldStore";
  import UploadFile from "../atoms/UploadFile.svelte";
  import { CF_generateResume } from "../../firebase/firebase";
  import { base64EncodeFile } from "../../helpers/base64encodeFile";
  import ScaffoldCoordinator from "../../scaffold/ScaffoldCoordinator";

  export let activeDataView;

  let loadingDataSource = false;

  const resumeUploadHandler = async (event) => {
    const file = event.target.files[0];
    if( file ) {
      loadingDataSource = true;
      const encodedFile = await base64EncodeFile(file);
      try {
        const response = await CF_generateResume({resume: encodedFile});
        const dataSource = 'choices' in response.data ? {resume: JSON.parse(response.data.choices[0].message.content)} ?? {} : {};
        ScaffoldCoordinator.setScaffoldWithDataSource($scaffold, dataSource);
        loadingDataSource = false;
      } catch(error) {
        console.error(error);
        alert(error);
      }
    }
  }
</script>

{#if activeDataView === 'resume'}
  {#if $scaffoldDataSource}
    <ResumeDataForm/>
  {:else}
    <ViewSection>
      <div class="upload-file-container">
        <UploadFile loading={loadingDataSource} on:change={resumeUploadHandler}/>
      </div>
    </ViewSection>
  {/if}
{:else if activeDataView === 'restaurant-menu'}
  <SectionLabel label="Restaurant Menu"/>
  <ViewSection>
    <InstructionDialog icon={{icon: "eva:file-add-outline", size: 35}} dialog="Upload your restaurant menu"/>
  </ViewSection>
{:else if activeDataView === 'spreadsheet'}
  <SectionLabel label="Spreadsheet"/>
  <ViewSection>
    <InstructionDialog icon={{icon: "eva:file-add-outline", size: 35}} dialog="Upload your spreadsheet data"/>
  </ViewSection>
{/if}

<style lang="scss">
  .upload-file-container {
    @include theme-dark;
  }
</style>